import { Box, Button, Flex, Text } from '@radix-ui/themes';
import { PlateView } from 'components/common/plate-view';
import { MachineCalibrateButton } from 'components/machine/buttons/calibrate';
import { MachineFireButton } from 'components/machine/buttons/fire';
import { AimingContext } from 'contexts/aiming.context';
import { AuthContext } from 'contexts/auth.context';
import { CookiesContext } from 'contexts/cookies.context';
import { HittersContext } from 'contexts/hitters.context';
import { MachineContext } from 'contexts/machine.context';
import { MatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { QuickSessionContext } from 'contexts/quick-session.context';
import { t } from 'i18next';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useContext, useMemo } from 'react';

export const SelectPitchBodySidebar = (props: {
  lazySendPitch: (v: boolean) => void;
}) => {
  const cookiesCx = useContext(CookiesContext);
  const authCx = useContext(AuthContext);

  const matchingCx = useContext(MatchingShotsContext);

  const machineCx = useContext(MachineContext);
  const { lastStatus, lastBallCount, checkActive } = useContext(MachineContext);

  const aimingCx = useContext(AimingContext);
  const { pitch, requiresSend, setPlate } = useContext(AimingContext);

  const { active } = useContext(HittersContext);

  const { tags, training } = useContext(QuickSessionContext);

  const trained = useMemo(() => {
    if (!pitch) {
      return false;
    }

    if (!matchingCx.aggReady) {
      return false;
    }

    return matchingCx.isPitchTrained(pitch);
  }, [pitch, matchingCx.aggReady, matchingCx.lastUpdated]);

  const safeTags = `quick-session,${tags}`;
  const noBall = lastBallCount === 0;

  const machineReady = checkActive(true) && lastStatus.calibrated;

  const canSend = machineReady && trained && requiresSend;
  const canFire = machineReady && trained && !requiresSend;

  return (
    <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
      <Flex justify="center">
        <Box>
          <PlateView
            cookiesCx={cookiesCx}
            authCx={authCx}
            machineCx={machineCx}
            matchingCx={matchingCx}
            pitch={noBall ? undefined : pitch}
            hitter={active}
            disabled={
              training || noBall || !pitch || !matchingCx.isPitchTrained(pitch)
            }
            onUpdate={(location) => {
              setPlate(location);
              props.lazySendPitch(true);
            }}
            drawShots
            border
          />
        </Box>
      </Flex>

      {!machineReady && <MachineCalibrateButton className="width-100p" />}

      {machineReady && (
        <Box minWidth="0">
          <div hidden={!canSend}>
            <Button
              className="width-100p"
              color={RADIX.COLOR.SEND_PITCH}
              disabled={matchingCx.loading || !pitch || !checkActive(true)}
              onClick={() => props.lazySendPitch(false)}
            >
              <Text truncate>{t('common.load-pitch')}</Text>
            </Button>
          </div>

          <div hidden={!canFire}>
            <MachineFireButton
              className="width-100p"
              cookiesCx={cookiesCx}
              machineCx={machineCx}
              hitter={active}
              aimingCx={aimingCx}
              tags={safeTags}
              firing
            />
          </div>
        </Box>
      )}
    </Flex>
  );
};
