import { NotifyHelper } from 'classes/helpers/notify.helper';
import { AuthContext } from 'contexts/auth.context';
import { MachineContext } from 'contexts/machine.context';
import { SectionsContext } from 'contexts/sections.context';
import { MachineButtonMode } from 'enums/machine.enums';
import { SectionName } from 'enums/route.enums';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { MachineHelper } from 'lib_ts/classes/machine.helper';
import {
  getMachineActiveModelID,
  getMSFromMSDict,
} from 'lib_ts/classes/ms.helper';
import { IPitch } from 'lib_ts/interfaces/pitches';
import {
  IAggregateMachineShotsDict,
  IAggregateMachineShotsEntry,
  IAggregateMachineShotsRequest,
  IArchiveMachineShotsByHashRequest,
  IMachineShot,
  IMatchingShotsDict,
  IMatchingShotsRequest,
} from 'lib_ts/interfaces/training/i-machine-shot';
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ShotsService } from 'services/shots.service';

const CONTEXT_NAME = 'MatchingShotsContext';

const MATCHING_BATCH_SIZE = 50;

// if false, UI elements that allow the user to refresh will be shown, otherwise rely on training refreshable pitches to trigger refreshes
export const ONLY_ALLOW_REFRESH_ON_TRAIN = true;

// also used when working with smaller dicts (e.g. results of updateTrainingMatches)
export const getShotsFromDict = (config: {
  shotsDict: IMatchingShotsDict;
  hash: string | undefined;
}): IMachineShot[] => {
  try {
    if (!config.hash) {
      // key is not specified
      return [];
    }

    const entry = config.shotsDict[config.hash];

    if (!entry) {
      // key doesn't exist
      return [];
    }

    const output = entry
      // clone the array to avoid sorting the original
      .map((m) => m)
      // sort the newest shots to the front
      .sort((a, b) => -a._created.localeCompare(b._created));

    return output;
  } catch (e) {
    console.error(e);
    return [];
  }
};

const DEFAULT_AGG_SHOT_ENTRY: IAggregateMachineShotsEntry = {
  total: 0,
  qt: 0,
  qt_complete: false,
  trained: false,
};

export interface IMatchesCount {
  before: number;
  after: number;
}

export interface IMatchingShotsContext {
  lastUpdated: number;

  aggReady: boolean;
  loading: boolean;

  readonly readyToTrain: (options?: {
    ignoreConnection?: boolean;
    ignoreGameStatus?: boolean;
  }) => boolean;

  readonly readyToRefresh: (pitch: IPitch) => boolean;

  readonly machineButtonMode: (config: {
    isActive: boolean;
    calibrated: boolean;
    requireTraining: boolean;
    requireSend: boolean;
    pitch: IPitch | undefined;
    awaitingResend: boolean | undefined;
  }) => MachineButtonMode;

  // mostly unused
  readonly isHashTrained: (matching_hash?: string) => boolean;
  readonly isPitchTrained: (pitch: Partial<IPitch>) => boolean;

  readonly safeGetShotsByPitch: (pitch: Partial<IPitch>) => IMachineShot[];

  // mostly unused
  readonly getAggShotsByHash: (
    matching_hash?: string
  ) => IAggregateMachineShotsEntry | undefined;
  readonly getAggShotsByPitch: (
    pitch: IPitch
  ) => IAggregateMachineShotsEntry | undefined;

  readonly updatePitch: (
    config: {
      pitch: Partial<IPitch>;
      includeHitterPresent: boolean;
      includeLowConfidence: boolean;
      newerThan?: string;
    },
    force: boolean
  ) => Promise<IMatchesCount>;

  readonly updatePitches: (config: {
    pitches: Partial<IPitch>[];
    includeHitterPresent: boolean;
    includeLowConfidence: boolean;
    newerThan?: string;
    limit?: number;
  }) => Promise<IMatchingShotsDict>;

  /** archives one shot by _id */
  readonly archiveShotByID: (shot: IMachineShot) => Promise<boolean>;
  /** archives all shots matching the filter criteria */
  readonly archiveShotsByHashes: (
    filter: IArchiveMachineShotsByHashRequest
  ) => Promise<boolean>;

  /** updates a specific shot for a specific hash and updates the dictionary entry accordingly */
  readonly updateShot: (config: {
    matching_hash: string;
    shot_id: string;
    payload: Partial<IMachineShot>;
  }) => Promise<boolean>;

  /** replaces matching shots for a single hash */
  readonly replaceHash: (matching_hash: string, shots: IMachineShot[]) => void;
}

const DEFAULT: IMatchingShotsContext = {
  lastUpdated: Date.now(),

  aggReady: false,
  loading: false,

  readyToTrain: () => false,
  readyToRefresh: () => false,
  machineButtonMode: () => MachineButtonMode.NoPitch,

  isPitchTrained: () => false,
  isHashTrained: () => false,

  safeGetShotsByPitch: () => [],

  getAggShotsByPitch: () => undefined,
  getAggShotsByHash: () => undefined,

  updatePitch: () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),

  updatePitches: () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),

  archiveShotByID: () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),
  archiveShotsByHashes: () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),
  updateShot: () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),
  replaceHash: () => console.error(`${CONTEXT_NAME}: not init`),
};

export const MatchingShotsContext = createContext(DEFAULT);

interface IProps {
  children: ReactNode;
}

export const MatchingShotsProvider: FC<IProps> = (props: IProps) => {
  const authCx = useContext(AuthContext);
  const sectionsCx = useContext(SectionsContext);
  const machineCx = useContext(MachineContext);

  const [_shotsDict, _setShotsDict] = useState<IMatchingShotsDict>({});
  const [_aggregateDict, _setAggregateDict] = useState<
    IAggregateMachineShotsDict | undefined
  >();

  const _aggReady = useMemo(
    () => _aggregateDict !== undefined,
    [_aggregateDict]
  );

  const [_loading, _setLoading] = useState(DEFAULT.loading);

  const _refreshAggregateDict = async (
    config: IAggregateMachineShotsRequest
  ) => {
    const result = await ShotsService.getInstance().getAggregateMatches(config);

    if (!result) {
      console.warn('no aggregation result received from the server!');
      return;
    }

    _setAggregateDict({
      ..._aggregateDict,
      ...result,
    });
  };

  const _updateOneHash = (
    query: IAggregateMachineShotsRequest,
    force: boolean
  ) => {
    return new Promise<IMatchesCount>((resolve) => {
      if (!query.matching_hash) {
        resolve({
          before: 0,
          after: 0,
        });
        return;
      }

      const { matching_hash } = query;

      const prevShots = getShotsFromDict({
        shotsDict: _shotsDict,
        hash: matching_hash,
      });

      if (!force) {
        resolve({
          before: prevShots.length,
          after: prevShots.length,
        });
        return;
      }

      _setLoading(true);
      ShotsService.getInstance()
        .getMatches({
          matching_hashes: [matching_hash],
          machineID: machineCx.machine.machineID,
          ball_type: machineCx.machine.ball_type,
          newerThan: query.start_date,
          includeHitterPresent: query.includeHitterPresent,
          includeLowConfidence: query.includeLowConfidence,
        })
        .then((result) => {
          if (!result || !result[matching_hash]) {
            NotifyHelper.error({
              message_md: 'Failed to fetch matching shots, please try again.',
            });
            resolve({ before: 0, after: 0 });
            return;
          }

          const nextShots = result[matching_hash];

          /** take snapshot of lengths before vs after */
          const counts: IMatchesCount = {
            before: prevShots?.length ?? 0,
            after: nextShots.length,
          };

          /** new object should trigger re-renders */
          const newShotDict = {
            ..._shotsDict,
            [matching_hash]: nextShots,
          };

          _setShotsDict(newShotDict);

          // try to update the specific entry in _aggShotsDict too
          if (nextShots.length === 0) {
            resolve(counts);
            return;
          }

          ShotsService.getInstance()
            .getAggregateMatches(query)
            .then((result) => {
              if (!result) {
                return;
              }

              _setAggregateDict({
                ..._aggregateDict,
                ...result,
              });
            })
            .finally(() => {
              resolve(counts);
            });
        })
        .catch((e) => {
          console.error(e);
          resolve({
            before: 0,
            after: 0,
          });
        })
        .finally(() => _setLoading(false));
    });
  };

  const _updateHashes = async (
    config: Partial<IMatchingShotsRequest>,
    limit?: number
  ) => {
    const updated: IMatchingShotsDict = {};

    if (!config.matching_hashes) {
      // i.e. nothing changed
      return updated;
    }

    const service = ShotsService.getInstance();

    _setLoading(true);

    const chunkedRequests = ArrayHelper.chunkArray(
      config.matching_hashes,
      MATCHING_BATCH_SIZE
    ).map(async (chunk) => {
      const payload: IMatchingShotsRequest = {
        matching_hashes: chunk,
        machineID: machineCx.machine.machineID,
        ball_type: machineCx.machine.ball_type,
        newerThan: config.newerThan,
        includeHitterPresent: !!config.includeHitterPresent,
        includeLowConfidence: !!config.includeLowConfidence,
      };

      const matches = await service.getMatches(payload, limit);

      payload.matching_hashes.forEach((key) => {
        // if there are no matches in result (e.g. payload has newerThan set), this will ensure the dict entry is reset to undefined
        updated[key] = matches[key] ?? [];
      });
    });

    await Promise.all(chunkedRequests)
      .then(() =>
        _setShotsDict({
          // keep existing values
          ..._shotsDict,
          // merge updated values
          ...updated,
        })
      )
      .catch((e) => console.error(e));

    await _refreshAggregateDict({
      machineID: machineCx.machine.machineID,
      start_date: config.newerThan,
      includeHitterPresent: !!config.includeHitterPresent,
      includeLowConfidence: !!config.includeLowConfidence,
    });

    _setLoading(false);

    return updated;
  };

  const _isHashTrained = (matching_hash?: string) => {
    if (!matching_hash) {
      return false;
    }

    if (!_aggregateDict) {
      return false;
    }

    const summary = _aggregateDict[matching_hash];

    if (!summary) {
      return false;
    }

    if (summary.qt_complete) {
      return true;
    }

    return summary.total - summary.qt >= machineCx.machine.training_threshold;
  };

  const _isPitchTrained = (pitch: Partial<IPitch>) => {
    const ms = getMSFromMSDict(pitch, machineCx.machine).ms;
    if (!ms) {
      return false;
    }

    const hash = ms.matching_hash ?? MachineHelper.getMSHash('matching', ms);
    return _isHashTrained(hash);
  };

  const _readyToRefresh = (pitch: IPitch) => {
    const machine = machineCx.machine;

    const ms = getMSFromMSDict(pitch, machine).ms;
    if (!ms) {
      // require a rebuild to get an ms
      return true;
    }

    const current_id = getMachineActiveModelID(machine);
    const upToDate = ms.model_id === current_id;

    if (upToDate) {
      // nothing would happen if we rebuild
      return false;
    }

    // only recommend rebuilding outdated ms that are not trained
    return !_isHashTrained(ms.matching_hash);
  };

  const _lastUpdated = useMemo(() => Date.now(), [_aggregateDict, _shotsDict]);

  const state: IMatchingShotsContext = {
    lastUpdated: _lastUpdated,
    aggReady: _aggReady,
    loading: _loading,

    readyToTrain: (options) => {
      if (_loading) {
        return false;
      }

      if (machineCx.loading) {
        return false;
      }

      if (machineCx.activeModel?.calibration_only) {
        return false;
      }

      if (!options?.ignoreGameStatus && authCx.restrictedGameStatus) {
        return false;
      }

      if (!options?.ignoreConnection && !machineCx.checkActive(true)) {
        return false;
      }

      return true;
    },

    readyToRefresh: _readyToRefresh,

    machineButtonMode: (config) => {
      if (!config.isActive) {
        return MachineButtonMode.Unavailable;
      }

      if (!config.calibrated) {
        return MachineButtonMode.Calibrate;
      }

      if (!config.pitch) {
        /** draw nothing while nothing is selected */
        return MachineButtonMode.NoPitch;
      }

      if (_readyToRefresh(config.pitch)) {
        return ONLY_ALLOW_REFRESH_ON_TRAIN
          ? MachineButtonMode.Train
          : MachineButtonMode.Refresh;
      }

      if (config.requireTraining && !_isPitchTrained(config.pitch)) {
        /** requires additional training */
        return MachineButtonMode.Train;
      }

      if (!config.requireSend) {
        return MachineButtonMode.Fire;
      }

      if (config.awaitingResend) {
        /** to avoid flickering to send to machine when nudging mstarget */
        return MachineButtonMode.Fire;
      }

      /** default */
      return MachineButtonMode.Send;
    },

    isPitchTrained: _isPitchTrained,

    isHashTrained: _isHashTrained,

    safeGetShotsByPitch: (pitch) => {
      const ms = getMSFromMSDict(pitch, machineCx.machine).ms;

      return getShotsFromDict({
        shotsDict: _shotsDict,
        hash: ms?.matching_hash,
      });
    },

    getAggShotsByPitch: (pitch) => {
      if (!_aggregateDict) {
        return undefined;
      }

      const ms = getMSFromMSDict(pitch, machineCx.machine).ms;
      if (!ms) {
        return undefined;
      }

      const hash = ms.matching_hash ?? MachineHelper.getMSHash('matching', ms);

      const safeEntry = _aggregateDict[hash] ?? { ...DEFAULT_AGG_SHOT_ENTRY };

      const regularTotal = safeEntry.total - safeEntry.qt;

      // recalculate this in case machine threshold changes
      safeEntry.trained =
        safeEntry.qt_complete ||
        regularTotal >= machineCx.machine.training_threshold;

      return safeEntry;
    },

    getAggShotsByHash: (matching_hash) => {
      if (!_aggregateDict) {
        return undefined;
      }

      if (!matching_hash) {
        return { ...DEFAULT_AGG_SHOT_ENTRY };
      }

      const safeEntry = matching_hash
        ? _aggregateDict[matching_hash]
        : { ...DEFAULT_AGG_SHOT_ENTRY };

      const regularTotal = safeEntry.total - safeEntry.qt;

      // recalculate this in case machine threshold changes
      safeEntry.trained =
        safeEntry.qt_complete ||
        regularTotal >= machineCx.machine.training_threshold;

      return safeEntry;
    },

    updatePitch: (config, force) => {
      const hash = getMSFromMSDict(config.pitch, machineCx.machine).ms
        ?.matching_hash;
      return _updateOneHash(
        {
          machineID: machineCx.machine.machineID,
          matching_hash: hash,
          start_date: config.newerThan,
          includeHitterPresent: config.includeHitterPresent,
          includeLowConfidence: config.includeLowConfidence,
        },
        force
      );
    },

    updatePitches: async (config) => {
      try {
        const hashes = ArrayHelper.unique(
          config.pitches.map(
            (p) => getMSFromMSDict(p, machineCx.machine).ms?.matching_hash ?? ''
          )
        );

        return await _updateHashes(
          {
            includeHitterPresent: config.includeHitterPresent,
            includeLowConfidence: config.includeLowConfidence,
            newerThan: config.newerThan,
            matching_hashes: hashes,
          },
          config.limit
        );
      } catch (e) {
        console.error(e);
        return {};
      }
    },

    archiveShotByID: async (shot) => {
      try {
        _setLoading(true);

        const success = await ShotsService.getInstance().archiveShotByID(shot);

        if (!success) {
          NotifyHelper.error({
            message_md:
              'Training data could not be archived. Please try again.',
          });
          return false;
        }

        NotifyHelper.success({
          message_md: 'Training data archived successfully!',
        });

        const { matching_hash } = shot.target_ms;

        if (matching_hash) {
          _updateHashes({
            matching_hashes: [matching_hash],
            includeHitterPresent: false,
            includeLowConfidence: true,
          });

          if (machineCx.lastMSHash === matching_hash) {
            machineCx.resetMSHash();
          }
        }

        return true;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md: 'There was an error while archiving training data.',
        });

        return false;
      } finally {
        _setLoading(false);
      }
    },

    archiveShotsByHashes: async (filter) => {
      try {
        _setLoading(true);

        const success =
          await ShotsService.getInstance().archiveShotsByHashes(filter);

        if (!success) {
          NotifyHelper.error({
            message_md:
              'Training data could not be archived. Please try again.',
          });

          return false;
        }

        NotifyHelper.success({
          message_md: 'Training data archived successfully!',
        });

        // we remove entries by hash
        const nextAgg = { ..._aggregateDict };
        const nextShot = { ..._shotsDict };

        filter.matching_hashes.forEach((h) => {
          delete nextAgg[h];
          delete nextShot[h];
        });

        _setAggregateDict(nextAgg);
        _setShotsDict(nextShot);
        return true;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md: 'There was an error while archiving training data.',
        });

        return false;
      } finally {
        _setLoading(false);
      }
    },

    updateShot: (config) => {
      return ShotsService.getInstance()
        .updateShot(config.shot_id, config.payload)
        .then((uShot) => {
          if (!uShot) {
            return false;
          }

          const nextShots = getShotsFromDict({
            shotsDict: _shotsDict,
            hash: config.matching_hash,
          }).filter((s) => s._id !== config.shot_id);

          const newDict = {
            ..._shotsDict,
            [config.matching_hash]: [...nextShots, uShot],
          };

          _setShotsDict(newDict);
          return true;
        })
        .catch((error) => {
          console.error(error);
          return false;
        });
    },

    replaceHash: (matching_hash, shots) => {
      const newDict = {
        ..._shotsDict,
        [matching_hash]: shots,
      };

      _setShotsDict(newDict);
    },
  };

  useEffect(() => {
    if (!machineCx.machine.machineID) {
      // can't refresh without a machineID
      return;
    }

    if (!machineCx.machine.ball_type) {
      // can't refresh without a ball_type
      return;
    }

    if (
      ![SectionName.Pitches, SectionName.QuickSession].includes(
        sectionsCx.active.section
      )
    ) {
      // only reload within certain sections
      return;
    }

    console.debug({
      event: 'matching shots provider loading agg dict',
      machineID: machineCx.machine.machineID,
      ball_type: machineCx.machine.ball_type,
      section: sectionsCx.active.section,
    });

    _refreshAggregateDict({
      machineID: machineCx.machine.machineID,
      includeHitterPresent: false,
      includeLowConfidence: true,
    });

    _setShotsDict({});
  }, [
    machineCx.machine.machineID,
    machineCx.machine.ball_type,
    sectionsCx.active.section,
  ]);

  return (
    <MatchingShotsContext.Provider value={state}>
      {props.children}
    </MatchingShotsContext.Provider>
  );
};
