import { NotifyHelper } from 'classes/helpers/notify.helper';
import { IHitterSessionAdvancedFilters } from 'interfaces/i-hitter-session-filters';
import { HitOutcomeCode } from 'lib_ts/enums/hitters.enums';
import { PitchType } from 'lib_ts/enums/pitches.enums';
import {
  ISessionHit,
  ISessionHitDeleteRequest,
  ISessionHitSummary,
} from 'lib_ts/interfaces/i-session-hit';
import { HittersService } from 'services/hitters.service';
import { create } from 'zustand';

interface AnalyticsState {
  loading: boolean;
  error: string | null;

  // Table data
  sessions: ISessionHitSummary[];
  hits: ISessionHit[];
  active: ISessionHitSummary | null;

  // Hitting Report filters
  batterFilter: string[];
  startDateFilter: string[];

  // Hitter Session filters
  pitchTypeFilter: PitchType[];
  outcomeFilter: HitOutcomeCode[];
  advancedFilters: IHitterSessionAdvancedFilters | null;
}

interface AnalyticsActions {
  // Data fetchers
  fetchSessions: (params: {
    machineID: string;
  }) => Promise<ISessionHitSummary[]>;
  fetchHits: (params: {
    hitterID: string;
    sessionID: string;
  }) => Promise<ISessionHit[]>;

  // Update a single record
  updateHit: (id: string, value: Partial<ISessionHit>) => Promise<void>;

  // remove one or more sessions
  deleteSessions: (values: ISessionHitDeleteRequest[]) => Promise<void>;

  // Hitting Report filter setters
  setBatterFilter: (filter: string[]) => void;
  setStartDateFilter: (filter: string[]) => void;

  // Hitter Session filter setters
  setPitchTypeFilter: (filter: AnalyticsState['pitchTypeFilter']) => void;
  setOutcomeFilter: (filter: AnalyticsState['outcomeFilter']) => void;
  setAdvancedFilters: (filter: AnalyticsState['advancedFilters']) => void;

  reset: () => void;
}

type AnalyticsStore = AnalyticsState & AnalyticsActions;

const initialState: AnalyticsState = {
  loading: false,
  error: null,

  sessions: [],
  hits: [],
  active: null,

  batterFilter: [],
  startDateFilter: [],

  pitchTypeFilter: [],
  outcomeFilter: [],
  advancedFilters: null,
};

const useAnalyticsStore = create<AnalyticsStore>((set, get) => ({
  ...initialState,
  fetchSessions: async ({ machineID }) => {
    set({ loading: true, error: null });

    try {
      const response = await HittersService.getInstance().getMachineSessions({
        machineID: machineID,
        limit: 100,
      });

      set({
        loading: false,
        sessions: response,
      });

      return response;
    } catch (error) {
      set({ error: 'Failed to fetch machine sessions', loading: false });
      return [];
    }
  },
  // Loads / resets the hitter session table
  fetchHits: async ({ hitterID, sessionID }) => {
    set({
      loading: true,
      error: null,
      active: null,
      hits: [],
      // Reset filters when we change sessions
      pitchTypeFilter: [],
      outcomeFilter: [],
      advancedFilters: null,
    });

    try {
      const hits = await HittersService.getInstance().getHitterSession({
        hitterID,
        sessionID,
      });

      set((state) => {
        // machineSessions are fetched upon Analytics mount
        // Find the summary for the current session

        const active =
          state.sessions.find((machineSession) => {
            // Some machine sessions don't have a hitterID
            if (machineSession.session && machineSession.hitterID) {
              return (
                machineSession.session === sessionID &&
                machineSession.hitterID === hitterID
              );
            }

            return machineSession.session === sessionID;
          }) ?? null;

        return {
          loading: false,
          hits: hits,
          active: active,
        };
      });

      return hits;
    } catch (error) {
      set({ error: 'Failed to fetch hitter session', loading: false });
      return [];
    }
  },
  updateHit: async (id, value) => {
    const { hits } = get();

    try {
      const result = await HittersService.getInstance().updateHit(id, value);

      // Find and update this hit in the store
      const updated = hits.map((m) => (m._id === id ? result : m));

      set({
        hits: updated,
      });
    } catch (error) {
      set({ error: 'Failed to update hit', loading: false });
    }
  },
  deleteSessions: async (values) => {
    const { sessions } = get();

    try {
      const payload = values.map((m) => {
        const o: ISessionHitDeleteRequest = {
          session: m.session,
          hitterID: m.hitterID,
        };

        return o;
      });

      const success =
        await HittersService.getInstance().deleteSessions(payload);

      if (!success) {
        NotifyHelper.error({
          message_md: `There was a problem deleting the ${
            values.length > 1 ? 'reports' : 'report'
          }.`,
        });
        return;
      }

      NotifyHelper.success({
        delay_ms: 3_000,
        message_md:
          values.length > 1
            ? 'Selected reports deleted successfully.'
            : 'Report deleted successfully!',
      });

      const updated = sessions.filter(
        (m) =>
          // keep only the sessions that were not deleted
          values.findIndex(
            (v) => v.session === m.session && v.hitterID === m.hitterID
          ) === -1
      );

      set({
        sessions: updated,
      });
    } catch (error) {
      set({ error: 'Failed to update hit', loading: false });
    }
  },

  setBatterFilter: (batterFilter) => set({ batterFilter }),
  setStartDateFilter: (startDateFilter) => set({ startDateFilter }),

  setPitchTypeFilter: (pitchTypeFilter) => set({ pitchTypeFilter }),
  setOutcomeFilter: (outcomeFilter) => set({ outcomeFilter }),
  setAdvancedFilters: (advancedFilters) => set({ advancedFilters }),

  reset: () => set(initialState),
}));

export default useAnalyticsStore;
