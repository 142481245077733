import { Avatar, Flex, Grid, Heading, Skeleton, Text } from '@radix-ui/themes';
import { StringHelper } from 'classes/helpers/string.helper';
import { DataCard } from 'components/common/data-card';
import useAnalyticsStore from 'components/sections/analytics/store/use-analytics-store';
import { SectionHeader } from 'components/sections/header';
import { SectionsContext } from 'contexts/sections.context';
import format from 'date-fns-tz/format';
import parseISO from 'date-fns/parseISO';
import { LOCAL_DATETIME_FORMAT, LOCAL_TIMEZONE } from 'enums/env';
import { SectionName, SubSectionName } from 'enums/route.enums';
import { t } from 'i18next';
import { round } from 'lib_ts/classes/math.utilities';
import { SessionHelper } from 'lib_ts/classes/session.helper';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useContext, useMemo } from 'react';

const Summary = () => {
  const { active, hits, loading } = useAnalyticsStore();

  const stats = useMemo(() => SessionHelper.getHitsStats(hits), [hits]);

  return (
    <Flex align="center" wrap="wrap" gap={RADIX.FLEX.GAP.SM}>
      <Flex gap={RADIX.FLEX.GAP.MD} align="center">
        <Skeleton loading={!active}>
          <Avatar
            color={RADIX.COLOR.NEUTRAL}
            fallback={
              active ? StringHelper.getInitials(active.hitterName) : '??'
            }
            size="7"
          />
        </Skeleton>
        <Flex direction="column" justify="center" gap={RADIX.FLEX.GAP.XS}>
          <Skeleton loading={!active}>
            <Heading size={RADIX.HEADING.SIZE.LG}>
              {active?.hitterName ?? t('hitters.anonymous')}
            </Heading>
          </Skeleton>
          {/* TODO: Add HitterSide badge here */}
          <Skeleton loading={!active}>
            <Text color={RADIX.COLOR.SECONDARY}>
              {`${
                active
                  ? format(parseISO(active?.startDate), LOCAL_DATETIME_FORMAT, {
                      timeZone: LOCAL_TIMEZONE,
                    })
                  : undefined
              } · ${active?.shots ?? 0} ${t('common.shots')}`}
            </Text>
          </Skeleton>
        </Flex>
      </Flex>
      <Grid
        flexGrow="1"
        columns={{ initial: '2', xs: '3', sm: '4', md: '5' }}
        gap={RADIX.FLEX.GAP.XS}
        width={{ initial: '100%', xs: 'auto' }}
      >
        <Skeleton loading={loading}>
          <DataCard
            title={t('common.avg-ev')}
            value={round(stats.avgExitMPH, 1)}
            unit="MPH"
          />
        </Skeleton>
        <Skeleton loading={loading}>
          <DataCard
            title={t('common.max-ev')}
            value={round(stats.maxExitMPH, 1)}
            unit="MPH"
          />
        </Skeleton>
        <Skeleton loading={loading}>
          <DataCard
            title={t('common.avg-distance')}
            value={stats.avgDistanceFT ? round(stats.avgDistanceFT, 1) : '-'}
            unit="FT"
          />
        </Skeleton>
        <Skeleton loading={loading}>
          <DataCard
            title={t('common.max-distance')}
            value={stats.maxDistanceFT ? round(stats.maxDistanceFT, 1) : '-'}
            unit="FT"
          />
        </Skeleton>
        <Skeleton loading={loading}>
          <DataCard
            title={t('common.avg-la')}
            value={
              stats.avgVLaunchDeg ? `${round(stats.avgVLaunchDeg, 1)}°` : '-'
            }
          />
        </Skeleton>
      </Grid>
    </Flex>
  );
};

export const HitterSessionHeader = () => {
  const sectionsCx = useContext(SectionsContext);
  const { active } = useAnalyticsStore();

  return (
    <>
      <SectionHeader
        header={null}
        breadcrumbs={[
          {
            label: 'main.hitting-reports',
            onClick: () =>
              sectionsCx.tryChangeSection({
                section: SectionName.Analytics,
                subsection: SubSectionName.HittingReports,
                trigger: 'Analytics > breadcrumbs',
              }),
          },
          ...(active ? [{ label: active.hitterName }] : []),
        ]}
      />
      <Summary />
    </>
  );
};
