import { DOT_RGB_YELLOW, PlateCanvas } from 'classes/plate-canvas';
import { ErrorBoundary } from 'components/common/error-boundary';
import {
  DOT_SIZE_SM,
  MAX_SHOT_OPACITY,
  SHOT_OPACITY_DELTA,
} from 'enums/canvas';
import { SubStatus } from 'enums/installation';
import { isAppearanceDark } from 'index';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { TrajHelper } from 'lib_ts/classes/trajectory.helper';
import { IPlateLocExt } from 'lib_ts/interfaces/pitches';
import { IMachineShot } from 'lib_ts/interfaces/training/i-machine-shot';
import React from 'react';

const COMPONENT_NAME = 'DataCollectorPlateView';

interface IProps {
  state: SubStatus;
  complete: boolean;
  shots: IMachineShot[];
}

export class DataCollectorPlateView extends React.Component<IProps> {
  private plate_canvas = PlateCanvas.makeLandscape(isAppearanceDark());

  private mainCanvasNode?: HTMLCanvasElement;
  private shotsCanvasNode?: HTMLCanvasElement;

  constructor(props: IProps) {
    super(props);

    this.drawComplete = this.drawComplete.bind(this);
    this.drawMain = this.drawMain.bind(this);
    this.drawShots = this.drawShots.bind(this);
  }

  componentDidMount(): void {
    this.drawMain('componentDidMount');

    if (this.props.shots.length > 0) {
      this.drawShots('componentDidMount w/ non-zero shots');
    }

    if (this.props.complete) {
      this.drawComplete();
    }
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (
      !this.props.complete &&
      !ArrayHelper.equals(prevProps.shots, this.props.shots)
    ) {
      this.drawShots('componentDidUpdate w/ shots');
    }

    if (
      (prevProps.state !== this.props.state &&
        [SubStatus.Completed, SubStatus.Error].includes(this.props.state)) ||
      (!prevProps.complete && this.props.complete)
    ) {
      this.drawComplete();
    }
  }

  private drawMain(source: string) {
    console.debug(`drawMain (${source})`);

    const canvas = this.mainCanvasNode;
    if (!canvas) {
      return;
    }

    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return;
    }

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    this.plate_canvas.drawStrikeZoneAutoAdjustArea(ctx);
    this.plate_canvas.drawStrikeZone(ctx);
    this.plate_canvas.drawGround(ctx);
  }

  private drawComplete() {
    const canvas = this.shotsCanvasNode;
    if (!canvas) {
      return;
    }

    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return;
    }

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    if (this.props.state === SubStatus.Error) {
      this.plate_canvas.drawCrossmark(ctx);
      return;
    }

    this.plate_canvas.drawCheckmark(ctx);
  }

  drawShots(source: string) {
    console.debug(`drawShots (${source})`);

    const canvas = this.shotsCanvasNode;
    if (!canvas) {
      return;
    }

    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return;
    }

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // newest first
    const locs = [...this.props.shots]
      .sort((a, b) => -a._created.localeCompare(b._created))
      .map((shot) => {
        const o: IPlateLocExt = {
          ...TrajHelper.getPlateLoc(shot.traj),
          _created: shot._created,
          _id: shot._id,
          aiming: shot.aiming,
        };

        return o;
      });

    locs.forEach((loc, i) => {
      const opacity = Math.max(0, MAX_SHOT_OPACITY - i * SHOT_OPACITY_DELTA);

      this.plate_canvas.drawDot(ctx, loc, {
        label:
          i === 0
            ? // only label the latest shot's location
              `${loc.plate_x.toFixed(1)}', ${loc.plate_z.toFixed(1)}'`
            : undefined,
        size: DOT_SIZE_SM,
        color: `rgba(${DOT_RGB_YELLOW}, ${opacity})`,
      });
    });
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <div
          style={{
            height: '300px',
            width: '100%',
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          <div
            className="slider"
            style={{
              aspectRatio: '1.6',
              width: 'auto',
              // ensures this is centered horizontally
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: '50%',
              transform: 'translate(-50%, 0)',
            }}
          >
            <div
              className="sizer"
              style={{
                aspectRatio: '1.6',
                position: 'absolute',
                height: '100%',
                width: '100%',
              }}
            >
              <canvas
                ref={(node) =>
                  (this.mainCanvasNode = node as HTMLCanvasElement)
                }
                data-identifier="main-canvas"
                width={this.plate_canvas.CONFIG.canvas.width_px}
                height={this.plate_canvas.CONFIG.canvas.height_px}
              />
              <canvas
                ref={(node) =>
                  (this.shotsCanvasNode = node as HTMLCanvasElement)
                }
                data-identifier="shots-canvas"
                width={this.plate_canvas.CONFIG.canvas.width_px}
                height={this.plate_canvas.CONFIG.canvas.height_px}
              />
            </div>
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}
