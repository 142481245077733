import { DownloadIcon } from '@radix-ui/react-icons';
import {
  Badge,
  Blockquote,
  Card,
  DataList,
  Flex,
  ScrollArea,
  Text,
} from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { CommonDetails } from 'components/common/details';
import { DialogButton } from 'components/common/dialogs/button';
import { CommonConfirmationDialog } from 'components/common/dialogs/confirmation';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonInputHint } from 'components/common/form/hint';
import { CommonSearchInput } from 'components/common/form/search';
import { CommonTableHoC } from 'components/common/table';
import { CommonToast } from 'components/common/toast';
import { AuthContext } from 'contexts/auth.context';
import format from 'date-fns-tz/format';
import parseISO from 'date-fns/parseISO';
import { LOCAL_DATETIME_FORMAT, LOCAL_TIMEZONE } from 'enums/env';
import { INotification } from 'interfaces/i-notification';
import { ITableColumn } from 'interfaces/tables/columns';
import { ITableSelectable } from 'interfaces/tables/selection';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { LanguageCode } from 'lib_ts/enums/translation';
import { IError } from 'lib_ts/interfaces/common/i-error';
import { IErrorType } from 'lib_ts/interfaces/common/i-error-type';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import { IMachineDetails } from 'lib_ts/interfaces/i-machine-details';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AdminErrorTypesService } from 'services/admin/error-types.service';
import { AdminMachinesService } from 'services/admin/machines.service';

const RECORDS_PER_REQUEST = 50;

interface IProps {
  details: IMachineDetails;
}

const translateUserMessage = (
  type: IErrorType,
  language: LanguageCode | undefined
) => {
  switch (language) {
    case LanguageCode.Korean: {
      return type.user_message_korean || type.user_message;
    }

    case LanguageCode.Japanese: {
      return type.user_message_japanese || type.user_message;
    }

    case LanguageCode.English:
    default: {
      return type.user_message;
    }
  }
};

const Preview = (props: {
  type: IErrorType | undefined;
  fallbackError: string;
}) => {
  const { current } = useContext(AuthContext);

  if (!props.type) {
    return (
      <Blockquote color={RADIX.COLOR.DANGER}>
        This error type does not exist in the database. Please contact
        engineering.
      </Blockquote>
    );
  }

  if (props.type.internal) {
    return (
      <Blockquote color={RADIX.COLOR.WARNING}>
        This is an internal error and will not present the user with any
        notification.
      </Blockquote>
    );
  }

  const config: INotification = {
    hideHeader: true,
    message_md:
      translateUserMessage(props.type, current.language) || props.fallbackError,
    color: NotifyHelper.getColorFromLevel(props.type.level),
    level: props.type.level,
  };

  return (
    <Flex direction="column" gap="1">
      <CommonToast config={config} withoutToast />
      <CommonInputHint hint_md="Read more about Markdown [here](https://www.markdownguide.org/cheat-sheet/)." />
    </Flex>
  );
};

const ScrollingToggle = (props: {
  content: string | undefined;
  defaultOpen?: boolean;
}) => {
  return (
    <CommonDetails summary="Toggle" defaultOpen={props.defaultOpen}>
      <Card size="1">
        <ScrollArea
          type="auto"
          scrollbars="vertical"
          style={{ maxHeight: 180 }}
        >
          <Text size="1">
            <pre>{props.content}</pre>
          </Text>
        </ScrollArea>
      </Card>
    </CommonDetails>
  );
};

export const ErrorsTab = (props: IProps) => {
  const [loading, setLoading] = useState(false);

  const { current } = useContext(AuthContext);

  const [filter, setFilter] = useState<{
    created: string[];
    level: string[];
    internal: string[];
    slack: string[];
    sender: string[];
  }>({ created: [], level: [], internal: [], slack: [], sender: [] });

  const [types, setTypes] = useState<IErrorType[]>([]);

  const [selected, setSelected] = useState<IError | undefined>(undefined);

  const selectedJSON = useMemo(
    () => JSON.stringify(selected, null, 2),
    [selected]
  );

  const selectedType = useMemo(() => {
    if (!selected) {
      return undefined;
    }

    return types.find((t) => t.errorID === selected.errorID);
  }, [types, selected]);

  const selectedTypeJSON = useMemo(
    () => JSON.stringify(selectedType, null, 2),
    [selectedType]
  );

  useEffect(() => {
    AdminErrorTypesService.getInstance()
      .getAll()
      .then((result) => setTypes(result));
  }, []);

  const [data, setData] = useState<IError[]>([]);

  const getLevel = useCallback(
    (error: IError) => {
      const type = types.find((t) => t.errorID === error.errorID);
      if (!type) {
        return 'N/A';
      }

      return type.level;
    },
    [types]
  );

  const getInternal = useCallback(
    (error: IError) => {
      const type = types.find((t) => t.errorID === error.errorID);
      if (!type) {
        return 'N/A';
      }

      return type.internal ? 'True' : 'False';
    },
    [types]
  );

  const getSlack = useCallback(
    (error: IError) => {
      const type = types.find((t) => t.errorID === error.errorID);
      if (!type) {
        return 'N/A';
      }

      return type.slack ? 'True' : 'False';
    },
    [types]
  );

  const columns = useMemo<ITableColumn[]>(() => {
    return [
      {
        label: 'Created',
        key: '_created',
        formatFn: (m: IError) =>
          format(parseISO(m._created), LOCAL_DATETIME_FORMAT, {
            timeZone: LOCAL_TIMEZONE,
          }),
      },
      {
        label: 'ID',
        key: 'errorID',
        formatFn: (m: IError) =>
          m.errorID ? <Badge>{m.errorID}</Badge> : undefined,
      },
      {
        label: 'Level',
        key: 'level',
        formatFn: (m: IError) => {
          const level = getLevel(m);
          return (
            <Badge
              color={
                level === 'error' || level === 'N/A'
                  ? RADIX.COLOR.DANGER
                  : level === 'warning'
                  ? RADIX.COLOR.WARNING
                  : RADIX.COLOR.INFO
              }
            >
              {level}
            </Badge>
          );
        },
      },
      {
        label: 'Internal',
        key: 'internal',
        formatFn: (m: IError) => {
          const internal = getInternal(m);
          return (
            <Badge
              color={
                internal === 'True' ? RADIX.COLOR.SUCCESS : RADIX.COLOR.DANGER
              }
            >
              {internal}
            </Badge>
          );
        },
      },
      {
        label: 'In Slack',
        key: 'in-slack',
        formatFn: (m: IError) => {
          const slack = getSlack(m);
          return (
            <Badge
              color={
                slack === 'True' ? RADIX.COLOR.SUCCESS : RADIX.COLOR.DANGER
              }
            >
              {slack}
            </Badge>
          );
        },
      },
      {
        label: 'Sender',
        key: 'sender',
        formatFn: (m: IError) =>
          m.sender ? (
            <Badge color={RADIX.COLOR.WARNING}>
              {m.sender.replace(' ', ' > ')}
            </Badge>
          ) : undefined,
      },
      {
        label: 'Message',
        key: '_message',
        disableSort: true,
        formatFn: (m: IError) => {
          const typeDef = types.find((t) => t.errorID === m.errorID);
          const message = typeDef
            ? translateUserMessage(typeDef, current.language)
            : (m as any).message;

          if (!message) {
            return <></>;
          }

          return (
            <Blockquote className="font-mono" size="1" wrap="pretty">
              {message}
            </Blockquote>
          );
        },
      },
    ];
  }, [types, getLevel, getInternal, getSlack]);

  const [noMoreData, setNoMoreData] = useState(false);

  const loadData = useCallback(
    async (notify: boolean) => {
      if (loading) {
        return;
      }

      try {
        setLoading(true);

        const latest =
          await AdminMachinesService.getInstance().getLatestMachineErrors({
            machineID: props.details.machineID,
            skip: data.length,
            limit: RECORDS_PER_REQUEST,
          });

        if (latest.length < RECORDS_PER_REQUEST) {
          setNoMoreData(true);
        }

        const next = [...data, ...latest];

        setData(next);

        if (notify) {
          const anyFilters =
            filter.sender.length > 0 || filter.created.length > 0;

          const single = latest.length === 1;

          NotifyHelper.success({
            message_md: anyFilters
              ? `Fetched ${latest.length} more ${
                  single ? 'error' : 'errors'
                }, showing filtered results only.`
              : `Fetched ${latest.length} more ${
                  single ? 'error' : 'errors'
                }, showing all results.`,
          });
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [loading, data]
  );

  useEffect(() => {
    loadData(false);
  }, []);

  const options = useMemo<{
    created: IOption[];
    level: IOption[];
    internal: IOption[];
    slack: IOption[];
    sender: IOption[];
  }>(() => {
    return {
      created: ArrayHelper.unique(
        data.map((m) =>
          format(parseISO(m._created), 'yyyy-MM-dd', {
            timeZone: LOCAL_TIMEZONE,
          })
        )
      ).map((m) => {
        const o: IOption = {
          label: m,
          value: m,
        };
        return o;
      }),
      level: ArrayHelper.unique(data.map((m) => getLevel(m))).map((m) => {
        const o: IOption = {
          label: m,
          value: m,
        };
        return o;
      }),
      internal: ArrayHelper.unique(data.map((m) => getInternal(m))).map((m) => {
        const o: IOption = {
          label: m,
          value: m,
        };
        return o;
      }),
      slack: ArrayHelper.unique(data.map((m) => getSlack(m))).map((m) => {
        const o: IOption = {
          label: m,
          value: m,
        };
        return o;
      }),
      sender: ArrayHelper.unique(data.map((m) => m.sender ?? '')).map((m) => {
        const o: IOption = {
          label: m,
          value: m,
        };
        return o;
      }),
    };
  }, [data, getLevel, getInternal, getSlack]);

  const filtered = useMemo(() => {
    return data
      .filter(
        (m) =>
          filter.created.length === 0 ||
          filter.created.includes(
            format(parseISO(m._created), 'yyyy-MM-dd', {
              timeZone: LOCAL_TIMEZONE,
            })
          )
      )
      .filter((m) => {
        if (filter.level.length === 0) {
          return true;
        }
        return filter.level.includes(getLevel(m));
      })
      .filter((m) => {
        if (filter.internal.length === 0) {
          return true;
        }
        return filter.internal.includes(getInternal(m));
      })
      .filter((m) => {
        if (filter.slack.length === 0) {
          return true;
        }
        return filter.slack.includes(getSlack(m));
      })
      .filter((m) => {
        if (filter.sender.length === 0) {
          return true;
        }

        if (!m.sender) {
          return false;
        }

        return filter.sender.includes(m.sender);
      });
  }, [data, filter, getLevel, getInternal, getSlack]);

  const tableSelection: ITableSelectable = {
    enableSelect: true,
    afterChangeSelected: (m: IError | undefined) => {
      if (!m) {
        return;
      }

      setSelected(m);
    },
  };

  return (
    <ErrorBoundary componentName="MachineDetailsErrorsTab">
      <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
        <CommonFormGrid columns={5}>
          <CommonSearchInput
            id="errors-created"
            placeholder="common.created"
            options={options.created}
            values={filter.created}
            onChange={(v) =>
              setFilter({
                ...filter,
                created: v,
              })
            }
            multiple
          />
          <CommonSearchInput
            id="errors-level"
            placeholder="common.level"
            options={options.level}
            values={filter.level}
            onChange={(v) =>
              setFilter({
                ...filter,
                level: v,
              })
            }
            multiple
          />
          <CommonSearchInput
            id="errors-internal"
            placeholder="Internal"
            options={options.internal}
            values={filter.internal}
            onChange={(v) =>
              setFilter({
                ...filter,
                internal: v,
              })
            }
            multiple
          />
          <CommonSearchInput
            id="errors-slack"
            placeholder="In Slack"
            options={options.slack}
            values={filter.slack}
            onChange={(v) =>
              setFilter({
                ...filter,
                slack: v,
              })
            }
            multiple
          />
          <CommonSearchInput
            id="errors-sender"
            placeholder="Sender"
            options={options.sender}
            values={filter.sender}
            onChange={(v) =>
              setFilter({
                ...filter,
                sender: v,
              })
            }
            multiple
          />
        </CommonFormGrid>

        <CommonTableHoC
          id="MachineErrorsLog"
          displayColumns={columns}
          displayData={filtered}
          {...tableSelection}
        />

        {!noMoreData && (
          <DialogButton
            icon={<DownloadIcon />}
            label="Load More"
            className="btn-block"
            onClick={() => loadData(true)}
          />
        )}
      </Flex>

      {selected && (
        <CommonConfirmationDialog
          key={selected._id}
          maxWidth={RADIX.DIALOG.WIDTH.LG}
          identifier={'errordetails'}
          title={
            selected.errorID
              ? format(parseISO(selected._created), LOCAL_DATETIME_FORMAT, {
                  timeZone: LOCAL_TIMEZONE,
                }) +
                ': ' +
                selected.errorID
              : 'Error Details'
          }
          action={{
            invisible: true,
          }}
          cancel={{
            label: 'common.close',
            onClick: () => setSelected(undefined),
          }}
          content={
            <DataList.Root orientation="horizontal">
              <DataList.Item>
                <DataList.Label>Exception</DataList.Label>
                <DataList.Value>
                  <ScrollingToggle content={selected.exception} defaultOpen />
                </DataList.Value>
              </DataList.Item>
              <DataList.Item>
                <DataList.Label>Traceback</DataList.Label>
                <DataList.Value>
                  <ScrollingToggle content={selected.traceback} />
                </DataList.Value>
              </DataList.Item>
              <DataList.Item>
                <DataList.Label>Error JSON</DataList.Label>
                <DataList.Value>
                  <ScrollingToggle content={selectedJSON} />
                </DataList.Value>
              </DataList.Item>

              <DataList.Item>
                <DataList.Label>Type Preview</DataList.Label>
                <DataList.Value>
                  <Preview
                    type={selectedType}
                    fallbackError={(selected as any).message}
                  />
                </DataList.Value>
              </DataList.Item>

              {selectedType && (
                <>
                  <DataList.Item>
                    <DataList.Label>CS Message</DataList.Label>
                    <DataList.Value>{selectedType.cs_message}</DataList.Value>
                  </DataList.Item>
                  <DataList.Item>
                    <DataList.Label>Dev Message</DataList.Label>
                    <DataList.Value>{selectedType.dev_message}</DataList.Value>
                  </DataList.Item>
                  <DataList.Item>
                    <DataList.Label>Patch</DataList.Label>
                    <DataList.Value>{selectedType.patch}</DataList.Value>
                  </DataList.Item>
                  <DataList.Item>
                    <DataList.Label>Resolution</DataList.Label>
                    <DataList.Value>{selectedType.resolution}</DataList.Value>
                  </DataList.Item>
                  <DataList.Item>
                    <DataList.Label>Error Type</DataList.Label>
                    <DataList.Value>
                      <ScrollingToggle content={selectedTypeJSON} />
                    </DataList.Value>
                  </DataList.Item>
                </>
              )}
            </DataList.Root>
          }
        />
      )}
    </ErrorBoundary>
  );
};
