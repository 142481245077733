import { Spinner } from '@radix-ui/themes';
import { RouteHelper } from 'classes/helpers/route.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { GameDataRouter } from 'components/sections/game-data/router';
import { PitchListHoc } from 'components/sections/pitch-list';
import { SEARCH_ID } from 'components/sections/pitch-list/store/pitch-list-store';
import { PitchListsHoC } from 'components/sections/pitch-lists';
import { Design } from 'components/sections/pitches/router/design';
import { List } from 'components/sections/pitches/router/list';
import { Uploader } from 'components/sections/pitches/router/uploader';
import { AimingProvider } from 'contexts/aiming.context';
import { AuthContext } from 'contexts/auth.context';
import { SectionsContext } from 'contexts/sections.context';
import { SectionName, SubSectionName } from 'enums/route.enums';
import { useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

const RedirectToIndex = (props: { trigger: string }) => {
  const { userSections, tryChangeSection } = useContext(SectionsContext);

  const section = userSections?.find((m) => m.value === SectionName.Pitches);

  // Keep active section state in sync with URL for subsection UI
  useEffect(() => {
    if (!section) {
      return;
    }

    tryChangeSection({
      section: section.value,
      subsection: defaultSubsection?.value,
      trigger: props.trigger,
    });
  }, []);

  if (!section) {
    // don't do/show anything until the section is found
    return <Spinner size="3" />;
  }

  const defaultSubsection = section.subsections?.find((m) => m.default);

  return (
    <Navigate
      to={RouteHelper.getSlug([section.value, defaultSubsection?.value])}
      replace
    />
  );
};

export const PitchesRouter = () => {
  const { current, restrictedGameStatus } = useContext(AuthContext);

  return (
    <ErrorBoundary componentName="PitchesRouter">
      <Routes>
        <Route
          path=""
          element={<RedirectToIndex trigger="Redirect from '/pitches'" />}
        />

        <Route
          path={RouteHelper.getSlug([SubSectionName.Library])}
          element={
            <AimingProvider>
              <PitchListHoc key={SEARCH_ID} search />
            </AimingProvider>
          }
        />

        {/* new and existing pitches */}
        <Route
          path={RouteHelper.getSlug([SubSectionName.Design])}
          element={<Design />}
        />

        <Route
          path={RouteHelper.getSlug([SubSectionName.Upload])}
          element={<Uploader />}
        />

        {current.mlb_stats_api && (
          <Route
            path={RouteHelper.getSlug([SubSectionName.GameData], ['*'])}
            element={
              restrictedGameStatus ? (
                <RedirectToIndex trigger="Redirect from '/game-data' upon restricted game status" />
              ) : (
                <GameDataRouter />
              )
            }
          />
        )}

        <Route
          path={RouteHelper.getSlug([SubSectionName.Lists])}
          element={<PitchListsHoC />}
        />

        <Route
          path={RouteHelper.getSlug([SubSectionName.List], [':id'])}
          element={<List />}
        />

        <Route
          path="*"
          element={
            <RedirectToIndex trigger="Redirect from '/pitches' wildcard" />
          }
        />
      </Routes>
    </ErrorBoundary>
  );
};
