import { t } from 'i18next';
import { useEffect, useState } from 'react';

export const DotDotDot = (props: { label: string; delay_ms?: number }) => {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    let i = 0;
    const interval = setInterval(() => {
      setCounter(++i % 4);
    }, props.delay_ms ?? 500);

    return () => clearInterval(interval);
  }, []);

  const dots = '.'.repeat(counter);
  return <>{`${t(props.label)}${dots}`}</>;
};
