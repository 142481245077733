import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { ArcPositions } from 'components/machine/arc-positions';
import { MachineContext } from 'contexts/machine.context';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { CalibrateProc } from 'lib_ts/interfaces/i-machine-msg';
import { useContext } from 'react';

const COMPONENT_NAME = 'RealignMachineDialog';

interface IProps {
  onClose: () => void;
}

export const RealignMachineDialog = (props: IProps) => {
  const machineCx = useContext(MachineContext);

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <CommonDialog
        identifier="ConfirmRealignment"
        title="Realign Machine"
        width={RADIX.DIALOG.WIDTH.SM}
        content={
          <ArcPositions machineCx={machineCx} procs={[CalibrateProc.All]} />
        }
        onClose={props.onClose}
      />
    </ErrorBoundary>
  );
};
