import { CheckCircledIcon, CrossCircledIcon } from '@radix-ui/react-icons';
import { Flex, Text } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { WebSocketHelper } from 'classes/helpers/web-socket.helper';
import { CommonCallout } from 'components/common/callouts';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonProgress } from 'components/common/progress';
import { IMachineContext } from 'contexts/machine.context';
import { IntercomEvents } from 'enums/intercom.enums';
import { clamp } from 'lib_ts/classes/math.utilities';
import { WsMsgType } from 'lib_ts/enums/machine-msg.enum';
import { RADIX } from 'lib_ts/enums/radix-ui';
import {
  CalibrateProc,
  ICalibrateProgressMsg,
  ICalibrateResponseMsg,
} from 'lib_ts/interfaces/i-machine-msg';
import React from 'react';

const COMPONENT_NAME = 'ArcPositions';

const MAX_WAIT_MIN = 4;

// max time (in seconds) that the user will be expected to wait before a failure is assumed
const MAX_WAIT_SEC = MAX_WAIT_MIN * 60;

// progress will update every 4 seconds
const PROGRESS_FPS = 0.25;

interface IProps {
  machineCx: IMachineContext;
  procs?: CalibrateProc[];
}

interface IState {
  progressPercent: number;
  progress?: ICalibrateProgressMsg;

  result?: ICalibrateResponseMsg;

  waitSec: number;
}

export class ArcPositions extends React.Component<IProps, IState> {
  private init = false;
  private waitInterval: any;

  constructor(props: IProps) {
    super(props);

    this.state = {
      progressPercent: 0,
      waitSec: 0,
    };

    this.handleCompletion = this.handleCompletion.bind(this);
    this.handleProgress = this.handleProgress.bind(this);
    this.startCalibrating = this.startCalibrating.bind(this);
  }

  componentDidMount(): void {
    WebSocketHelper.on(
      WsMsgType.M2U_CalibrationResponse,
      this.handleCompletion
    );
    WebSocketHelper.on(WsMsgType.M2U_CalibrateProgress, this.handleProgress);

    if (this.init) {
      return;
    }

    this.init = true;

    // directly trigger realign process
    this.startCalibrating('ArcPositions (mount)');
  }

  componentWillUnmount(): void {
    WebSocketHelper.remove(
      WsMsgType.M2U_CalibrationResponse,
      this.handleCompletion
    );

    WebSocketHelper.remove(
      WsMsgType.M2U_CalibrateProgress,
      this.handleProgress
    );

    clearInterval(this.waitInterval);
  }

  private handleCompletion(event: CustomEvent) {
    const data: ICalibrateResponseMsg = event.detail;

    if (data.status === undefined) {
      // shouldn't trigger, but just in case old FW reports before completion
      return;
    }

    clearInterval(this.waitInterval);

    this.setState({
      result: data,
      progressPercent: 1,
    });
  }

  private handleProgress(event: CustomEvent) {
    // bypass all the interval logic as soon as we get a progress update
    clearInterval(this.waitInterval);

    const data: ICalibrateProgressMsg = event.detail;

    const countCompleted = data.progress_list.filter(
      (p) => p.success !== undefined
    ).length;

    // avoid dividing by 0
    const total = data.progress_list.length || 1;

    this.setState({
      progressPercent: clamp(countCompleted / total, 0, 1),
      progress: data,
    });

    if (data.progress_list.findIndex((p) => p.success === undefined) === -1) {
      // everything is done calibrating or failed to calibrate
      const failed = data.progress_list.filter((p) => p.success === false);

      if (failed.length > 0) {
        NotifyHelper.error({
          inbox: true,
          message_md:
            'Your machine has failed to realign. Please contact support.',
          buttons: [
            {
              label: 'common.contact-support',
              dismissAfterClick: true,
              onClick: () => {
                document.dispatchEvent(
                  new CustomEvent(IntercomEvents.ShowNewMessage, {
                    detail: undefined,
                  })
                );
              },
            },
          ],
        });
        return;
      }

      NotifyHelper.success({
        message_md: 'Calibration finished successfully!',
      });

      this.props.machineCx.setLastStatus({
        ...this.props.machineCx.lastStatus,
        calibrated: true,
      });
    }
  }

  private startCalibrating(trigger: string) {
    clearInterval(this.waitInterval);

    this.waitInterval = setInterval(() => {
      if (this.state.waitSec >= MAX_WAIT_SEC) {
        clearInterval(this.waitInterval);

        this.setState({
          progressPercent: 1,
        });
        return;
      }

      const nextWaitSec = this.state.waitSec + 1 / PROGRESS_FPS;

      this.setState({
        progressPercent: clamp(nextWaitSec / MAX_WAIT_SEC, 0, 1),
        waitSec: nextWaitSec,
      });
    }, 1_000 / PROGRESS_FPS);

    this.props.machineCx.calibrate(
      this.props.procs ?? [CalibrateProc.All],
      trigger
    );
  }

  render() {
    // if we have a result, assumption is we're done so progress should be 100%
    const progress = (this.state.result ? 1 : this.state.progressPercent) * 100;

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <Flex direction="column" gap="2">
          <Flex justify="between">
            <Text color={RADIX.COLOR.SECONDARY}>Progress</Text>
            <Text color={RADIX.COLOR.SECONDARY}>{`${progress.toFixed(
              0
            )}%`}</Text>
          </Flex>

          <CommonProgress value={progress} />

          {!this.state.result && (
            <Text>
              This may take a few minutes. Ensure the track is clear as the
              machine may move to the 3rd base side.
            </Text>
          )}

          {this.state.result && this.state.result.status && (
            <CommonCallout
              icon={<CheckCircledIcon />}
              color={RADIX.COLOR.SUCCESS}
              text="Machine realignment complete, please try firing a test pitch before continuing your session."
            />
          )}

          {this.state.result && !this.state.result.status && (
            <CommonCallout
              icon={<CrossCircledIcon />}
              color={RADIX.COLOR.DANGER}
              text="Machine realignment failed, please try again or contact support."
            />
          )}
        </Flex>
      </ErrorBoundary>
    );
  }
}
