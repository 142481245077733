import { Button, Flex } from '@radix-ui/themes';
import { CommonDualSlider } from 'components/common/form/dual-slider';
import useAnalyticsStore from 'components/sections/analytics/store/use-analytics-store';
import { t } from 'i18next';
import { IHitterSessionAdvancedFilters } from 'interfaces/i-hitter-session-filters';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { ISessionHit } from 'lib_ts/interfaces/i-session-hit';
import { useState } from 'react';

const getMinMaxFilter = ({
  key,
  value,
  acc,
}: {
  key: keyof IHitterSessionAdvancedFilters;
  value?: number;
  acc: IHitterSessionAdvancedFilters;
}): [number, number] | null => {
  const { getMin, getMax } = MiscHelper;

  // Exclude this hit's value if it's undefined
  if (value !== undefined) {
    if (acc[key].minMax) {
      const existing = acc[key].minMax as [number, number];
      return [getMin([value, existing[0]]), getMax([value, existing[1]])];
    } else {
      return [value, value];
    }
  }

  return null;
};

const generateAdvancedFilters = (hits: ISessionHit[]) =>
  hits.reduce(
    (acc, { hit, pitch }) => {
      const { exitMPH, vLaunchDEG, distanceFT } = hit;
      const { speedMPH, xBreakIN, zBreakIN } = pitch;

      const xBreakINfilter = getMinMaxFilter({
        key: 'xBreakIN',
        value: xBreakIN,
        acc,
      });
      acc.xBreakIN.minMax = xBreakINfilter;
      acc.xBreakIN.value = xBreakINfilter;

      const zBreakINfilter = getMinMaxFilter({
        key: 'zBreakIN',
        value: zBreakIN,
        acc,
      });
      acc.zBreakIN.minMax = zBreakINfilter;
      acc.zBreakIN.value = zBreakINfilter;

      const exitMPHfilter = getMinMaxFilter({
        key: 'exitMPH',
        value: exitMPH,
        acc,
      });
      acc.exitMPH.minMax = exitMPHfilter;
      acc.exitMPH.value = exitMPHfilter;

      const speedMPHfilter = getMinMaxFilter({
        key: 'speedMPH',
        value: speedMPH,
        acc,
      });
      acc.speedMPH.minMax = speedMPHfilter;
      acc.speedMPH.value = speedMPHfilter;

      const vLaunchDEGfilter = getMinMaxFilter({
        key: 'vLaunchDEG',
        value: vLaunchDEG,
        acc,
      });
      acc.vLaunchDEG.minMax = vLaunchDEGfilter;
      acc.vLaunchDEG.value = vLaunchDEGfilter;

      const distanceFTfilter = getMinMaxFilter({
        key: 'distanceFT',
        value: distanceFT,
        acc,
      });
      acc.distanceFT.minMax = distanceFTfilter;
      acc.distanceFT.value = distanceFTfilter;

      return acc;
    },
    {
      speedMPH: {
        label: t('common.pitch-speed-mph'),
        minMax: null,
        value: null,
        step: 0.1,
        roundTo: 1,
      },
      xBreakIN: {
        label: t('common.horizontal-break-in'),
        minMax: null,
        value: null,
        step: 0.1,
        roundTo: 1,
      },
      zBreakIN: {
        label: t('common.vertical-break-in'),
        minMax: null,
        value: null,
        step: 0.1,
        roundTo: 1,
      },
      exitMPH: {
        label: t('common.exit-velocity-mph'),
        minMax: null,
        value: null,
        step: 0.1,
        roundTo: 1,
      },
      vLaunchDEG: {
        label: t('common.launch-angle-deg'),
        minMax: null,
        value: null,
        step: 0.1,
        roundTo: 1,
      },
      distanceFT: {
        label: t('common.distance-ft'),
        minMax: null,
        value: null,
        step: 1,
        roundTo: 0,
      },
    } as IHitterSessionAdvancedFilters
  );

interface IAdvancedFiltersProps {
  onApply: () => void;
  onReset: () => void;
}

export const AdvancedFilters = ({
  onApply,
  onReset,
}: IAdvancedFiltersProps) => {
  const { hits, advancedFilters, setAdvancedFilters } = useAnalyticsStore();

  // Controlled inputs scoped to this component only to minimize unnecessary re-renders
  const [filterInputs, setFilterInputs] =
    useState<IHitterSessionAdvancedFilters>(
      advancedFilters || generateAdvancedFilters(hits)
    );

  const reset = () => {
    setAdvancedFilters(null);
    onReset();
  };

  return (
    <Flex direction="column" gap={RADIX.FLEX.GAP.MD} width="375px">
      <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
        {Object.keys(filterInputs).map((key) => {
          const { label, minMax, value, step, roundTo } =
            filterInputs[key as keyof IHitterSessionAdvancedFilters];

          // Ignore if column has no data or only one value
          if (!minMax || minMax[0] === minMax[1]) {
            return null;
          }
          return (
            <CommonDualSlider
              id={key}
              key={key}
              label={label}
              defaultValue={minMax}
              min={minMax[0]}
              max={minMax[1]}
              step={step}
              minStepsBetweenThumbs={step}
              roundTo={roundTo}
              value={value as [number, number]}
              onValueChange={(value) =>
                setFilterInputs((prev) => ({
                  ...prev,
                  [key]: {
                    ...prev[key as keyof IHitterSessionAdvancedFilters],
                    value,
                  },
                }))
              }
            />
          );
        })}
      </Flex>
      <Flex justify="end" gap={RADIX.FLEX.GAP.MD}>
        <Button
          variant="soft"
          size={RADIX.BUTTON.SIZE.SM}
          color={RADIX.COLOR.NEUTRAL}
          onClick={reset}
        >
          {t('common.reset')}
        </Button>
        <Button
          variant="solid"
          size={RADIX.BUTTON.SIZE.SM}
          onClick={() => {
            setAdvancedFilters(filterInputs);
            onApply();
          }}
        >
          {t('common.apply-filters')}
        </Button>
      </Flex>
    </Flex>
  );
};
