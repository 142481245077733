import { IServerResponse } from 'lib_ts/interfaces/common/i-server-response';
import { IHitter } from 'lib_ts/interfaces/i-hitter';
import {
  ISessionHit,
  ISessionHitDeleteRequest,
  ISessionHitSummary,
} from 'lib_ts/interfaces/i-session-hit';
import { BaseRESTService } from 'services/_base-rest.service';

export class HittersService extends BaseRESTService {
  private static instance: HittersService;
  static getInstance(): HittersService {
    if (!HittersService.instance) {
      HittersService.instance = new HittersService();
    }

    return HittersService.instance;
  }

  private constructor() {
    super({
      controller: 'hitters',
    });
  }

  async getMachineSessions(config: {
    machineID: string;
    limit: number;
  }): Promise<ISessionHitSummary[]> {
    return await this.get({
      uri: `machine/${config.machineID}/sessions/${config.limit}`,
    }).then((result: IServerResponse) => {
      if (!result.success || !result.data || result.error) {
        console.error(
          result.error ?? 'encountered an error in getMachineSessions'
        );
        return [];
      }

      return result.data as ISessionHitSummary[];
    });
  }

  async deleteSessions(payload: ISessionHitDeleteRequest[]): Promise<boolean> {
    return await this.post(
      {
        uri: `delete/sessions`,
      },
      payload
    ).then((result: IServerResponse) => {
      if (!result.success || result.error) {
        console.error(result.error ?? 'encountered an error in deleteSessions');
        return false;
      }

      return true;
    });
  }

  async getHitterSession(config: {
    hitterID: string;
    sessionID: string;
  }): Promise<ISessionHit[]> {
    return await this.get({
      uri: `hitter/${config.hitterID}/session/${config.sessionID}`,
    }).then((result: IServerResponse) => {
      if (!result.success || !result.data || result.error) {
        console.error(
          result.error ?? 'encountered an error in getHitterSession'
        );
        return [];
      }

      return result.data as ISessionHit[];
    });
  }

  async updateHit(
    id: string,
    data: Partial<ISessionHit>
  ): Promise<ISessionHit> {
    return await this.put(
      {
        uri: `hit/${id}`,
      },
      data
    );
  }

  async getHitters(): Promise<IHitter[]> {
    return await this.get({
      uri: 'list',
    }).then((result: IServerResponse) => {
      if (!result.success || !result.data || result.error) {
        console.error(result.error ?? 'encountered an error in getHitters');
        return [];
      }

      return result.data as IHitter[];
    });
  }

  /** use post to allow deleting multiple at once */
  async deleteHitters(ids: string[]): Promise<boolean> {
    return await this.post(
      {
        uri: 'delete',
      },
      ids
    ).then((result) => !!result && result.success);
  }

  async postHitter(data: Partial<IHitter>): Promise<IHitter> {
    return await this.post(
      {
        uri: 'create',
      },
      data
    );
  }

  async updateHitter(data: Partial<IHitter>): Promise<IHitter> {
    if (!data._id) {
      throw new Error('Cannot put without id');
    }

    return await this.put(
      {
        uri: '',
        params: {
          hitter_id: data._id,
        } as any,
      },
      data
    );
  }
}
