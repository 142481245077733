import {
  Box,
  Flex,
  Grid,
  Heading,
  RadioCards,
  Skeleton,
} from '@radix-ui/themes';
import { PitchDesignHelper } from 'classes/helpers/pitch-design.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonTextInput } from 'components/common/form/text';
import { CommonTooltip } from 'components/common/tooltip';
import { CookiesContext } from 'contexts/cookies.context';
import { MachineContext } from 'contexts/machine.context';
import { PitchDesignContext } from 'contexts/pitch-lists/pitch-design.context';
import { CookieKey } from 'enums/cookies.enums';
import { t } from 'i18next';
import { BallHelper } from 'lib_ts/classes/ball.helper';
import { BuildPriority } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { ISpin, ISpinExt } from 'lib_ts/interfaces/pitches/i-base';
import { useContext, useMemo, useState } from 'react';

const COMPONENT_NAME = 'MainForm';

const getSpinTooltipMD = (spin: 'x' | 'y' | 'z'): string => {
  switch (spin) {
    case 'x': {
      return [
        t('pd.rpm-from-pitcher-pov').toString(),
        `**${t('pd.positive')}**: ${t('pd.top-spin')}`,
        `**${t('pd.negative')}**: ${t('pd.back-spin')}`,
      ].join('\n\n');
    }

    case 'y': {
      return [
        t('pd.rpm-from-pitcher-pov').toString(),
        `**${t('pd.positive')}**: ${t('pd.counter-clockwise')}`,
        `**${t('pd.negative')}**: ${t('pd.clockwise')}`,
      ].join('\n\n');
    }

    case 'z': {
      return [
        t('pd.rpm-from-pitcher-pov').toString(),
        `**${t('pd.positive')}**: ${t('pd.spin-left')}`,
        `**${t('pd.negative')}**: ${t('pd.spin-right')}`,
      ].join('\n\n');
    }

    default: {
      return '';
    }
  }
};

const DECIMALS_SPEED = 1;
const DECIMALS_SPIN = 0;
const DECIMALS_BREAKS = 1;

export const MainForm = (props: { showCurrent: boolean }) => {
  const { ball, priority, reference, referenceKey, setPriority, mergeBall } =
    useContext(PitchDesignContext);
  const { setCookie } = useContext(CookiesContext);
  const { activeModel, buildOptions } = useContext(MachineContext);

  const refBall = useMemo(() => {
    const output = BallHelper.getCharsFromPitch(reference);

    console.debug({
      event: 'updating refBall because reference changed',
      reference,
      output,
    });

    return output;
  }, [reference]);

  const [speed, setSpeed] = useState((ball.speed ?? 0).toFixed(DECIMALS_SPEED));

  const [wx, setWx] = useState((ball.wx ?? 0).toFixed(DECIMALS_SPIN));
  const [wy, setWy] = useState((ball.wy ?? 0).toFixed(DECIMALS_SPIN));
  const [wz, setWz] = useState((ball.wz ?? 0).toFixed(DECIMALS_SPIN));

  const [wnet, setWnet] = useState((ball.wnet ?? 0).toFixed(DECIMALS_SPIN));
  const [gyro, setGyro] = useState(
    (ball.gyro_angle ?? 0).toFixed(DECIMALS_SPIN)
  );
  const [waxis, setWaxis] = useState((ball.waxis ?? 0).toFixed(DECIMALS_SPIN));

  // saved value is the opposite of what needs to be visible to the user (to get to hawkeye frame of ref)
  const [breaksX, setBreaksX] = useState(
    (-(ball.breaks?.xInches ?? 0)).toFixed(DECIMALS_BREAKS)
  );
  const [breaksZ, setBreaksZ] = useState(
    (ball.breaks?.zInches ?? 0).toFixed(DECIMALS_BREAKS)
  );

  return (
    <ErrorBoundary componentName="PDMainForm">
      <Flex direction="column" gap={RADIX.FLEX.GAP.FORM}>
        <Heading size={RADIX.HEADING.SIZE.SM}>{t('pd.input-mode')}</Heading>
        {activeModel ? (
          <RadioCards.Root
            size="1"
            gap={RADIX.FLEX.GAP.SM}
            value={priority}
            onValueChange={(v) => {
              setPriority(v as BuildPriority);

              setCookie(CookieKey.app, {
                build_priority: v as BuildPriority,
              });

              switch (v) {
                case BuildPriority.Breaks: {
                  setSpeed((ball.speed ?? 0).toFixed(1));
                  setWy((ball.wy ?? 0).toFixed(0));
                  setBreaksX((-(ball.breaks?.xInches ?? 0)).toFixed(1));
                  setBreaksZ((ball.breaks?.zInches ?? 0).toFixed(1));

                  break;
                }

                case BuildPriority.Spins: {
                  setSpeed((ball.speed ?? 0).toFixed(1));
                  setWnet((ball.wnet ?? 0).toFixed(0));
                  setGyro((ball.gyro_angle ?? 0).toFixed(0));
                  setWaxis((ball.waxis ?? 0).toFixed(0));

                  break;
                }

                case BuildPriority.Default: {
                  setSpeed((ball.speed ?? 0).toFixed(1));
                  setWx((ball.wx ?? 0).toFixed(0));
                  setWy((ball.wy ?? 0).toFixed(0));
                  setWz((ball.wz ?? 0).toFixed(0));

                  return;
                }

                default: {
                  // do nothing
                  console.warn(`encountered unexpected build priority: ${v}`);
                  return;
                }
              }
            }}
          >
            {activeModel.supports_spins ? (
              <RadioCards.Item value={BuildPriority.Default}>
                {t('pd.default')}
              </RadioCards.Item>
            ) : (
              <CommonTooltip
                text="pu.unsupported-spins-msg"
                trigger={
                  <RadioCards.Item value={BuildPriority.Default} disabled>
                    {`${t('pd.default')} (${t(
                      'common.unsupported'
                    ).toLowerCase()})`}
                  </RadioCards.Item>
                }
              />
            )}

            {buildOptions.map((v, i) => {
              const isDefault =
                props.showCurrent && v.value === reference.priority;

              if (!v.disabled) {
                return (
                  <RadioCards.Item key={i} value={v.value}>
                    {t(v.label)}
                    {isDefault ? '*' : ''}
                  </RadioCards.Item>
                );
              }

              return (
                <CommonTooltip
                  key={i}
                  text={
                    v.value === BuildPriority.Spins
                      ? 'pu.unsupported-spins-msg'
                      : v.value === BuildPriority.Breaks
                      ? 'pu.unsupported-breaks-msg'
                      : undefined
                  }
                  trigger={
                    <RadioCards.Item value={v.value} disabled>
                      {`${t(v.label)}${isDefault ? '*' : ''} (${t(
                        'common.unsupported'
                      ).toLowerCase()})`}
                    </RadioCards.Item>
                  }
                />
              );
            })}
          </RadioCards.Root>
        ) : (
          <Skeleton />
        )}

        <Grid columns="4" gap={RADIX.FLEX.GAP.SM}>
          <Box>
            <CommonTextInput
              key={`speed-${referenceKey}`}
              id="pitch-design-speed"
              label={t('pd.speed-units', { units: 'mph' }).toString()}
              inputColor={
                PitchDesignHelper.validateBallSpeed(ball.speed)
                  ? undefined
                  : RADIX.COLOR.WARNING
              }
              type="number"
              value={speed}
              onChange={(v) => {
                setSpeed(v ?? '');
              }}
              onNumericChange={(v) =>
                mergeBall({
                  ball: {
                    speed: v,
                  },
                  trigger: COMPONENT_NAME,
                })
              }
              hint_md={
                props.showCurrent
                  ? t('pd.current-x', {
                      x: (refBall.speed ?? 0).toFixed(DECIMALS_SPEED),
                    }).toString()
                  : undefined
              }
            />
          </Box>

          {(() => {
            switch (priority) {
              case BuildPriority.Breaks: {
                return (
                  <>
                    <Box>
                      <CommonTextInput
                        key={`gyro-${referenceKey}`}
                        id="pitch-design-spin-gyro"
                        label="pd.gyro-spin"
                        inputColor={
                          PitchDesignHelper.validateSpin(ball.wy)
                            ? undefined
                            : RADIX.COLOR.WARNING
                        }
                        type="number"
                        value={wy}
                        onChange={(v) => {
                          setWy(v ?? '');
                        }}
                        // NET SPIN - BREAKS MODE
                        onNumericChange={(v) =>
                          mergeBall({
                            ball: {
                              wy: v,
                            },
                            trigger: COMPONENT_NAME,
                          })
                        }
                        hint_md={
                          props.showCurrent
                            ? t('pd.current-x', {
                                x: (refBall.wy ?? 0).toFixed(DECIMALS_SPIN),
                              }).toString()
                            : undefined
                        }
                      />
                    </Box>
                    <Box>
                      <CommonTextInput
                        key={`v-break-${referenceKey}`}
                        id="pitch-design-vert-break"
                        label="pd.vert-break-in"
                        iconTooltip={PitchDesignHelper.VB_TOOLTIP_TEXT}
                        inputColor={
                          PitchDesignHelper.validateBreak(ball.breaks?.zInches)
                            ? undefined
                            : RADIX.COLOR.WARNING
                        }
                        type="number"
                        value={breaksZ}
                        onChange={(v) => {
                          setBreaksZ(v ?? '');
                        }}
                        onNumericChange={(v) =>
                          mergeBall({
                            ball: {
                              breaks: {
                                zInches: v,
                                xInches: -parseFloat(breaksX),
                              },
                            },
                            trigger: COMPONENT_NAME,
                          })
                        }
                        hint_md={
                          props.showCurrent
                            ? t('pd.current-x', {
                                x: (refBall.breaks?.zInches ?? 0).toFixed(
                                  DECIMALS_BREAKS
                                ),
                              }).toString()
                            : undefined
                        }
                      />
                    </Box>
                    <Box>
                      <CommonTextInput
                        key={`h-break-${referenceKey}`}
                        id="pitch-design-hor-break"
                        label="pd.hor-break-in"
                        iconTooltip={PitchDesignHelper.HB_TOOLTIP_TEXT}
                        inputColor={
                          PitchDesignHelper.validateBreak(ball.breaks?.xInches)
                            ? undefined
                            : RADIX.COLOR.WARNING
                        }
                        type="number"
                        value={breaksX}
                        onChange={(v) => {
                          setBreaksX(v ?? '');
                        }}
                        onNumericChange={(v) =>
                          mergeBall({
                            ball: {
                              breaks: {
                                xInches: -v,
                                zInches: parseFloat(breaksZ),
                              },
                            },
                            trigger: COMPONENT_NAME,
                          })
                        }
                        hint_md={
                          props.showCurrent
                            ? t('pd.current-x', {
                                x: (-(refBall.breaks?.xInches ?? 0)).toFixed(
                                  DECIMALS_BREAKS
                                ),
                              }).toString()
                            : undefined
                        }
                      />
                    </Box>
                  </>
                );
              }

              case BuildPriority.Spins: {
                return (
                  <>
                    <Box>
                      <CommonTextInput
                        key={`spin-${referenceKey}`}
                        id="pitch-design-spin-net"
                        label="pd.net-spin"
                        inputColor={
                          PitchDesignHelper.validateSpin(ball.wnet)
                            ? undefined
                            : RADIX.COLOR.WARNING
                        }
                        type="number"
                        value={wnet}
                        onChange={(v) => {
                          setWnet(v ?? '');
                        }}
                        // NET SPIN - NET MODE
                        onNumericChange={(v) => {
                          const nextSpinExt: ISpinExt = {
                            gyro_angle: ball.gyro_angle ?? 0,
                            waxis: ball.waxis ?? 0,
                            wnet: v,
                          };

                          // don't update spin if the value isn't valid
                          const nextSpin = PitchDesignHelper.validateSpin(v)
                            ? BallHelper.convertSpinExtToSpin(nextSpinExt)
                            : undefined;

                          mergeBall({
                            ball: {
                              wnet: v,
                              ...nextSpin,
                            },
                            trigger: COMPONENT_NAME,
                          });
                        }}
                        hint_md={
                          props.showCurrent
                            ? t('pd.current-x', {
                                x: (refBall.wnet ?? 0).toFixed(DECIMALS_SPIN),
                              }).toString()
                            : undefined
                        }
                      />
                    </Box>
                    <Box>
                      <CommonTextInput
                        key={`gyro-${referenceKey}`}
                        id="pitch-design-gyro"
                        label="pd.gyro-angle-deg"
                        inputColor={
                          PitchDesignHelper.validateGyroAngle(ball.gyro_angle)
                            ? undefined
                            : RADIX.COLOR.WARNING
                        }
                        type="number"
                        value={gyro}
                        onChange={(v) => {
                          setGyro(v ?? '');
                        }}
                        onNumericChange={(v) => {
                          const nextSpinExt: ISpinExt = {
                            gyro_angle: v,
                            waxis: ball.waxis ?? 0,
                            wnet: ball.wnet ?? 0,
                          };

                          // don't update spin if the value isn't valid
                          const nextSpin = PitchDesignHelper.validateGyroAngle(
                            v
                          )
                            ? BallHelper.convertSpinExtToSpin(nextSpinExt)
                            : undefined;

                          mergeBall({
                            ball: {
                              gyro_angle: v,
                              ...nextSpin,
                            },
                            trigger: COMPONENT_NAME,
                          });
                        }}
                        hint_md={
                          props.showCurrent
                            ? t('pd.current-x', {
                                x: (refBall.gyro_angle ?? 0).toFixed(
                                  DECIMALS_SPIN
                                ),
                              }).toString()
                            : undefined
                        }
                      />
                    </Box>
                    <Box>
                      <CommonTextInput
                        key={`axis-${referenceKey}`}
                        id="pitch-design-spin-axis"
                        label="pd.spin-axis-deg"
                        inputColor={
                          PitchDesignHelper.validateSpinAxis(ball.waxis)
                            ? undefined
                            : RADIX.COLOR.WARNING
                        }
                        type="number"
                        value={waxis}
                        onChange={(v) => {
                          setWaxis(v ?? '');
                        }}
                        onNumericChange={(v) => {
                          const nextSpinExt: ISpinExt = {
                            gyro_angle: ball.gyro_angle ?? 0,
                            wnet: ball.wnet ?? 0,
                            waxis: v,
                          };

                          // don't update spin if the value isn't valid
                          const nextSpin = PitchDesignHelper.validateSpinAxis(v)
                            ? BallHelper.convertSpinExtToSpin(nextSpinExt)
                            : undefined;

                          mergeBall({
                            ball: {
                              waxis: v,
                              ...nextSpin,
                            },
                            trigger: COMPONENT_NAME,
                          });
                        }}
                        hint_md={
                          props.showCurrent
                            ? t('pd.current-x', {
                                x: (refBall.waxis ?? 0).toFixed(DECIMALS_SPIN),
                              }).toString()
                            : undefined
                        }
                      />
                    </Box>
                  </>
                );
              }

              case BuildPriority.Default:
              default: {
                return (
                  <>
                    <Box>
                      <CommonTextInput
                        key={`spin-x-${referenceKey}`}
                        id="pitch-design-spin-x"
                        label="pd.spin-x"
                        iconTooltip={getSpinTooltipMD('x')}
                        inputColor={
                          PitchDesignHelper.validateSpin(ball.wx)
                            ? undefined
                            : RADIX.COLOR.WARNING
                        }
                        type="number"
                        value={wx}
                        onChange={(v) => {
                          setWx(v ?? '');
                        }}
                        onNumericChange={(v) => {
                          const nextSpin: ISpin = {
                            wx: v,
                            wy: ball.wy ?? 0,
                            wz: ball.wz ?? 0,
                          };

                          const nextSpinExt = PitchDesignHelper.validateSpin(v)
                            ? BallHelper.convertSpinToSpinExt(nextSpin)
                            : undefined;

                          mergeBall({
                            ball: {
                              ...nextSpinExt,
                              wx: v,
                            },
                            trigger: COMPONENT_NAME,
                          });
                        }}
                        hint_md={
                          props.showCurrent
                            ? t('pd.current-x', {
                                x: (refBall.wx ?? 0).toFixed(DECIMALS_SPIN),
                              }).toString()
                            : undefined
                        }
                      />
                    </Box>
                    <Box>
                      <CommonTextInput
                        key={`spin-y-${referenceKey}`}
                        id="pitch-design-spin-y"
                        label="pd.spin-y"
                        type="number"
                        iconTooltip={getSpinTooltipMD('y')}
                        inputColor={
                          PitchDesignHelper.validateSpin(ball.wy)
                            ? undefined
                            : RADIX.COLOR.WARNING
                        }
                        value={wy}
                        onChange={(v) => {
                          setWy(v ?? '');
                        }}
                        onNumericChange={(v) => {
                          const nextSpin: ISpin = {
                            wy: v,
                            wx: ball.wx ?? 0,
                            wz: ball.wz ?? 0,
                          };

                          const nextSpinExt = PitchDesignHelper.validateSpin(v)
                            ? BallHelper.convertSpinToSpinExt(nextSpin)
                            : undefined;

                          mergeBall({
                            ball: {
                              ...nextSpinExt,
                              wy: v,
                            },
                            trigger: COMPONENT_NAME,
                          });
                        }}
                        hint_md={
                          props.showCurrent
                            ? t('pd.current-x', {
                                x: (refBall.wy ?? 0).toFixed(DECIMALS_SPIN),
                              }).toString()
                            : undefined
                        }
                      />
                    </Box>
                    <Box>
                      <CommonTextInput
                        key={`spin-z-${referenceKey}`}
                        id="pitch-design-spin-z"
                        type="number"
                        label="pd.spin-z"
                        iconTooltip={getSpinTooltipMD('z')}
                        inputColor={
                          PitchDesignHelper.validateSpin(ball.wz)
                            ? undefined
                            : RADIX.COLOR.WARNING
                        }
                        value={wz}
                        onChange={(v) => {
                          setWz(v ?? '');
                        }}
                        onNumericChange={(v) => {
                          const nextSpin: ISpin = {
                            wz: v,
                            wx: ball.wx ?? 0,
                            wy: ball.wy ?? 0,
                          };

                          const nextSpinExt = PitchDesignHelper.validateSpin(v)
                            ? BallHelper.convertSpinToSpinExt(nextSpin)
                            : undefined;

                          mergeBall({
                            ball: {
                              ...nextSpinExt,
                              wz: v,
                            },
                            trigger: COMPONENT_NAME,
                          });
                        }}
                        hint_md={
                          props.showCurrent
                            ? t('pd.current-x', {
                                x: (refBall.wz ?? 0).toFixed(DECIMALS_SPIN),
                              }).toString()
                            : undefined
                        }
                      />
                    </Box>
                  </>
                );
              }
            }
          })()}
        </Grid>
      </Flex>
    </ErrorBoundary>
  );
};
