import { Box, Grid } from '@radix-ui/themes';
import { CommonSearchInput } from 'components/common/form/search';
import useAnalyticsStore from 'components/sections/analytics/store/use-analytics-store';
import { lightFormat } from 'date-fns';
import parseISO from 'date-fns/parseISO';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { ISessionHitSummary } from 'lib_ts/interfaces/i-session-hit';
import { useMemo } from 'react';

const getUniqueHitters = (sessions: ISessionHitSummary[]) => {
  // { [hitterID]: ISessionHitSummary }
  const uniqueHitters = sessions.reduce<Record<string, ISessionHitSummary>>(
    (acc, session) => {
      const { hitterID } = session;
      if (!acc[hitterID]) {
        acc[hitterID] = session;
      }
      return acc;
    },
    {}
  );

  // Only return unique hitters with a name we can search for
  return Object.values(uniqueHitters).filter((h) => h.hitterName);
};

const getUniqueStartDates = (sessions: ISessionHitSummary[]) =>
  ArrayHelper.unique(
    sessions.map((m) => lightFormat(parseISO(m.startDate), 'yyyy-MM-dd'))
  ).sort((a: string, b: string) => a.localeCompare(b));

export const HittingReportsToolbar = () => {
  const {
    sessions,
    batterFilter,
    setBatterFilter,
    startDateFilter,
    setStartDateFilter,
  } = useAnalyticsStore();

  const hitters = useMemo(() => getUniqueHitters(sessions), [sessions]);

  const startDates = useMemo(() => getUniqueStartDates(sessions), [sessions]);

  return (
    <Grid columns="4" gap={RADIX.FLEX.GAP.SM}>
      <Box gridColumn="span 2">
        <CommonSearchInput
          id="filter-name"
          placeholder="common.batter-name"
          options={hitters.map(({ hitterName, hitterID }) => ({
            label: hitterName || '',
            value: hitterID || '',
          }))}
          values={batterFilter}
          onChange={setBatterFilter}
          multiple
          strict
        />
      </Box>
      <Box gridColumn="span 2">
        <CommonSearchInput
          id="filter-session-start"
          placeholder="common.start-date"
          options={startDates.map((startDate) => ({
            label: startDate,
            value: startDate,
          }))}
          values={startDateFilter}
          onChange={setStartDateFilter}
          multiple
        />
      </Box>
    </Grid>
  );
};
