import {
  DownloadIcon,
  PlusIcon,
  UpdateIcon,
  UploadIcon,
} from '@radix-ui/react-icons';
import { Badge, Box, Flex, Grid, Skeleton } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonSimpleFileUploader } from 'components/common/file-uploader';
import { CommonSearchInput } from 'components/common/form/search';
import { CommonSelectInput } from 'components/common/form/select';
import { FlexTableWrapper } from 'components/common/layout/flex-table-wrapper';
import { CommonTableHoC } from 'components/common/table';
import { ErrorTypeEditorDialog } from 'components/sections/admin-portal/error-types/editor';
import { AdminTabNav } from 'components/sections/admin-portal/tab-nav';
import { SectionHeaderActions } from 'components/sections/header/actions';
import {
  ErrorTypesContext,
  ErrorTypesProvider,
  IErrorTypesContext,
} from 'contexts/admin/error-types.context';
import { CookiesContext, ICookiesContext } from 'contexts/cookies.context';
import { GlobalContext } from 'contexts/global.context';
import { SubSectionName } from 'enums/route.enums';
import { ACTIONS_KEY, TABLES } from 'enums/tables';
import { TableIdentifier } from 'interfaces/cookies/i-app.cookie';
import { ITableColumn } from 'interfaces/tables/columns';
import { ITablePageable } from 'interfaces/tables/pagination';
import { ITableSortable } from 'interfaces/tables/sorting';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { ErrorLevel } from 'lib_ts/enums/errors.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import {
  DEFAULT_ERROR_TYPE,
  IErrorType,
} from 'lib_ts/interfaces/common/i-error-type';
import React, { useContext } from 'react';
import { AdminErrorTypesService } from 'services/admin/error-types.service';

const IDENTIFIER = TableIdentifier.AdminErrorTypeList;

const PAGE_SIZES = TABLES.PAGE_SIZES.MD;

// todo: convert this view to fnl so we don't need to do this thing
interface IProps {
  cookiesCx: ICookiesContext;
  errorsCx: IErrorTypesContext;
}

interface IState extends Partial<ITablePageable> {
  editorModel?: Partial<IErrorType>;
  dialog?: number;
}

export const ErrorTypesTableHoc = () => {
  const cookiesCx = useContext(CookiesContext);

  return (
    <ErrorTypesProvider>
      <ErrorTypesContext.Consumer>
        {(errorsCx) => (
          <ErrorTypesTable cookiesCx={cookiesCx} errorsCx={errorsCx} />
        )}
      </ErrorTypesContext.Consumer>
    </ErrorTypesProvider>
  );
};

class ErrorTypesTable extends React.Component<IProps, IState> {
  private fileInput?: CommonSimpleFileUploader;

  private readonly BASE_COLUMNS: ITableColumn[] = [
    {
      label: 'common.actions',
      key: ACTIONS_KEY,
      actions: [
        {
          label: 'common.edit',
          onClick: (m: IErrorType) => {
            this.setState({
              editorModel: m,
              dialog: Date.now(),
            });
          },
        },
        {
          label: 'common.preview',
          color: RADIX.COLOR.SUCCESS,
          onClick: (m: IErrorType) => {
            NotifyHelper.userError(m);
          },
        },
        {
          label: 'common.duplicate',
          color: RADIX.COLOR.WARNING,
          onClick: (m: IErrorType) => {
            const cloned: Partial<IErrorType> = {
              ...m,
              _id: undefined,
              errorID: `${m.errorID} (copy)`,
            };

            this.setState({
              editorModel: cloned,
              dialog: Date.now(),
            });
          },
        },
        {
          label: 'common.delete',
          color: RADIX.COLOR.DANGER,
          invisibleFn: (m: IErrorType) => !!m.deleted,
          onClick: (m: IErrorType) => {
            this.props.errorsCx.update({
              _id: m._id,
              deleted: true,
            });
          },
        },
        {
          label: 'Restore',
          color: RADIX.COLOR.WARNING,
          invisibleFn: (m: IErrorType) => !m.deleted,
          onClick: (m: IErrorType) => {
            this.props.errorsCx.update({
              _id: m._id,
              deleted: false,
            });
          },
        },
      ],
    },
    {
      label: 'ErrorID',
      key: 'errorID',
    },
    {
      label: 'Type',
      key: 'type',
    },
    {
      label: 'Category',
      key: 'category',
    },
    {
      label: 'Sender',
      key: 'sender',
    },
    {
      label: 'Level',
      key: 'level',
      align: 'center',
      formatFn: (et: IErrorType) => {
        const color = NotifyHelper.getColorFromLevel(et.level);
        return <Badge color={color}>{et.level.toUpperCase()}</Badge>;
      },
    },
    {
      label: 'Internal',
      key: 'internal',
      formatFn: (et: IErrorType) => {
        return et.internal ? 'Yes' : 'No';
      },
    },
    {
      label: 'Status',
      key: 'deleted',
      formatFn: (et: IErrorType) => {
        return et.deleted ? 'Deleted' : 'Active';
      },
    },
  ];

  constructor(props: IProps) {
    super(props);

    this.state = {};

    this.renderEditorDialog = this.renderEditorDialog.bind(this);
    this.renderToolbar = this.renderToolbar.bind(this);
  }

  private renderEditorDialog() {
    if (!this.state.dialog) {
      return;
    }

    return (
      <ErrorTypeEditorDialog
        key={this.state.dialog}
        errorsCx={this.props.errorsCx}
        input={this.state.editorModel}
        onClose={() => {
          // close the dialog regardless
          this.setState({ dialog: undefined });
        }}
      />
    );
  }

  render() {
    const { filtered, filters, loading } = this.props.errorsCx;

    if (!filtered) {
      return <Skeleton />;
    }

    const pagination: ITablePageable = {
      identifier: IDENTIFIER,
      total: filtered.length,
      enablePagination: true,
      pageSizes: PAGE_SIZES,
    };

    const sort: ITableSortable = {
      enableSort: true,
      defaultSort: {
        key: 'errorID',
        dir: -1,
      },
    };

    const isFiltering =
      filters.errorIDs.length > 0 ||
      filters.levels.length > 0 ||
      filters.internal !== undefined;

    return (
      <ErrorBoundary componentName="ErrorTypesTable">
        <FlexTableWrapper
          gap={RADIX.FLEX.GAP.SECTION}
          header={<AdminTabNav active={SubSectionName.ErrorTypes} />}
          table={
            <GlobalContext.Consumer>
              {(globalCx) => (
                <CommonTableHoC
                  id="AdminErrorTypes"
                  toolbarContent={this.renderToolbar()}
                  displayColumns={this.BASE_COLUMNS}
                  displayData={filtered}
                  loading={loading}
                  enableListener={globalCx.dialogs.length === 0}
                  noDataBody={isFiltering ? 'common.no-matches-msg' : undefined}
                  {...pagination}
                  {...sort}
                  vFlex
                />
              )}
            </GlobalContext.Consumer>
          }
        />

        {this.renderEditorDialog()}

        <CommonSimpleFileUploader
          ref={(elem) => (this.fileInput = elem as CommonSimpleFileUploader)}
          id="error-types-uploader"
          acceptedTypes={['application/json']}
          onChange={async (files) => {
            const success =
              await AdminErrorTypesService.getInstance().importJSON(files);

            if (success) {
              this.props.errorsCx.refresh();
            }
          }}
          hidden
        />
      </ErrorBoundary>
    );
  }

  private renderToolbar() {
    const { filtered, filters, options, setFilters } = this.props.errorsCx;

    return (
      <Flex gap={RADIX.FLEX.GAP.SM}>
        <Grid flexGrow="1" columns="4" gap={RADIX.FLEX.GAP.SM}>
          {/* filters */}
          <Box>
            <CommonSearchInput
              id="error-types-errorIDs"
              placeholder="ErrorID"
              options={options.errorIDs}
              values={filters.errorIDs}
              onChange={(values) => setFilters({ errorIDs: values })}
              multiple
            />
          </Box>

          <Box>
            <CommonSearchInput
              id="error-types-levels"
              placeholder="Level"
              options={options.levels}
              values={filters.levels}
              onChange={(values) =>
                setFilters({ levels: values as ErrorLevel[] })
              }
              multiple
              skipSort
            />
          </Box>

          <Box>
            <CommonSelectInput
              id="error-types-internal"
              name="internal"
              placeholder="Internal"
              options={[
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' },
              ]}
              value={filters.internal?.toString()}
              onOptionalBooleanChange={(v) => setFilters({ internal: v })}
              optional
            />
          </Box>

          <Box>
            <CommonSelectInput
              id="error-types-deleted"
              name="deleted"
              placeholder="Status"
              options={[
                { label: 'Active', value: 'false' },
                { label: 'Deleted', value: 'true' },
              ]}
              value={filters.deleted?.toString()}
              onOptionalBooleanChange={(v) => setFilters({ deleted: v })}
              optional
            />
          </Box>
        </Grid>

        {/* actions menu */}
        <SectionHeaderActions
          actions={[
            {
              label: 'common.create',
              color: RADIX.COLOR.SUCCESS,
              prefixIcon: <PlusIcon />,
              onClick: () => {
                this.setState({
                  // empty model => a new instance will be created
                  editorModel: undefined,
                  dialog: Date.now(),
                });
              },
            },
            {
              label: 'common.refresh',
              prefixIcon: <UpdateIcon />,
              onClick: this.props.errorsCx.refresh,
            },
            {
              group: 'JSON',
              label: 'common.export',
              prefixIcon: <DownloadIcon />,
              disabled: filtered.length === 0,
              onClick: () => {
                const types: Partial<IErrorType>[] = filtered.map((m) => {
                  const o: Partial<IErrorType> = {
                    ...DEFAULT_ERROR_TYPE,
                    ...m,
                  };

                  if (typeof o.resolution === 'string') {
                    o.resolution = (o.resolution as string).split(',');
                  }

                  if (typeof o.patch === 'string') {
                    o.patch = (o.patch as string).split(',');
                  }

                  delete o._id;
                  delete o._created;
                  delete o._createdby;
                  delete o._changed;
                  delete o._changedby;

                  return o;
                });

                MiscHelper.saveAs(
                  new Blob([JSON.stringify(types, null, 2)]),
                  `error-types.json`
                );
              },
            },
            {
              group: 'JSON',
              label: 'common.import',
              prefixIcon: <UploadIcon />,
              disabled: !this.fileInput,
              onClick: () => {
                this.fileInput?.handleClick();
              },
            },
          ]}
        />
      </Flex>
    );
  }
}
