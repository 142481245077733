import { ICameraStreamResponseMsg } from 'lib_ts/interfaces/i-machine-msg';
import { createContext, FC, ReactNode, useState } from 'react';

const CONTEXT_NAME = 'StreamingContext';

export interface IStreamingContext {
  paused: boolean;
  readonly setPaused: (value: boolean) => void;

  lastFrame: ICameraStreamResponseMsg | undefined;
  readonly setLastFrame: (frame: ICameraStreamResponseMsg | undefined) => void;
}

const DEFAULT: IStreamingContext = {
  paused: false,
  setPaused: () => console.error(`${CONTEXT_NAME}: not init`),

  lastFrame: undefined,
  setLastFrame: () => console.error(`${CONTEXT_NAME}: not init`),
};

export const StreamingContext = createContext(DEFAULT);

interface IProps {
  children: ReactNode;
}

export const StreamingProvider: FC<IProps> = (props) => {
  const [paused, setPaused] = useState(DEFAULT.paused);
  const [lastFrame, setLastFrame] = useState(DEFAULT.lastFrame);

  const state: IStreamingContext = {
    paused: paused,
    setPaused: setPaused,

    lastFrame: lastFrame,
    setLastFrame: (data) => {
      if (paused && lastFrame) {
        // don't change the frozen frame (if any)
        return;
      }

      // regular behaviour, will insert a first frame even if paused
      setLastFrame(data);
    },
  };

  return (
    <StreamingContext.Provider value={state}>
      {props.children}
    </StreamingContext.Provider>
  );
};
