export enum InstallStep {
  TestShot = 'TestShot',
  GeneralConfig = 'GeneralConfig',
  ProjectorConfig = 'ProjectorConfig',
  BallType = 'BallType',
  Repeatability = 'Repeatability',
  Completion = 'Completion',
  End = 'End',
}

export enum SubStatus {
  NotStarted,
  InProgress,
  Completed,
  Error,
}

export enum ResultsTabKey {
  ModelPerformance = 'Model Performance',
  MachinePerformance = 'Machine Performance',
}
