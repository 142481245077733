import { Box, Flex, SegmentedControl } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { CommonCallout } from 'components/common/callouts';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonInputHint } from 'components/common/form/hint';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonTextInput } from 'components/common/form/text';
import { CommonTextareaInput } from 'components/common/form/textarea';
import { CommonTabs } from 'components/common/tabs';
import { CommonToast } from 'components/common/toast';
import { IErrorTypesContext } from 'contexts/admin/error-types.context';
import { IFullDialog } from 'interfaces/i-dialogs';
import { INotification } from 'interfaces/i-notification';
import { ErrorLevel } from 'lib_ts/enums/errors.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { LANGUAGE_OPTIONS, LanguageCode } from 'lib_ts/enums/translation';
import { IErrorType } from 'lib_ts/interfaces/common/i-error-type';
import React from 'react';

const COMPONENT_NAME = 'ErrorTypeEditorDialog';

const DEFAULT_ERROR_TYPE: Partial<IErrorType> = {
  level: ErrorLevel.error,
  internal: true,
  slack: true,
  show_restart: false,
};

enum TabKey {
  Config = 'Config',
  UserMessage = 'UserMessage',
  OtherMessages = 'OtherMessages',
}

interface IProps {
  errorsCx: IErrorTypesContext;

  input: Partial<IErrorType> | undefined;
  onClose: (refresh: boolean) => void;
}

interface IState {
  model: Partial<IErrorType>;

  activeTab: TabKey;

  language: LanguageCode;
}

export class ErrorTypeEditorDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    /** make a copy to manipulate via forms */
    const safeModel = props.input ?? DEFAULT_ERROR_TYPE;

    // fixes the case where value provided in JSON import is the wrong type
    if (typeof safeModel.resolution === 'string') {
      safeModel.resolution = (safeModel.resolution as string).split(',');
    }

    // fixes the case where value provided in JSON import is the wrong type
    if (typeof safeModel.patch === 'string') {
      safeModel.patch = (safeModel.patch as string).split(',');
    }

    this.state = {
      model: safeModel,
      activeTab: TabKey.Config,
      language: LanguageCode.English,
    };

    this.isNew = this.isNew.bind(this);
    this.getTranslatedUserMsg = this.getTranslatedUserMsg.bind(this);
    this.updateMetadata = this.updateMetadata.bind(this);

    this.renderContent = this.renderContent.bind(this);
    this.renderPreview = this.renderPreview.bind(this);
    this.renderUserMessageInput = this.renderUserMessageInput.bind(this);
  }

  private isNew(): boolean {
    if (this.state.model) {
      return !this.state.model._id;
    } else {
      return true;
    }
  }

  private updateMetadata(model: Partial<IErrorType>) {
    this.setState({
      model: {
        ...this.state.model,
        ...model,
      },
    });
  }

  private renderPreview() {
    if (this.state.model.internal) {
      return (
        <CommonCallout text="Internal errors do not present the user with any notifications." />
      );
    }

    const config: INotification = {
      // don't translate this header
      header: '--none--',
      hideHeader: true,
      message_md: this.getTranslatedUserMsg() || '(empty user message)',
      color: NotifyHelper.getColorFromLevel(this.state.model.level),
      level: this.state.model.level,
    };

    return (
      <Flex direction="column" gap={RADIX.FLEX.GAP.SM}>
        <CommonToast config={config} withoutToast />

        <CommonInputHint hint_md="Read more about Markdown [here](https://www.markdownguide.org/cheat-sheet/)." />
      </Flex>
    );
  }

  render() {
    const mergeProps: IFullDialog = {
      identifier: COMPONENT_NAME,
      title: this.isNew() ? 'Create Error Type' : 'Update Error Type',
      width: RADIX.DIALOG.WIDTH.LG,
      content: this.renderContent(),
      buttons: [
        {
          label: 'Preview',
          color: RADIX.COLOR.SUCCESS,
          onClick: () => {
            NotifyHelper.userError({
              level: this.state.model.level ?? ErrorLevel.debug,
              user_message:
                this.state.model.user_message?.trim() || 'No message',
              delay_ms: this.state.model.delay_ms,
              show_restart: this.state.model.show_restart,
              errorID: this.state.model.errorID ?? 'Unknown',
              type: this.state.model.type ?? 'Unknown',
              category: this.state.model.category ?? 'Unknown',
              internal: !!this.state.model.internal,
              slack: !!this.state.model.slack,
              _id: 'error-type-preview',
              _created: new Date().toISOString(),
              _changed: new Date().toISOString(),
            });
          },
        },
        {
          label: 'Create',
          invisible: !this.isNew(),
          color: RADIX.COLOR.INFO,
          onClick: async () => {
            const success = await this.props.errorsCx.create(this.state.model);

            if (!success) {
              return;
            }

            this.props.onClose(true);
          },
        },
        {
          label: 'Update',
          invisible: this.isNew(),
          color: RADIX.COLOR.INFO,
          onClick: async () => {
            const success = await this.props.errorsCx.update(this.state.model);

            if (!success) {
              return;
            }

            this.props.onClose(true);
          },
        },
      ],
      onClose: () => this.props.onClose(false),
    };

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDialog {...mergeProps} />
      </ErrorBoundary>
    );
  }

  private renderContent() {
    return (
      <CommonTabs
        value={this.state.activeTab}
        onValueChange={(value) => {
          this.setState({ activeTab: value as TabKey });
        }}
        tabs={[
          {
            value: TabKey.Config,
            label: 'Config',
            content: (
              <CommonFormGrid columns={3}>
                {/* row 1 */}
                <CommonTextInput
                  id="error-type-errorID"
                  label="ErrorID"
                  placeholder="Type a unique value"
                  value={this.state.model.errorID}
                  disabled={!!this.state.model._id}
                  onChange={(v) => this.updateMetadata({ errorID: v })}
                />
                <CommonTextInput
                  id="error-type-category"
                  label="Category"
                  value={this.state.model.category}
                  onChange={(v) => this.updateMetadata({ category: v })}
                />
                <CommonTextInput
                  id="error-type-type"
                  label="Type"
                  value={this.state.model.type}
                  onChange={(v) => this.updateMetadata({ type: v })}
                />
                {/* row 2 */}
                <CommonSelectInput
                  id="error-type-level"
                  name="level"
                  label="Level"
                  options={Object.values(ErrorLevel).map((o) => ({
                    label: o.toUpperCase(),
                    value: o,
                  }))}
                  value={this.state.model.level}
                  onChange={(v) =>
                    this.updateMetadata({ level: v as ErrorLevel })
                  }
                />
                <CommonTextInput
                  id="error-type-auto-dismiss"
                  label="Auto-Dismiss (ms)"
                  value={this.state.model.delay_ms?.toString()}
                  onNumericChange={(v) => {
                    this.updateMetadata({ delay_ms: v });
                  }}
                  hint_md="After the specified delay, the notification will auto-dismiss. A value of 0 will disable auto-dismiss."
                  optional
                />
                <CommonSelectInput
                  id="error-type-restart"
                  name="show_restart"
                  label="Show Restart"
                  options={[
                    { label: 'Yes', value: 'true' },
                    { label: 'No', value: 'false' },
                  ]}
                  value={this.state.model.show_restart?.toString()}
                  onBooleanChange={(v) => {
                    this.updateMetadata({
                      show_restart: v,
                    });
                  }}
                  hint_md="Only triggers a restart for active users."
                />
                {/* row 3 */}
                <Box gridColumn="span 2">
                  <CommonTextInput
                    id="error-type-resolution"
                    label="Resolution Method(s)"
                    placeholder="Comma-delimited"
                    value={this.state.model.resolution?.join(',')}
                    onChange={(v) => {
                      this.updateMetadata({
                        resolution: v?.split(','),
                      });
                    }}
                    hint_md="Keywords for action(s) that should permanently address the issue."
                    optional
                  />
                </Box>
                <CommonTextInput
                  id="error-type-intercom"
                  label="Intercom Article ID"
                  placeholder="e.g. 123456"
                  value={this.state.model.intercom_article_id?.toString()}
                  onNumericChange={(v) => {
                    this.updateMetadata({
                      intercom_article_id: v,
                    });
                  }}
                  hint_md="Provide an article ID to show a button that opens the article within the Intercom widget."
                  optional
                />
                {/* row 4 */}
                <Box gridColumn="span 2">
                  <CommonTextInput
                    id="error-type-patch-methods"
                    label="Patch Method(s)"
                    placeholder="Comma-delimited"
                    value={this.state.model.patch?.join(',')}
                    onChange={(v) => {
                      this.updateMetadata({
                        patch: v?.split(','),
                      });
                    }}
                    hint_md="Keywords for immediate action(s) that may temporarily address the issue."
                    optional
                  />
                </Box>
                <CommonSelectInput
                  id="error-type-slack"
                  name="slack"
                  label="Slack"
                  options={[
                    { label: 'Yes', value: 'true' },
                    { label: 'No', value: 'false' },
                  ]}
                  value={this.state.model.slack?.toString()}
                  onBooleanChange={(v) => {
                    this.updateMetadata({
                      slack: v,
                    });
                  }}
                  hint_md={[
                    `Slack errors will be posted to the relevant Slack channel.`,
                    `e.g. \`#machine-internal-errors\` or \`#machine-warnings\``,
                  ].join(' ')}
                />
              </CommonFormGrid>
            ),
          },
          {
            value: TabKey.UserMessage,
            label: 'User Message',
            content: (
              <CommonFormGrid columns={2}>
                <Flex direction="column" gap={RADIX.FLEX.GAP.SM}>
                  <SegmentedControl.Root
                    value={this.state.language}
                    onValueChange={(v) =>
                      this.setState({ language: v as LanguageCode })
                    }
                  >
                    {LANGUAGE_OPTIONS.map((o, i) => {
                      return (
                        <SegmentedControl.Item
                          key={`language-${i}`}
                          value={o.value}
                        >
                          {o.label} ({o.value.toUpperCase()})
                        </SegmentedControl.Item>
                      );
                    })}
                  </SegmentedControl.Root>

                  {this.renderUserMessageInput()}
                </Flex>
                <Flex direction="column" gap={RADIX.FLEX.GAP.LG}>
                  <CommonSelectInput
                    id="error-type-internal"
                    name="internal"
                    label="Internal"
                    options={[
                      { label: 'Yes', value: 'true' },
                      { label: 'No', value: 'false' },
                    ]}
                    value={this.state.model.internal?.toString()}
                    onBooleanChange={(v) => {
                      this.updateMetadata({
                        internal: v,
                      });
                    }}
                  />

                  {this.renderPreview()}
                </Flex>
              </CommonFormGrid>
            ),
          },
          {
            value: TabKey.OtherMessages,
            label: 'Other Messages',
            content: (
              <CommonFormGrid columns={1}>
                <Box>
                  <CommonTextareaInput
                    id="error-type-cs-msg"
                    label="Customer Support Message"
                    value={this.state.model.cs_message}
                    onChange={(v) =>
                      this.updateMetadata({ cs_message: v ?? '' })
                    }
                    hint_md="Message to be shown to customer support (e.g. resolution instructions)."
                    rows={8}
                    optional
                  />
                </Box>
                <Box>
                  <CommonTextareaInput
                    id="error-type-dev-msg"
                    label="Dev Message"
                    value={this.state.model.dev_message}
                    onChange={(value) => {
                      this.updateMetadata({
                        dev_message: value ?? '',
                      });
                    }}
                    hint_md="Message to be shown to developers (e.g. for troubleshooting)."
                    rows={8}
                    optional
                  />
                </Box>
              </CommonFormGrid>
            ),
          },
        ]}
      />
    );
  }

  private renderUserMessageInput() {
    const value = this.getTranslatedUserMsg();

    return (
      <CommonTextareaInput
        id="error-type-user-msg"
        // label={`User Message (${this.state.language})`}
        rows={20}
        disabled={this.state.model.internal}
        value={value}
        onChange={(v) => {
          switch (this.state.language) {
            case LanguageCode.English: {
              this.updateMetadata({
                user_message: v ?? '',
              });
              break;
            }

            case LanguageCode.Japanese: {
              this.updateMetadata({
                user_message_japanese: v ?? '',
              });
              break;
            }

            case LanguageCode.Korean: {
              this.updateMetadata({
                user_message_korean: v ?? '',
              });
              break;
            }

            default: {
              break;
            }
          }
        }}
        hint_md="Message to be shown to users in notifications."
      />
    );
  }

  private getTranslatedUserMsg() {
    switch (this.state.language) {
      case LanguageCode.English: {
        return this.state.model.user_message ?? '';
      }

      case LanguageCode.Japanese: {
        return this.state.model.user_message_japanese ?? '';
      }

      case LanguageCode.Korean: {
        return this.state.model.user_message_korean ?? '';
      }

      default: {
        return '';
      }
    }
  }
}
