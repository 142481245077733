import _ from 'lodash';
import { ISessionHit, ISessionHitStats } from '../interfaces/i-session-hit';

// for reuse between server and front-end to ensure numbers agree
export class SessionHelper {
  static getHitsStats(hits: ISessionHit[]) {
    const notExcluded = hits.filter((m) => !m.avg_excluded);

    const safeExits = notExcluded
      .map((m) => m.hit.exitMPH)
      .filter((m) => m !== null && m !== undefined) as number[];

    const safeDistances = notExcluded
      .map((m) => m.hit.distanceFT)
      .filter((m) => m !== null && m !== undefined) as number[];

    const safeVLaunches = notExcluded
      .map((m) => m.hit.vLaunchDEG)
      .filter((m) => m !== null && m !== undefined) as number[];

    const output: ISessionHitStats = {
      avgExitMPH: _.mean(safeExits),
      maxExitMPH: _.max(safeExits) ?? 0,
      avgDistanceFT: _.mean(safeDistances),
      maxDistanceFT: _.max(safeDistances) ?? 0,
      avgVLaunchDeg: _.mean(safeVLaunches),
    };

    return output;
  }
}
