import {
  JwtType,
  LoginMethod,
  UserRole,
  UserStatus,
} from 'lib_ts/enums/auth.enums';
import { League } from 'lib_ts/enums/team.enums';
import { ILoginSuccess } from 'lib_ts/interfaces/common/i-login';
import { SessionMode } from 'lib_ts/interfaces/i-auth-token';
import { DEFAULT_COMBINED_PERMISSIONS } from 'lib_ts/interfaces/i-permissions';

export interface ISessionCookie extends ILoginSuccess {
  auth: boolean;

  /** calculated with expiresIn or restored from cookie, handled in merge function */
  expires?: Date;
}

export const DEFAULT_SESSION_UNAUTH: ISessionCookie = {
  method: LoginMethod.None,
  version: '',
  auth: false,
  email: '',
  expiresIn: 0,
  machineID: '',
  role: UserRole.basic,
  session: '',
  status: UserStatus.unverified,
  type: JwtType.auth,
  teamID: 'none',
  team: 'none',
  league: League.None,
  token: '',
  userID: '',
  mode: SessionMode.Basic,
  ...DEFAULT_COMBINED_PERMISSIONS,
};
