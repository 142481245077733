import { MagnifyingGlassIcon, ResetIcon } from '@radix-ui/react-icons';
import { Box, Heading } from '@radix-ui/themes';
import { getPitchYearOptions } from 'classes/helpers/pitch-list.helper';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonSearchInput } from 'components/common/form/search';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonTextInput } from 'components/common/form/text';
import { SEARCH_ID } from 'components/sections/pitch-list/store/pitch-list-store';
import { usePitchListStore } from 'components/sections/pitch-list/store/use-pitch-list-store';
import { PitchListsContext } from 'contexts/pitch-lists/lists.context';
import { t } from 'i18next';
import { PitchListOwner } from 'lib_ts/enums/pitch-list.enums';
import {
  PITCH_TYPE_OPTIONS,
  PitchType,
  PitcherHand,
} from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import { ISearchPitches } from 'lib_ts/interfaces/pitches/i-search-pitches';
import React, { useContext, useEffect, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

const COMPONENT_NAME = 'SearchPitchesDialog';

const ENABLE_SORTING = false;

const SUBMIT_KEY = 'Enter';

const LIST_PARENT_OPTIONS: IOption[] = [
  {
    label: 'common.personal',
    value: PitchListOwner.User,
  },
  {
    label: 'common.machine',
    value: PitchListOwner.Machine,
  },
  {
    label: 'common.team',
    value: PitchListOwner.Team,
  },
];

// assuming grid with 2 columns
const SPAN = 'span 2';

interface IProps {
  onClose: () => void;
}

export const SearchPitchesDialog: React.FC<IProps> = ({ onClose }) => {
  const listsCx = useContext(PitchListsContext);
  const listStore = usePitchListStore(
    useShallow(({ searchCriteria, loading, setSearchCriteria }) => ({
      searchCriteria,
      loading,
      setSearchCriteria,
    }))
  );

  const [searchState, setSearchState] = useState<ISearchPitches>({
    ...listStore.searchCriteria,
  });

  const submit = async () => {
    // update search criteria
    await listStore.setSearchCriteria(searchState);

    // dialog close should only happen after search criteria are updated
    onClose();
  };

  const handleInputChange = (field: keyof ISearchPitches, value: any) => {
    setSearchState((prev) => ({ ...prev, [field]: value }));
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.code !== SUBMIT_KEY) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    if (event.repeat) {
      return;
    }

    switch (event.code) {
      case SUBMIT_KEY: {
        submit();
        return;
      }

      default: {
        return;
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  const renderPitchFilters = () => (
    <>
      <Box gridColumn={SPAN}>
        <Heading size={RADIX.HEADING.SIZE.SM}>
          {t('common.pitch-details')}
        </Heading>
      </Box>
      <Box>
        <CommonTextInput
          id="search-pitch-name"
          label="common.name"
          name="name"
          value={searchState.name}
          disabled={listStore.loading}
          onChange={(v) => handleInputChange('name', v)}
        />
      </Box>
      <Box>
        <CommonSelectInput
          id="search-pitch-year"
          name="year"
          label="common.year"
          options={getPitchYearOptions()}
          value={searchState.year}
          onChange={(v) => handleInputChange('year', v)}
          disabled={listStore.loading}
          optional
        />
      </Box>
      <Box>
        <CommonSearchInput
          id="search-pitch-type"
          name="type"
          label="common.type"
          options={PITCH_TYPE_OPTIONS}
          values={searchState.type ? [searchState.type] : []}
          onChange={(values) =>
            handleInputChange('type', values[0] as PitchType)
          }
          disabled={listStore.loading}
          optional
        />
      </Box>
      <Box>
        <CommonSelectInput
          id="search-pitch-hand"
          name="hand"
          label="common.side"
          options={Object.values(PitcherHand).map((m) => ({
            label: m,
            value: m,
          }))}
          value={searchState.hand}
          onChange={(v) => handleInputChange('hand', v as PitcherHand)}
          disabled={listStore.loading}
          optional
        />
      </Box>
      <Box>
        <CommonTextInput
          id="search-pitch-min-speed"
          label="common.min-speed-mph"
          name="minVnet"
          type="number"
          value={searchState.minVnet?.toString()}
          disabled={listStore.loading}
          onNumericChange={(v) => handleInputChange('minVnet', v)}
        />
      </Box>
      <Box>
        <CommonTextInput
          id="search-pitch-max-speed"
          label="common.max-speed-mph"
          name="maxVnet"
          type="number"
          value={searchState.maxVnet?.toString()}
          disabled={listStore.loading}
          onNumericChange={(v) => handleInputChange('maxVnet', v)}
        />
      </Box>
      <Box>
        <CommonTextInput
          id="search-pitch-min-spin"
          label="common.min-spin-rpm"
          name="minWnet"
          type="number"
          value={searchState.minWnet?.toString()}
          disabled={listStore.loading}
          onNumericChange={(v) => handleInputChange('minWnet', v)}
        />
      </Box>
      <Box>
        <CommonTextInput
          id="search-pitch-max-spin"
          label="common.max-spin-rpm"
          name="maxWnet"
          type="number"
          value={searchState.maxWnet?.toString()}
          disabled={listStore.loading}
          onNumericChange={(v) => handleInputChange('maxWnet', v)}
        />
      </Box>
    </>
  );

  const renderSortControls = () => {
    if (!ENABLE_SORTING) {
      return;
    }

    return (
      <>
        <Box gridColumn={SPAN}>
          <Heading size={RADIX.HEADING.SIZE.SM}>Filter</Heading>
        </Box>
        <Box>
          <CommonSelectInput
            id="search-pitch-sort-by"
            name="sortKey"
            label="common.sort-by"
            options={[
              { label: 'Created', value: '_created' },
              { label: 'Changed', value: '_changed' },
            ]}
            value={searchState.sortKey}
            onChange={(v) => handleInputChange('sortKey', v)}
            disabled={listStore.loading}
            optional
          />
        </Box>
        <Box>
          <CommonSelectInput
            id="search-pitch-sort-direction"
            name="sortDir"
            label="common.sort-direction"
            options={[
              { label: 'Ascending', value: 'asc' },
              { label: 'Descending', value: 'desc' },
            ]}
            value={searchState.sortDir}
            onChange={(v) => handleInputChange('sortDir', v as 'asc' | 'desc')}
            disabled={listStore.loading}
            optional
          />
        </Box>
      </>
    );
  };

  const renderListFilters = () => {
    if (listsCx.active?._id !== SEARCH_ID) {
      return;
    }

    return (
      <>
        <Box gridColumn={SPAN}>
          <Heading size={RADIX.HEADING.SIZE.SM}>List Details</Heading>
        </Box>
        <Box>
          <CommonTextInput
            id="search-pitch-list-name"
            label="common.name"
            name="listName"
            value={searchState.listName}
            disabled={listStore.loading}
            onChange={(v) => handleInputChange('listName', v)}
          />
        </Box>
        <Box>
          <CommonTextInput
            id="search-pitch-list-folder"
            label="pl.folder"
            name="name"
            value={searchState.listFolder}
            disabled={listStore.loading}
            onChange={(v) => handleInputChange('listFolder', v)}
          />
        </Box>
        <Box gridColumn={SPAN}>
          <CommonSelectInput
            id="search-pitch-list-parent"
            name="listParent"
            label="common.visibility"
            options={LIST_PARENT_OPTIONS}
            value={searchState.listParent}
            onChange={(v) => handleInputChange('listParent', v)}
            disabled={listStore.loading}
            optional
          />
        </Box>
      </>
    );
  };

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <CommonDialog
        identifier="PitchListSearchModal"
        width={RADIX.DIALOG.WIDTH.MD}
        title="common.search"
        buttons={[
          {
            label: 'common.search',
            icon: <MagnifyingGlassIcon />,
            color: RADIX.COLOR.INFO,
            onClick: submit,
          },
          {
            label: 'common.reset',
            icon: <ResetIcon />,
            color: RADIX.COLOR.NEUTRAL,
            onClick: () => {
              listStore.setSearchCriteria(undefined);
              onClose();
            },
          },
        ]}
        onClose={onClose}
        content={
          <CommonFormGrid columns={2}>
            {renderPitchFilters()}
            {renderListFilters()}
            {renderSortControls()}
          </CommonFormGrid>
        }
      />
    </ErrorBoundary>
  );
};
