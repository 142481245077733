import { createContext, FC, ReactNode, useState } from 'react';

const CONTEXT_NAME = 'DataCollector';

export interface IDataCollector {
  // true after firing, false after (re)fetching shots
  waiting: boolean;
  readonly setWaiting: (value: boolean) => void;
}

const DEFAULT: IDataCollector = {
  waiting: false,
  setWaiting: () => console.error(`${CONTEXT_NAME}: not init`),
};

export const DataCollectorContext = createContext(DEFAULT);

interface IProps {
  children: ReactNode;
}

export const DataCollectorProvider: FC<IProps> = (props) => {
  const [waiting, setWaiting] = useState(DEFAULT.waiting);

  const state: IDataCollector = {
    waiting: waiting,
    setWaiting: setWaiting,
  };

  return (
    <DataCollectorContext.Provider value={state}>
      {props.children}
    </DataCollectorContext.Provider>
  );
};
