import { MixerHorizontalIcon, TrackNextIcon } from '@radix-ui/react-icons';
import {
  Badge,
  Box,
  Card,
  Flex,
  IconButton,
  Progress,
  Text,
} from '@radix-ui/themes';
import { TrainingHelper } from 'classes/helpers/training-helper';
import { WebSocketHelper } from 'classes/helpers/web-socket.helper';
import { CommonCallout } from 'components/common/callouts';
import { CustomIcon } from 'components/common/custom-icon';
import {
  LowerMachineIcon,
  R2FIcon,
  SuperAdminIcon,
} from 'components/common/custom-icon/shorthands';
import {
  DialogButton,
  DialogIconButton,
} from 'components/common/dialogs/button';
import { CommonConfirmationDialog } from 'components/common/dialogs/confirmation';
import { ErrorBoundary } from 'components/common/error-boundary';
import { HINT_DELIMITER } from 'components/common/form/text-hint';
import { CommonMenu } from 'components/common/menu';
import { MachineAutoFireButton } from 'components/machine/buttons/auto-fire';
import { MachineFireButton } from 'components/machine/buttons/fire';
import { InstallationContext } from 'components/machine/dialogs/installation/context';
import { DataCollectorContext } from 'components/machine/dialogs/installation/steps/data-collector/context';
import { AimingContext } from 'contexts/aiming.context';
import { AuthContext } from 'contexts/auth.context';
import { CookiesContext } from 'contexts/cookies.context';
import { MachineContext, MachineDialogMode } from 'contexts/machine.context';
import { TrainingContext } from 'contexts/training.context';
import { CustomIconPath } from 'enums/custom.enums';
import { DataCollectionStep } from 'enums/data-collector.enums';
import { FireInstruction } from 'enums/machine.enums';
import { t } from 'i18next';
import { clamp } from 'lib_ts/classes/math.utilities';
import { getMSFromMSDict } from 'lib_ts/classes/ms.helper';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { WsMsgType } from 'lib_ts/enums/machine-msg.enum';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { SpecialMsPosition } from 'lib_ts/interfaces/machine-msg/i-special-mstarget';
import { IPitch } from 'lib_ts/interfaces/pitches';
import { useContext, useEffect, useMemo, useState } from 'react';

const BTN_CLASS = 'min-width-60px';

interface IProps {
  pitch: IPitch;

  // 1-indexed
  currentPitch: number;
  // length of pitches
  totalPitches: number;

  // e.g. to disable fire and ignore auto-fire when all shots for all pitches have been collected
  isComplete: boolean;
  step: DataCollectionStep;

  // per pitch
  currentShot: number;
  // per pitch
  totalShots: number;

  // 0-1
  overallProgress: number;
  overallShotsRemaining: number;

  // loading should happen automatically, but if the machine runs into an issue and clears the last MS hash,
  // the load pitch button will show up to trigger this fn
  onLoadPitch: () => void;
  onSkipPitch: () => void;
}

export const DataCollectorStatusBar = (props: IProps) => {
  const aimingCx = useContext(AimingContext);
  const cookiesCx = useContext(CookiesContext);
  const machineCx = useContext(MachineContext);
  const trainingCx = useContext(TrainingContext);

  const { current } = useContext(AuthContext);
  const {
    autoFire,
    firing,
    machine,
    lastBallCount,
    lastMS,
    lastMSHash,
    lastR2F,
    setAutoFire,
    setDialog,
    specialMstarget,
  } = useContext(MachineContext);

  const { waiting, setWaiting } = useContext(DataCollectorContext);
  const { lowConfidence, setLowConfidence } = useContext(InstallationContext);

  const [dialogWarnLowConfidence, setDialogWarnLowConfidence] =
    useState<number>();

  const [ignoreAutoFire, setIgnoreAutoFire] = useState(true);

  // stop ignoring auto-fire whenever a fire response is received
  useEffect(() => {
    const callback = () => setIgnoreAutoFire(false);
    WebSocketHelper.on(WsMsgType.M2U_FireResponse, callback);
    return () => {
      WebSocketHelper.remove(WsMsgType.M2U_FireResponse, callback);
    };
  }, []);

  const safeProgress = clamp(props.overallProgress, 0, 1);

  const isAdmin =
    current.role === UserRole.admin || current.mode === 'impostor';

  const etaMinutes = useMemo(
    () => TrainingHelper.getETAForShots(props.overallShotsRemaining, 'min'),
    [props.overallShotsRemaining]
  );

  const pitchHash = getMSFromMSDict(
    props.pitch,
    machine
  ).ms?.matching_hash?.slice(-6);

  const lastHash = lastMS?.matching_hash?.slice(-6);

  const r2fMessage = useMemo(() => {
    if (firing) {
      return t('common.firing');
    }

    if (lastBallCount !== 1) {
      return t('common.no-balls-msg');
    }

    if (waiting) {
      return t('common.waiting-for-data');
    }

    if (!lastR2F) {
      return t('common.waiting-for-data');
    }

    if (lastR2F.status) {
      return t('common.ready-to-fire');
    }

    return t('common.loading');
  }, [firing, lastBallCount, lastR2F, waiting]);

  return (
    <ErrorBoundary componentName="DataCollectorStatusBar">
      <Card>
        <Flex direction="column" gap={RADIX.FLEX.GAP.SM}>
          <Flex
            gap={RADIX.FLEX.GAP.SM}
            align="center"
            style={{ height: '100%' }}
          >
            <Flex flexGrow="1" direction="column" gap={RADIX.FLEX.GAP.SM}>
              {/* row 1 */}
              <Flex gap={RADIX.FLEX.GAP.SM} justify="between">
                {/* left */}
                <Box>
                  <Text>{t('tr.est-x-min-remaining', { x: etaMinutes })}</Text>
                </Box>

                {/* right */}
                <Box
                  title={
                    current.role === UserRole.admin
                      ? `Step: ${props.step}\nPitch: ${props.pitch.name}\nPitch Hash: ${pitchHash}\nLast Hash: ${lastHash}`
                      : undefined
                  }
                >
                  <Text color={RADIX.COLOR.SECONDARY}>
                    {t('common.pitch')} {props.currentPitch}/
                    {props.totalPitches}
                    {HINT_DELIMITER}
                    {t('common.shot')} {props.currentShot}/{props.totalShots}
                  </Text>
                </Box>
              </Flex>

              {/* row 2 */}
              <Progress value={safeProgress * 100} />
            </Flex>

            <Flex gap={RADIX.FLEX.GAP.SM}>
              <CommonMenu
                title="common.actions"
                trigger={
                  <IconButton
                    size={RADIX.BUTTON.SIZE.TABLE}
                    variant={RADIX.BUTTON.VARIANT.TABLE}
                  >
                    <MixerHorizontalIcon />
                  </IconButton>
                }
                actions={[
                  {
                    group: '_1',
                    label: 'Skip Current Pitch',
                    prefixIcon: <TrackNextIcon />,
                    disabled:
                      !lastMSHash ||
                      autoFire ||
                      props.currentShot >= props.totalShots,
                    onClick: props.onSkipPitch,
                  },
                  {
                    group: '_1',
                    label: 'Allow Low Confidence Shots',
                    prefixIcon: (
                      <CustomIcon
                        icon={
                          lowConfidence
                            ? CustomIconPath.SwitchOn
                            : CustomIconPath.SwitchOff
                        }
                      />
                    ),

                    onClick: () => {
                      if (lowConfidence) {
                        // no warning needed for turning it back off
                        setLowConfidence(false);
                        return;
                      }

                      // show alert dialog before enabling
                      setDialogWarnLowConfidence(Date.now());
                    },
                  },
                  {
                    group: '_2',
                    label: 'common.ready-status',
                    prefixIcon: <R2FIcon />,
                    suffixIcon: <SuperAdminIcon />,
                    invisible: !isAdmin,
                    onClick: () => setDialog(MachineDialogMode.R2F),
                  },
                ]}
                skipSort
              />

              <DialogIconButton
                color={RADIX.COLOR.WARNING}
                icon={<LowerMachineIcon />}
                tooltip="common.lower-machine"
                onClick={() => {
                  setAutoFire(false);
                  specialMstarget(SpecialMsPosition.lowered);
                }}
              />

              <MachineAutoFireButton
                as="dialog-button"
                className={BTN_CLASS}
                beforeToggleFn={(newValue) => setIgnoreAutoFire(newValue)}
              />

              {/* load pitch */}
              {!lastMSHash && (
                <DialogButton
                  color={RADIX.COLOR.SEND_PITCH}
                  className={BTN_CLASS}
                  onClick={props.onLoadPitch}
                  label="common.load-pitch"
                />
              )}

              {/* fire */}
              {lastMSHash && (
                <MachineFireButton
                  as="dialog-button"
                  size={RADIX.BUTTON.SIZE.SM}
                  className={BTN_CLASS}
                  ignoreAutoFire={ignoreAutoFire}
                  aimingCx={aimingCx}
                  cookiesCx={cookiesCx}
                  machineCx={machineCx}
                  trainingCx={trainingCx}
                  firing={!props.isComplete}
                  tags="DC"
                  beforeFire={() => {
                    // prevent auto-fire again until training data is received > shots have been reloaded
                    setWaiting(true);
                  }}
                  onReady={() => {
                    if (waiting) {
                      // don't proceed until training loop completes
                      return FireInstruction.DoNothing;
                    }

                    if (props.isComplete) {
                      // don't proceed until pitch is changed
                      return FireInstruction.DoNothing;
                    }

                    if (autoFire && !ignoreAutoFire) {
                      return FireInstruction.AutoFire;
                    }

                    // fallback
                    return FireInstruction.GoToReady;
                  }}
                />
              )}
            </Flex>
          </Flex>

          <Flex gap={RADIX.FLEX.GAP.SM} justify="between">
            <Box>
              <Text color={RADIX.COLOR.SECONDARY}>{r2fMessage}</Text>
            </Box>

            {lowConfidence && (
              <Badge color={RADIX.COLOR.WARNING}>
                Allowing Low Confidence Shots
              </Badge>
            )}
          </Flex>
        </Flex>
      </Card>

      {dialogWarnLowConfidence && (
        <CommonConfirmationDialog
          key={dialogWarnLowConfidence}
          identifier="ConfirmLowConfidence"
          title="Allow Low Confidence Shots"
          content={
            <Flex direction="column" gap="4">
              <Box>
                Enabling this setting can significantly impact the accuracy and
                reliability of the data you're collecting.
              </Box>

              <Box>
                If you're unsure whether you should use this feature, please
                contact your Trajekt representative before proceeding.
              </Box>

              <CommonCallout text="Turning on Allow Low Confidence Shots is only recommended if a Trajekt representative has specifically instructed you to do so. " />
            </Flex>
          }
          action={{
            label: 'Allow',
            onClick: () => {
              setLowConfidence(true);
            },
          }}
        />
      )}
    </ErrorBoundary>
  );
};
