import { NotifyHelper } from 'classes/helpers/notify.helper';
import { PitchDesignHelper } from 'classes/helpers/pitch-design.helper';
import { AuthContext } from 'contexts/auth.context';
import { CookiesContext } from 'contexts/cookies.context';
import { GlobalContext } from 'contexts/global.context';
import { MachineContext } from 'contexts/machine.context';
import { DirtyForm, SectionsContext } from 'contexts/sections.context';
import { AimingHelper } from 'lib_ts/classes/aiming.helper';
import { BallHelper } from 'lib_ts/classes/ball.helper';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { getMergedMSDict, getMSFromMSDict } from 'lib_ts/classes/ms.helper';
import { BuildPriority } from 'lib_ts/enums/pitches.enums';
import { IBallChar } from 'lib_ts/interfaces/i-ball-char';
import { DEFAULT_PLATE } from 'lib_ts/interfaces/pitches';
import {
  DEFAULT_PITCH,
  IBuildPitchChars,
  IPitch,
} from 'lib_ts/interfaces/pitches/i-pitch';
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { StateTransformService } from 'services/state-transform.service';
import { v4 } from 'uuid';

const CONTEXT_NAME = 'PitchDesignContext';

export interface IPitchDesignContext {
  priority: BuildPriority;
  readonly setPriority: (value: BuildPriority) => void;

  referenceKey: number;
  reference: IPitch;
  readonly setReference: (pitch: IPitch) => void;

  // for forms and editors
  ball: Partial<IBallChar>;
  readonly mergeBall: (config: {
    trigger: string;
    ball: Partial<IBallChar>;
  }) => void;

  videoID?: string;
  readonly setVideoID: (id: string | undefined) => void;

  workingKey: number;
  workingChars: Partial<IBuildPitchChars>;
  readonly mergeWorkingChars: (value: Partial<IBuildPitchChars>) => void;

  readonly getPitchPayload: () => Partial<IPitch> | undefined;
}

const DEFAULT: IPitchDesignContext = {
  priority: BuildPriority.Default,
  setPriority: () => console.error(`${CONTEXT_NAME}: not init`),

  reference: DEFAULT_PITCH,
  setReference: () => console.error(`${CONTEXT_NAME}: not init`),

  referenceKey: Date.now(),
  ball: {},
  mergeBall: () => console.error(`${CONTEXT_NAME}: not init`),

  setVideoID: () => console.error(`${CONTEXT_NAME}: not init`),

  workingKey: Date.now(),
  workingChars: {},
  mergeWorkingChars: () => console.error(`${CONTEXT_NAME}: not init`),

  getPitchPayload: () => undefined,
};

export const PitchDesignContext = createContext(DEFAULT);

interface IProps {
  children: ReactNode;
}

export const PitchDesignProvider: FC<IProps> = (props) => {
  const validationTimeout = useRef<any>();

  const { dialogs } = useContext(GlobalContext);
  const { app } = useContext(CookiesContext);
  const { current } = useContext(AuthContext);
  const { markDirtyForm } = useContext(SectionsContext);
  const { activeModel, machine, getDefaultPitch } = useContext(MachineContext);

  // note: reference py may differ from machine plate distance if the pitch was originally created for a different machine
  const [_reference, _setReference] = useState<IPitch>(getDefaultPitch());
  const _referenceKey = useMemo(() => Date.now(), [_reference]);
  const _referenceHash = useMemo(
    () => MiscHelper.hashify(_reference),
    [_reference]
  );

  const [_priority, _setPriority] = useState(
    _reference.priority ?? app.build_priority
  );

  // rebuild true ensures the ball gets built the first time
  const [_ball, _setBall] = useState<Partial<IBallChar>>({
    ...BallHelper.getCharsFromPitch(_reference),
  });

  useEffect(() => {
    if (!current.auth) {
      return;
    }

    // console.debug(
    //   `${CONTEXT_NAME} resetting ball because reference hash changed`
    // );

    _setBall(BallHelper.getCharsFromPitch(_reference));
  }, [current.auth, _referenceHash]);

  const [_workingChars, _setWorkingChars] = useState(DEFAULT.workingChars);
  const _workingKey = useMemo(() => Date.now(), [_workingChars]);

  const [_videoID, _setVideoID] = useState(_reference.video_id);

  useEffect(() => {
    if (!activeModel) {
      return;
    }

    if (dialogs.length > 0) {
      return;
    }

    // console.debug(
    //   `${CONTEXT_NAME} checking "${_priority}" support for model "${activeModel.name}"`
    // );

    switch (_priority) {
      case BuildPriority.Spins:
      case BuildPriority.Default: {
        if (!activeModel.supports_spins) {
          NotifyHelper.warning({
            message_md: `The pitch definition prioritizes spins but your machine's active model does not support spins.`,
          });
        }
        return;
      }

      case BuildPriority.Breaks: {
        if (!activeModel.supports_breaks) {
          NotifyHelper.warning({
            message_md: `The pitch definition prioritizes breaks but your machine's active model does not support breaks.`,
          });
        }
        return;
      }

      default: {
        return;
      }
    }
  }, [activeModel, dialogs, _priority]);

  const safeSetReference = (pitch: IPitch) => {
    if (pitch.priority) {
      _setPriority(pitch.priority);
    }

    const safePitch: IPitch = { ...pitch };

    if (!safePitch.breaks) {
      safePitch.breaks = { xInches: 0, zInches: 0 };
    }

    if (!safePitch.seams) {
      safePitch.seams = { latitude_deg: 0, longitude_deg: 0 };
    }

    _setReference(safePitch);
    _setVideoID(safePitch.video_id);
  };

  // automatically define working chars from reference (without a build)
  useEffect(() => {
    const getSafeMS = async () => {
      const refMS = getMSFromMSDict(_reference, machine).ms;
      if (refMS) {
        return refMS;
      }

      const payload = _getBuildPayload();
      if (!payload) {
        return undefined;
      }

      const result = (
        await StateTransformService.getInstance().buildPitches({
          machine: machine,
          notifyError: true,
          chars: [payload],
        })
      )[0];

      return result.ms;
    };

    const callback = async () => {
      const safeMS = await getSafeMS();

      if (!safeMS) {
        console.warn(
          `${CONTEXT_NAME}: got undefined safeMS value while setting working chars`
        );
      }

      const nextChars: Partial<IBuildPitchChars> = {
        ..._workingChars,
        bs: _reference.bs,
        traj: _reference.traj,
        ms: safeMS,
        priority: _reference.priority,
        seams: _reference.seams,
        breaks: _reference.breaks,
        plate: _reference.plate_loc_backup ?? DEFAULT_PLATE,
      };

      _setWorkingChars(nextChars);
    };

    callback();
  }, [_reference, machine]);

  const _getBuildPayload = (): Partial<IBuildPitchChars> | undefined => {
    if (!activeModel) {
      return undefined;
    }

    switch (_priority) {
      case BuildPriority.Default:
      case BuildPriority.Spins: {
        if (!activeModel.supports_spins) {
          NotifyHelper.warning({
            message_md: `The pitch definition prioritizes spins but your machine's active model does not support spins.`,
          });
          return undefined;
        }
        break;
      }

      case BuildPriority.Breaks: {
        if (!activeModel.supports_breaks) {
          NotifyHelper.warning({
            message_md: `The pitch definition prioritizes breaks but your machine's active model does not support breaks.`,
          });
          return undefined;
        }
        break;
      }

      default: {
        break;
      }
    }

    const output: Partial<IBuildPitchChars> = {
      temp_index: 0,
      bs: BallHelper.getBallStateFromChars(_ball),
      plate: _workingChars.plate ?? DEFAULT_PLATE,
      priority: _priority,
      seams: {
        latitude_deg: _ball.latitude_deg ?? 0,
        longitude_deg: _ball.longitude_deg ?? 0,
      },
      breaks: {
        // already flipped by the input function (i.e. in trajekt frame of ref)
        xInches: _ball.breaks?.xInches ?? 0,
        zInches: _ball.breaks?.zInches ?? 0,
      },
    };

    // console.debug('got a build payload', output);

    return output;
  };

  useEffect(() => {
    if (_ball.skipRebuild) {
      // console.debug('Ball Rebuild: skipped');
      return;
    }

    const jobID = v4();

    // console.debug(`${CONTEXT_NAME} queueing ball build ${jobID}`);

    clearTimeout(validationTimeout.current);

    validationTimeout.current = setTimeout(async () => {
      if (!current.auth) {
        // console.debug('Ball Rebuild: not auth');
        return;
      }

      console.debug(`${CONTEXT_NAME} executing ball build ${jobID}`);

      const warnings = PitchDesignHelper.getBallErrors(_ball, _priority);

      // show an error toast if necessary
      if (warnings.length > 0) {
        NotifyHelper.warning({
          message_md: warnings[0],
        });
        return;
      }

      // rebuild the ball (e.g. so that traj view updates)
      const payload = _getBuildPayload();
      if (!payload) {
        console.warn(`${CONTEXT_NAME} got empty build payload`);
        return;
      }

      const builtChars = (
        await StateTransformService.getInstance().buildPitches({
          machine: machine,
          notifyError: true,
          chars: [payload],
        })
      )[0];

      if (!builtChars) {
        NotifyHelper.warning({
          message_md: 'Empty results received from pitch build.',
        });
        return;
      }

      const nextChars: Partial<IBuildPitchChars> = {
        ..._workingChars,
        ...builtChars,
      };

      _setWorkingChars(nextChars);

      const nextBall: Partial<IBallChar> = {
        ..._ball,
        // avoid infinite rebuilds
        skipRebuild: true,
      };

      switch (_priority) {
        case BuildPriority.Breaks: {
          if (nextChars.bs) {
            nextBall.wx = nextChars.bs.wx;
            nextBall.wy = nextChars.bs.wy;
            nextBall.wz = nextChars.bs.wz;

            const spinExt = BallHelper.convertSpinToSpinExt({
              wx: nextBall.wx ?? 0,
              wy: nextBall.wy ?? 0,
              wz: nextBall.wz ?? 0,
            });

            nextBall.wnet = spinExt.wnet;
            nextBall.gyro_angle = spinExt.gyro_angle;
            nextBall.waxis = spinExt.waxis;
          }
          break;
        }

        case BuildPriority.Spins:
        default: {
          if (nextChars.breaks) {
            nextBall.breaks = nextChars.breaks;
          }
          break;
        }
      }

      _setBall(nextBall);
    }, 1_000);
  }, [current.auth, activeModel, _ball]);

  const state: IPitchDesignContext = {
    priority: _priority,
    setPriority: _setPriority,

    referenceKey: _referenceKey,
    reference: _reference,
    setReference: safeSetReference,

    ball: _ball,
    mergeBall: (config) => {
      console.debug(
        `${CONTEXT_NAME} mergeBall triggered from ${config.trigger}`
      );

      markDirtyForm(DirtyForm.PitchDesign);

      _setBall({
        ..._ball,
        ...config.ball,
        // always rebuild unless specifically skipped, regardless of current ball's flag
        skipRebuild: config.ball.skipRebuild ? true : false,
      });
    },

    videoID: _videoID,
    setVideoID: _setVideoID,

    workingKey: _workingKey,
    workingChars: _workingChars,
    mergeWorkingChars: (v) =>
      _setWorkingChars({
        ..._workingChars,
        ...v,
      }),

    getPitchPayload: () => {
      if (!_ball) {
        console.warn(`${CONTEXT_NAME}: no ball specified for pitch payload`);
        return;
      }

      if (!_workingChars.bs) {
        console.warn(
          `${CONTEXT_NAME}: no ball state specified for pitch payload`
        );
        return;
      }
      if (!_workingChars.ms) {
        console.warn(
          `${CONTEXT_NAME}: no machine state specified for pitch payload`
        );
        return;
      }

      if (!_workingChars.traj) {
        console.warn(
          `${CONTEXT_NAME}: no trajectory specified for pitch payload`
        );
        return;
      }

      if (!_workingChars.plate) {
        console.warn(`${CONTEXT_NAME}: no plate specified for pitch payload`);
        return;
      }

      const aimed = AimingHelper.aimWithoutShots({
        chars: {
          bs: _workingChars.bs,
          ms: _workingChars.ms,
          traj: _workingChars.traj,

          seams: _workingChars.seams,
          // breaks are not necessary for aiming
          // breaks: _workingChars.breaks,
          priority: _priority,
        },
        release: {
          px: _workingChars.bs.px,
          pz: _workingChars.bs.pz,
        },
        plate_location: _workingChars.plate,
      });

      const output: Partial<IPitch> = {
        _id: _reference._id || `temp-${v4()}`,
        _parent_id: _reference._parent_id,

        name: _reference.name ?? '',

        bs: aimed.bs,
        msDict: getMergedMSDict(machine, [aimed.ms]),
        traj: aimed.traj,

        seams: _workingChars.seams,
        priority: _priority,
        breaks: _workingChars.breaks,
        plate_loc_backup: aimed.plate,
      };

      /** append video only if selected, else leave alone to avoid overwriting video with undefined */
      if (_videoID) {
        output.video_id = _videoID;
      }

      return output;
    },
  };

  // keep ball py in sync with plate distance
  useEffect(() => {
    if (!_ball) {
      return;
    }

    // console.debug(
    //   `${CONTEXT_NAME} updating ball py to match machine plate distance`
    // );

    _setBall({
      ..._ball,
      py: machine.plate_distance,
    });
  }, [machine.plate_distance]);

  return (
    <PitchDesignContext.Provider value={state}>
      {props.children}
    </PitchDesignContext.Provider>
  );
};
