import {
  CaretDownIcon,
  CaretUpIcon,
  ClipboardCopyIcon,
} from '@radix-ui/react-icons';
import {
  Box,
  Button,
  Card,
  Code,
  DataList,
  Flex,
  IconButton,
  Popover,
  Spinner,
} from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { MachineContext } from 'contexts/machine.context';
import { parseISO } from 'date-fns';
import { format } from 'date-fns-tz';
import { LOCAL_DATETIME_FORMAT, LOCAL_TIMEZONE } from 'enums/env';
import { t } from 'i18next';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IAggregateCollectionResult } from 'lib_ts/interfaces/training/i-machine-shot';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { ShotsService } from 'services/shots.service';
import { InstallationContext } from './context';

export const InstallationDetails = () => {
  const { machine } = useContext(MachineContext);
  const { details, lowConfidence } = useContext(InstallationContext);

  const [openDetails, setOpenDetails] = useState(false);

  const [aggCalibration, setAggCalibration] = useState<
    IAggregateCollectionResult[]
  >([]);

  const [aggRepeatability, setAggRepeatability] = useState<
    IAggregateCollectionResult[]
  >([]);

  const [loadingAgg, setLoadingAgg] = useState(false);

  useEffect(() => {
    if (!openDetails) {
      return;
    }

    setLoadingAgg(true);

    Promise.all([
      ShotsService.getInstance()
        .getCollectionAggregate({
          machineID: machine.machineID,
          collectionID: details.calibration_id,
          includeLowConfidence: lowConfidence,
          includeHitterPresent: false,
        })
        .then((result) => setAggCalibration(result)),
      ShotsService.getInstance()
        .getCollectionAggregate({
          machineID: machine.machineID,
          collectionID: details.repeatability_id,
          includeLowConfidence: lowConfidence,
          includeHitterPresent: false,
        })
        .then((result) => {
          console.debug(result);
          setAggRepeatability(result);
        }),
    ]).finally(() => setLoadingAgg(false));
  }, [openDetails]);

  return (
    <Popover.Root onOpenChange={(open) => setOpenDetails(open)}>
      <Popover.Trigger>
        <Button
          size={RADIX.BUTTON.SIZE.MODAL}
          color={RADIX.COLOR.SECONDARY}
          variant="soft"
        >
          {t('common.details')}
          {openDetails ? <CaretUpIcon /> : <CaretDownIcon />}
        </Button>
      </Popover.Trigger>
      <Popover.Content asChild>
        <Card style={{ minWidth: '200px' }}>
          <Box p="1">
            <DataList.Root orientation="vertical" size="1">
              <DataList.Item>
                <DataList.Label>{t('common.ball-type')}</DataList.Label>
                <DataList.Value>{details.ball_type}</DataList.Value>
              </DataList.Item>
              <DataList.Item>
                <DataList.Label>{t('settings.user')}</DataList.Label>
                <DataList.Value>{details.started_by}</DataList.Value>
              </DataList.Item>
              <DataList.Item>
                <DataList.Label>{t('Started On')}</DataList.Label>
                <DataList.Value>
                  {format(parseISO(details.start_date), LOCAL_DATETIME_FORMAT, {
                    timeZone: LOCAL_TIMEZONE,
                  })}
                </DataList.Value>
              </DataList.Item>

              {details.end_date && (
                <DataList.Item>
                  <DataList.Label>{t('Finished On')}</DataList.Label>
                  <DataList.Value>
                    {format(parseISO(details.end_date), LOCAL_DATETIME_FORMAT, {
                      timeZone: LOCAL_TIMEZONE,
                    })}
                  </DataList.Value>
                </DataList.Item>
              )}

              <DataList.Item>
                <DataList.Label>{t('common.calibration-id')}</DataList.Label>
                <DataList.Value>
                  <Flex
                    gap="2"
                    justify="between"
                    align="center"
                    style={{ width: '100%' }}
                  >
                    <Code color={RADIX.COLOR.NEUTRAL}>
                      {details.calibration_id}
                    </Code>

                    <IconButton
                      className="btn-floating"
                      color={RADIX.COLOR.NEUTRAL}
                      size="1"
                      variant="soft"
                      onClick={() => {
                        if (!details.calibration_id) {
                          return;
                        }

                        navigator.clipboard.writeText(details.calibration_id);

                        NotifyHelper.success({
                          message_md: t('common.x-copied-to-clipboard', {
                            x: details.calibration_id,
                          }),
                        });
                      }}
                    >
                      <ClipboardCopyIcon />
                    </IconButton>
                  </Flex>
                </DataList.Value>
              </DataList.Item>

              <DataList.Item>
                <DataList.Label>{t('common.calibration-shots')}</DataList.Label>
                <DataList.Value>
                  {loadingAgg ? (
                    <Spinner />
                  ) : (
                    _.sumBy(aggCalibration, (m) => m.total)
                  )}
                </DataList.Value>
              </DataList.Item>

              <DataList.Item>
                <DataList.Label>{t('common.repeatability-id')}</DataList.Label>
                <DataList.Value>
                  <Flex
                    gap="2"
                    justify="between"
                    align="center"
                    style={{ width: '100%' }}
                  >
                    <Code color={RADIX.COLOR.NEUTRAL}>
                      {details.repeatability_id}
                    </Code>

                    <IconButton
                      className="btn-floating"
                      color={RADIX.COLOR.NEUTRAL}
                      size="1"
                      variant="soft"
                      onClick={() => {
                        if (!details.repeatability_id) {
                          return;
                        }

                        navigator.clipboard.writeText(details.repeatability_id);

                        NotifyHelper.success({
                          message_md: t('common.x-copied-to-clipboard', {
                            x: details.repeatability_id,
                          }),
                        });
                      }}
                    >
                      <ClipboardCopyIcon />
                    </IconButton>
                  </Flex>
                </DataList.Value>
              </DataList.Item>

              <DataList.Item>
                <DataList.Label>
                  {t('common.repeatability-shots')}
                </DataList.Label>
                <DataList.Value>
                  {loadingAgg ? (
                    <Spinner />
                  ) : (
                    <Box
                      title={`Aiming: ${_.sumBy(
                        aggRepeatability,
                        (m) => m.aiming
                      )}\nAnalysis: ${_.sumBy(
                        aggRepeatability,
                        (m) => m.analysis
                      )}`}
                    >
                      {_.sumBy(aggRepeatability, (m) => m.total)}
                    </Box>
                  )}
                </DataList.Value>
              </DataList.Item>
            </DataList.Root>
          </Box>
        </Card>
      </Popover.Content>
    </Popover.Root>
  );
};
