import { LinkBreak1Icon, PauseIcon, PlayIcon } from '@radix-ui/react-icons';
import { Box, Flex, Heading, Skeleton, Text } from '@radix-ui/themes';
import { WebSocketHelper } from 'classes/helpers/web-socket.helper';
import { SuperAdminIcon } from 'components/common/custom-icon/shorthands';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonSelectInput } from 'components/common/form/select';
import { AuthContext } from 'contexts/auth.context';
import { addSeconds, isPast } from 'date-fns';
import { format } from 'date-fns-tz';
import { LOCAL_TIMEZONE } from 'enums/env';
import { t } from 'i18next';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { CameraSource, WsMsgType } from 'lib_ts/enums/machine-msg.enum';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import {
  ICameraStreamRequestMsg,
  ICameraStreamResponseMsg,
} from 'lib_ts/interfaces/i-machine-msg';
import React from 'react';
import { WebSocketService } from 'services/web-socket.service';
import { IStreamingContext } from './context';
import './index.scss';

const COMPONENT_NAME = 'MachineCameraStreamViewer';

const FALLBACK_IMG_FORMAT = 'png';

const TOGGLE_PLAY_KEY = 'Space';

const CAMERA_OPTIONS: IOption[] = [
  {
    label: 'BI_R',
    value: CameraSource.BI_R,
  },
  {
    label: 'STEREO_CHECK',
    value: CameraSource.STEREO_CHECK,
  },
  {
    label: 'BI_DEBUG',
    value: CameraSource.BI_DEBUG,
  },
  {
    label: 'OD_L',
    value: CameraSource.OD_L,
  },
  {
    label: 'OD_T',
    value: CameraSource.OD_T,
  },
];

interface IProps {
  streamingCx: IStreamingContext;

  machineID: string;
  defaultSource?: CameraSource;
  hideSource?: boolean;
}

interface IState {
  source: CameraSource;
}

export class MachineCameraStreamViewer extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      source: props.defaultSource ?? (CAMERA_OPTIONS[0].value as CameraSource),
    };

    this.handleCameraStream = this.handleCameraStream.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.renderBlinker = this.renderBlinker.bind(this);
    this.changeSource = this.changeSource.bind(this);
  }

  componentDidMount() {
    WebSocketHelper.on(WsMsgType.M2U_CameraStream, this.handleCameraStream);

    document.addEventListener('keydown', this.handleKeyDown);

    this.changeSource(this.state.source, true, 'mount');
  }

  componentWillUnmount() {
    WebSocketHelper.remove(WsMsgType.M2U_CameraStream, this.handleCameraStream);

    document.removeEventListener('keydown', this.handleKeyDown);

    this.changeSource(this.state.source, false, 'unmount');
  }

  private handleKeyDown(event: KeyboardEvent) {
    if (![TOGGLE_PLAY_KEY].includes(event.code)) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    if (event.repeat) {
      return;
    }

    switch (event.code) {
      case TOGGLE_PLAY_KEY: {
        if (this.props.streamingCx.lastFrame) {
          this.props.streamingCx.setPaused(!this.props.streamingCx.paused);
        }
        return;
      }

      default: {
        return;
      }
    }
  }

  private handleCameraStream(event: CustomEvent) {
    const data: ICameraStreamResponseMsg = event.detail;

    if (data.source !== this.state.source) {
      return;
    }

    this.props.streamingCx.setLastFrame(data);
  }

  private changeSource(source: CameraSource, enable: boolean, trigger: string) {
    console.debug(`changing camera source to ${source} (${trigger})`);

    const msg: ICameraStreamRequestMsg = {
      machineID: this.props.machineID,
      source: source,
      enable: enable,
    };

    WebSocketService.send(WsMsgType.U2M_CameraStream, msg, COMPONENT_NAME);

    this.setState({
      source: source,
    });

    this.props.streamingCx.setLastFrame(undefined);
  }

  private renderBlinker() {
    const { paused, lastFrame } = this.props.streamingCx;

    const disconnected = paused
      ? false
      : lastFrame
      ? isPast(addSeconds(new Date(lastFrame.timestamp), 3))
      : true;

    const label = disconnected ? 'CONNECTING...' : paused ? 'PAUSED' : 'LIVE';

    const color = disconnected
      ? RADIX.COLOR.WARNING
      : paused
      ? undefined
      : RADIX.COLOR.SUCCESS;

    const icon = disconnected ? (
      <LinkBreak1Icon />
    ) : paused ? (
      <PauseIcon />
    ) : (
      <PlayIcon />
    );

    return (
      <Heading className="blinker" color={color} size={RADIX.HEADING.SIZE.MD}>
        <Text className={paused ? undefined : 'animate-blink'}>
          {icon}&nbsp;{label}
        </Text>
      </Heading>
    );
  }

  render() {
    const { lastFrame } = this.props.streamingCx;

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <Flex
          direction="column"
          gap={RADIX.FLEX.GAP.SM}
          className="CameraStream"
        >
          {!this.props.hideSource && (
            <AuthContext.Consumer>
              {(authCx) => {
                if (authCx.current.role === UserRole.admin) {
                  return (
                    <Box>
                      <CommonSelectInput
                        id="ms-inspector-source"
                        name="source"
                        label="Source"
                        labelIcon={<SuperAdminIcon />}
                        options={CAMERA_OPTIONS}
                        value={this.state.source}
                        onChange={(v) =>
                          this.changeSource(v as CameraSource, true, 'dropdown')
                        }
                      />
                    </Box>
                  );
                }
              }}
            </AuthContext.Consumer>
          )}

          {!lastFrame && <Skeleton height="300px" />}

          {lastFrame && (
            <Box className="sizer">
              {this.renderBlinker()}

              <img
                className="camera-feed-wide select-none"
                alt={`${lastFrame.source} @ ${lastFrame.timestamp}`}
                src={`data:image/${
                  lastFrame.format || FALLBACK_IMG_FORMAT
                };base64, ${lastFrame.image}`}
              />

              <div className="timestamp">
                <Text size={RADIX.TEXT.SIZE.XS}>
                  {t('common.last-updated')}:{' '}
                  {format(
                    new Date(lastFrame.timestamp),
                    'yyyy-MM-dd @ hh:mm:ss a',
                    { timeZone: LOCAL_TIMEZONE }
                  )}
                </Text>
              </div>
            </Box>
          )}
        </Flex>
      </ErrorBoundary>
    );
  }
}
