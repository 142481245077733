export type EnvIdentifier =
  | 'local'
  | 'dev'
  | 'sandbox'
  | 'staging'
  | 'beta'
  | 'prod'
  | 'prod-jp'
  | 'prod-kr'
  | 'maintenance';

export const LOCAL_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const LOCAL_DATE_FORMAT = 'yyyy-MM-dd';
export const LOCAL_DATETIME_TIMEZONE_FORMAT = 'yyyy-MM-dd @ HH:mm zzz';
export const LOCAL_DATETIME_FORMAT = 'yyyy-MM-dd @ h:mm a';
export const LOCAL_DATETIME_FORMAT_MONO = 'yyyy-MM-dd @ hh:mm a';
export const LOCAL_DATETIME_FORMAT_SHORT = 'yyyy-MM-dd @ h:mm a';
export const LOCAL_DATETIME_FORMAT_LONG = 'yyyy-MM-dd @ h:mm:ss a';
export const LOCAL_GAMETIME_FORMAT = 'yyyy-MM-dd · h:mm a';
