import { RulerSquareIcon } from '@radix-ui/react-icons';
import { Box, Button, Card, Flex, Grid, Text } from '@radix-ui/themes';
import { HELP_URLS } from 'classes/helpers/url.helper';
import { CustomIcon } from 'components/common/custom-icon';
import { MachineIcon } from 'components/common/custom-icon/shorthands';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonLink } from 'components/common/link';
import { CommonStepHeader } from 'components/machine/dialogs/installation/header';
import { MachineContext } from 'contexts/machine.context';
import { CustomIconPath } from 'enums/custom.enums';
import { SubStatus } from 'enums/installation';
import { t } from 'i18next';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { CalibrateProc } from 'lib_ts/interfaces/i-machine-msg';
import { ReactNode, useContext } from 'react';

const COMPONENT_NAME = 'StepProjectorConfig';
const DELTA = 0.005;

interface IProps {
  state: SubStatus;
  onStart: () => void;
}

const ButtonFlexed = (props: {
  label: string;
  icon: ReactNode;
  onClick: () => void;
}) => (
  <Button className="width-120px" onClick={props.onClick}>
    <Flex align="center" justify="between" style={{ width: '100%' }}>
      <Box>{t(props.label)}</Box>
      {props.icon}
    </Flex>
  </Button>
);

export const StepProjectorConfig = (props: IProps) => {
  const { adjustKeystone, calibrate } = useContext(MachineContext);

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <Flex
        direction="column"
        gap="4"
        style={{
          height: '100%',
          // border: '1px solid red'
        }}
      >
        <CommonStepHeader label="Projector Configuration" />

        {props.state === SubStatus.NotStarted && (
          <Flex
            flexGrow="1"
            direction="column"
            gap="5"
            align="center"
            justify="center"
            style={
              {
                // border: '1px solid blue'
              }
            }
          >
            <Box>
              <img
                style={{
                  width: '351px',
                }}
                src="/img/renders/projector-setup.png"
              />
            </Box>
            <Text align="center" color={RADIX.COLOR.SECONDARY}>
              You're about to calibrate the projector to ensure proper rendering
              of pitcher release videos and an optimal training experience.
            </Text>
            <Box>
              <Button onClick={props.onStart}>
                Start Projector Configuration
              </Button>
            </Box>
          </Flex>
        )}

        {props.state === SubStatus.InProgress && (
          <>
            <Box>
              <Text color={RADIX.COLOR.SECONDARY}>
                <ol
                  className="no-margin"
                  style={{ paddingInlineStart: '20px' }}
                >
                  <li>Toggle the rulers ON.</li>
                  <li>
                    Use a measuring tape to compare the projected measurements
                    to actual ones.
                  </li>
                  <li>
                    Adjust the image as needed to fit the projected image on
                    HDPE.
                    <ul
                      className="no-margin"
                      style={{ paddingInlineStart: '20px' }}
                    >
                      <li>
                        Use the horizontal or vertical shift to move the image.
                      </li>
                      <li>
                        Use the zoom & focus lens to adjust size and clarity{' '}
                      </li>
                    </ul>
                  </li>
                </ol>
              </Text>
            </Box>

            <Box>
              <Text color={RADIX.COLOR.SECONDARY}>
                Please adjust the projector outline so the overlay aligns
                proportionately with the front boards of the machine.
              </Text>
            </Box>

            <Box>
              <Text color={RADIX.COLOR.SECONDARY}>
                You can read additional information on setting up the projector{' '}
                <CommonLink url={HELP_URLS.PROJECTOR}>here</CommonLink>.
              </Text>
            </Box>

            <Card>
              <Flex justify="center">
                <Grid columns="3" gap="2">
                  {/* row 1 */}
                  <Flex justify="center">
                    <Box>Horizontal</Box>
                  </Flex>
                  <Flex justify="center">
                    <Box>Vertical</Box>
                  </Flex>
                  <Flex justify="center">
                    <Box>Guides</Box>
                  </Flex>

                  {/* row 2 */}
                  <Box>
                    <ButtonFlexed
                      data-id="h-stretch"
                      icon={<CustomIcon icon={CustomIconPath.ColumnStretch} />}
                      label="Stretch"
                      onClick={() =>
                        adjustKeystone(COMPONENT_NAME, {
                          delta_h: DELTA,
                        })
                      }
                    />
                  </Box>

                  <Box>
                    <ButtonFlexed
                      data-id="v-stretch"
                      icon={<CustomIcon icon={CustomIconPath.RowStretch} />}
                      label="Stretch"
                      onClick={() =>
                        adjustKeystone(COMPONENT_NAME, {
                          delta_v: DELTA,
                        })
                      }
                    />
                  </Box>

                  <Box>
                    <ButtonFlexed
                      data-id="toggle-outlines"
                      icon={<MachineIcon />}
                      label="Outlines"
                      onClick={() =>
                        calibrate(
                          [CalibrateProc.ProjectorOutline],
                          COMPONENT_NAME
                        )
                      }
                    />
                  </Box>

                  {/* row 3 */}
                  <Box>
                    <ButtonFlexed
                      data-id="h-compress"
                      icon={<CustomIcon icon={CustomIconPath.ColumnCompress} />}
                      label="Compress"
                      onClick={() =>
                        adjustKeystone(COMPONENT_NAME, {
                          delta_h: -DELTA,
                        })
                      }
                    />
                  </Box>

                  <Box>
                    <ButtonFlexed
                      data-id="v-compress"
                      icon={<CustomIcon icon={CustomIconPath.RowCompress} />}
                      label="Compress"
                      onClick={() =>
                        adjustKeystone(COMPONENT_NAME, {
                          delta_v: -DELTA,
                        })
                      }
                    />
                  </Box>

                  <Box>
                    <ButtonFlexed
                      data-id="toggle-rulers"
                      icon={<RulerSquareIcon />}
                      label="Rulers"
                      onClick={() =>
                        calibrate(
                          [CalibrateProc.ProjectorRulers],
                          COMPONENT_NAME
                        )
                      }
                    />
                  </Box>
                </Grid>
              </Flex>
            </Card>
          </>
        )}
      </Flex>
    </ErrorBoundary>
  );
};
