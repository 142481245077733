import { StringHelper } from 'classes/helpers/string.helper';
import { AuthContext } from 'contexts/auth.context';
import { MachineContext } from 'contexts/machine.context';
import { ResultsTabKey } from 'enums/installation';
import { BallType } from 'lib_ts/enums/machine.enums';
import { IInstallationDetails } from 'lib_ts/interfaces/i-machine';
import { IModelPrediction } from 'lib_ts/interfaces/modelling/i-eval-models';
import { IRepeatabilityResult } from 'lib_ts/interfaces/modelling/i-repeatability';
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { SessionEventsService } from 'services/session-events.service';

const CONTEXT_NAME = 'InstallationContext';

export interface IInstallationContext {
  // because the dialog window needs to know which tab is open for download reports to work
  resultsTab: ResultsTabKey;
  readonly setResultsTab: (value: ResultsTabKey) => void;

  details: IInstallationDetails;
  readonly updateDetails: (value: Partial<IInstallationDetails>) => void;

  modelPerformance?: IModelPrediction;
  readonly setModelPerformance: (value: IModelPrediction) => void;

  machinePerformance?: IRepeatabilityResult;
  readonly setMachinePerformance: (value: IRepeatabilityResult) => void;

  // for filtering shots for ball type calibration and repeatability
  lowConfidence: boolean;
  readonly setLowConfidence: (value: boolean) => void;
}

const DEFAULT: IInstallationContext = {
  resultsTab: ResultsTabKey.ModelPerformance,
  setResultsTab: () => console.error(`${CONTEXT_NAME}: not init`),

  details: {
    started_by: 'not init',
    start_date: new Date().toISOString(),
    ball_type: BallType.MLB,
    calibration_id: 'not init',
    repeatability_id: 'not init',
  },
  updateDetails: () => console.error(`${CONTEXT_NAME}: not init`),

  setModelPerformance: () => console.error(`${CONTEXT_NAME}: not init`),
  setMachinePerformance: () => console.error(`${CONTEXT_NAME}: not init`),

  lowConfidence: false,
  setLowConfidence: () => console.error(`${CONTEXT_NAME}: not init`),
};

export const InstallationContext = createContext(DEFAULT);

interface IProps {
  children: ReactNode;
}

export const InstallationProvider: FC<IProps> = (props) => {
  const { current } = useContext(AuthContext);
  const { machine, update: updateMachine } = useContext(MachineContext);

  const [details, setDetails] = useState<IInstallationDetails>(
    machine.last_installation ?? {
      started_by: current.email,

      start_date: new Date().toISOString(),

      ball_type: machine.ball_type,

      calibration_id: StringHelper.getCollectionID(),
      repeatability_id: StringHelper.getCollectionID(),
    }
  );

  // keep details in sync with the latest value (e.g. when machine is updated)
  useEffect(() => {
    if (!machine.last_installation) {
      return;
    }

    setDetails(machine.last_installation);
  }, [machine.last_installation]);

  // loaded when the user opens the relevant tab on results step
  const [modelPerformance, setModelPerformance] = useState<IModelPrediction>();
  // loaded when the user opens the relevant tab on results step
  const [machinePerformance, setMachinePerformance] =
    useState<IRepeatabilityResult>();

  const [resultsTab, setResultsTab] = useState(DEFAULT.resultsTab);

  const [requireConfidence, setRequireConfidence] = useState(
    DEFAULT.lowConfidence
  );

  const state: IInstallationContext = {
    resultsTab: resultsTab,
    setResultsTab: setResultsTab,

    details: details,
    updateDetails: (value) => {
      const nextValue: IInstallationDetails = {
        ...details,
        ...value,
      };

      updateMachine({
        _id: machine._id,
        last_installation: nextValue,
      });

      if (value.end_date) {
        SessionEventsService.postEvent({
          category: 'install',
          tags: 'ended',
          data: nextValue,
        });
      }
    },

    modelPerformance: modelPerformance,
    setModelPerformance: setModelPerformance,

    machinePerformance: machinePerformance,
    setMachinePerformance: (v) =>
      setMachinePerformance({ ...v, datestamp: new Date() }),

    lowConfidence: requireConfidence,
    setLowConfidence: setRequireConfidence,
  };

  return (
    <InstallationContext.Provider value={state}>
      {props.children}
    </InstallationContext.Provider>
  );
};
