import { NotifyHelper } from 'classes/helpers/notify.helper';
import { EditSessionDialog } from 'components/common/sessions/dialogs/edit-session';
import { VisualizeSessionDialog } from 'components/common/sessions/dialogs/visualize-session';
import { ISettingsDialog } from 'components/common/settings-dialog';
import { AuthContext } from 'contexts/auth.context';
import format from 'date-fns-tz/format';
import lightFormat from 'date-fns/lightFormat';
import parseISO from 'date-fns/parseISO';
import { LOCAL_TIMEZONE } from 'enums/env';
import { SessionDialogMode } from 'enums/session.enums';
import { t } from 'i18next';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { CSVHelper } from 'lib_ts/classes/csv.helper';
import { TrajHelper } from 'lib_ts/classes/trajectory.helper';
import { UserRole } from 'lib_ts/enums/auth.enums';
import {
  EMPTY_TRACKING_DATA,
  ICombinedData,
} from 'lib_ts/interfaces/csv/exports/i-session-fires';
import {
  IPitchStat,
  IPitchStatsFilters,
} from 'lib_ts/interfaces/data/i-pitch-stats';
import { IMachineHardwareConfigBackup } from 'lib_ts/interfaces/i-machine-hw-config-backup';
import { ISessionSummary } from 'lib_ts/interfaces/i-session-summary';
import { IMachineShot } from 'lib_ts/interfaces/training/i-machine-shot';
import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { DataService } from 'services/data.service';
import { MachinesService } from 'services/machines.service';
import {
  MAX_USER_SESSIONS,
  SessionEventsService,
} from 'services/session-events.service';

const CONTEXT_NAME = 'SessionEventsContext';

const DATE_FORMAT = 'yyyy-MM-dd';
const TIME_FORMAT = 'HH:mm:ss.SSS z';

interface IDialogConfig {
  session: string;
  mode: SessionDialogMode;
  key: number;
}

interface IFilters {
  sessions: string[];
  starts: string[];
}

export interface ISessionEventsContext {
  filters: IFilters;
  readonly setFilters: (value: Partial<IFilters>) => void;

  filtered: ISessionSummary[];

  sessions: ISessionSummary[];
  sessionsOptions: {
    [key: string]: string[];
  };

  selectedSession?: ISessionSummary;

  loading: boolean;

  fired: number;
  readonly increaseFired: () => void;

  /** open settings from anywhere, providing an undefined config will close it */
  readonly setSettingsDialog: (config: ISettingsDialog | undefined) => void;

  /** undefined value =>  hidden */
  settingsDialog?: ISettingsDialog;

  /** pops the session details editor from anywhere */
  readonly showDialog: (config: IDialogConfig) => void;

  readonly getShotsData: (
    session: string,
    silently?: boolean
  ) => Promise<ICombinedData[]>;

  readonly getPitchStatsData: (
    filters: IPitchStatsFilters,
    silenty?: boolean
  ) => Promise<IPitchStat[]>;

  readonly refresh: () => void;

  readonly selectSession: (session: ISessionSummary | undefined) => void;
}

const DEFAULT: ISessionEventsContext = {
  filters: {
    sessions: [],
    starts: [],
  },
  setFilters: () => console.error(`${CONTEXT_NAME}: not init`),

  filtered: [],

  sessions: [],
  sessionsOptions: {},

  loading: false,

  fired: 0,
  increaseFired: () => console.error(`${CONTEXT_NAME}: not init`),

  setSettingsDialog: () => console.error(`${CONTEXT_NAME}: not init`),

  showDialog: () => console.error(`${CONTEXT_NAME}: not init`),
  getShotsData: () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),
  getPitchStatsData: () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),
  refresh: () => console.error(`${CONTEXT_NAME}: not init`),
  selectSession: () => console.error(`${CONTEXT_NAME}: not init`),
};

export const SessionEventsContext = createContext(DEFAULT);

const getSessionsOptions = (
  items: ISessionSummary[]
): {
  [key: string]: any[];
} => {
  if (items) {
    return {
      name: ArrayHelper.unique(items.map((m) => m.name)),

      session: ArrayHelper.unique(items.map((m) => m.session)),

      start: ArrayHelper.unique(
        items.map((m) => lightFormat(parseISO(m.start), 'yyyy-MM-dd'))
      ),
    };
  } else {
    return {};
  }
};

interface IProps {
  children: ReactNode;
}

export const SessionEventsProvider: FC<IProps> = (props) => {
  const authCx = useContext(AuthContext);

  const [_sessions, _setSessions] = useState(DEFAULT.sessions);
  const [_sessionsOptions, _setSessionsOptions] = useState(
    getSessionsOptions(DEFAULT.sessions)
  );

  const [_selectedSession, _setSelectedSession] = useState(
    DEFAULT.selectedSession
  );

  const [_loading, _setLoading] = useState(DEFAULT.loading);
  const [_lastFetched, _setLastFetched] = useState<Date | undefined>();

  const [_settingsDialog, _setSettingsDialog] = useState<
    ISettingsDialog | undefined
  >();
  const [_dialog, _setDialog] = useState<IDialogConfig | undefined>();

  /** used to count fire events sent for the current session */
  const [_fired, _setFired] = useState(DEFAULT.fired);

  const [_filters, _setFilters] = useState<IFilters>(DEFAULT.filters);

  const _filtered = useMemo(() => {
    return _sessions
      .filter(
        (m) =>
          _filters.sessions.length === 0 ||
          _filters.sessions.includes(m.session)
      )
      .filter(
        (m) =>
          _filters.starts.length === 0 ||
          _filters.starts.includes(lightFormat(parseISO(m.start), 'yyyy-MM-dd'))
      );
  }, [_sessions, _filters.sessions, _filters.starts]);

  const state: ISessionEventsContext = {
    filters: _filters,
    setFilters: (v) => _setFilters({ ..._filters, ...v }),

    filtered: _filtered,

    sessions: _sessions,
    sessionsOptions: _sessionsOptions,

    selectedSession: _selectedSession,

    loading: _loading,

    settingsDialog: _settingsDialog,

    setSettingsDialog: (config) => {
      _setSettingsDialog(config);
    },

    showDialog: (config) => {
      if (config.mode === SessionDialogMode.none) {
        _setDialog(undefined);
        return;
      }

      _setDialog(config);
    },

    fired: _fired,
    increaseFired: () => {
      _setFired(_fired + 1);

      /** notify user after every 50th shot */
      if (_fired !== 0 && _fired % 50 === 0) {
        NotifyHelper.info({
          message_md: t('common.you-have-fired-x-shots', { x: _fired }),
        });
        NotifyHelper.haveFun();
      }
    },

    getShotsData: async (session, silently) => {
      try {
        _setLoading(true);

        if (!silently) {
          NotifyHelper.info({
            message_md: t('common.please-wait-request-processing'),
          });
        }

        const mstargets =
          await SessionEventsService.getExportShotsForSession(session);

        // empty and undefined values will be stripped
        const hwConfigIDs = ArrayHelper.unique(
          mstargets.map((m) => m.data?.hw_config_id as string)
        );

        const hwConfigBackups =
          authCx.current.role === UserRole.admin
            ? await MachinesService.getInstance().getMachineHWConfigs(
                hwConfigIDs
              )
            : [];

        const hwConfigDict: {
          [hw_config_id: string]: IMachineHardwareConfigBackup | undefined;
        } = {};

        hwConfigBackups.forEach((m) => {
          hwConfigDict[m._id] = m;
        });

        const output: ICombinedData[] = [];

        mstargets
          .filter((m) => m.fires && m.fires.length > 0)
          .forEach((target) => {
            try {
              const targetData = target.data;

              if (!targetData) {
                return;
              }

              const targetCreated = parseISO(target._created);

              const baseRow: ICombinedData = {
                Trajekt: {
                  MachineID: target.machine,
                  Session: session,
                  Date: format(targetCreated, DATE_FORMAT, {
                    timeZone: LOCAL_TIMEZONE,
                  }),
                  Timestamp: format(targetCreated, TIME_FORMAT, {
                    timeZone: LOCAL_TIMEZONE,
                  }),
                  ShotNumber: 0, // will be overwritten later

                  ...CSVHelper.mstargetToTrajekt(targetData),
                },

                /** ensures the column is printed/accounted for in CSV output, even if first row was only from mstarget */
                Tracking: { ...EMPTY_TRACKING_DATA },

                Hardware:
                  hwConfigDict[target.data?.hw_config_id ?? 'none']?.config,
              };

              /** fallback for no rapsodo data, check if machine was fired */
              const fires = target.fires ? target.fires : [];

              /** always create a record for each fire event, using shot details where possible */
              fires.forEach((f) => {
                const fireCreated = parseISO(f._created);

                const shot = f.shot ? (f.shot[0] as IMachineShot) : undefined;

                const targetBreaks =
                  targetData.breaks ??
                  (targetData.traj
                    ? TrajHelper.getBreaks(targetData.traj)
                    : undefined);

                const actualBreaks = (() => {
                  if (!shot?.traj) {
                    return undefined;
                  }

                  return TrajHelper.getBreaks(shot.traj);
                })();

                const timeOfPitch = f.msbs?.[0].time
                  ? // convert from UTC seconds to ms
                    new Date(f.msbs[0].time * 1_000)
                  : undefined;

                const row: ICombinedData = {
                  Trajekt: {
                    ...baseRow.Trajekt,

                    /** overwrite mstarget date + time with shot's date + time */
                    Date: format(fireCreated, DATE_FORMAT, {
                      timeZone: LOCAL_TIMEZONE,
                    }),
                    Timestamp: format(fireCreated, TIME_FORMAT, {
                      timeZone: LOCAL_TIMEZONE,
                    }),

                    TimeOfPitchUTC: timeOfPitch?.toISOString(),

                    InGame: f.data.in_game ? 'Y' : 'N',
                    Training: f.data.training ? 'Y' : 'N',
                    Valid: f.data.rejected ? 'N' : 'Y',
                    ErrorMsg: f.data.rejected_msg ?? '',

                    TargetVB: targetBreaks?.zInches,
                    TargetHB: targetBreaks ? -targetBreaks.xInches : undefined,

                    ActualVB: actualBreaks?.zInches,
                    ActualHB: actualBreaks ? -actualBreaks.xInches : undefined,

                    Hitter: f.data.hitterExt?.name,
                  },

                  Tracking: (() => {
                    if (f.rapsodo?.[0]) {
                      return CSVHelper.rapsodoToTracking(f.rapsodo[0]);
                    }

                    if (
                      f.trackman_pitch?.data.data.Pitch ||
                      f.trackman_hit?.data.data.Hit
                    ) {
                      return CSVHelper.trackmanToTracking({
                        pitch: f.trackman_pitch?.data.data.Pitch,
                        hit: f.trackman_hit?.data.data.Hit,
                      });
                    }

                    return baseRow.Tracking;
                  })(),

                  Hardware: baseRow.Hardware,
                };

                output.push(row);
              });
            } catch (e2) {
              console.error(e2);
            }
          });

        /** fix shot numbers */
        output.forEach((row, i) => (row.Trajekt.ShotNumber = i + 1));

        return output;
      } catch (e1) {
        console.error(e1);
        NotifyHelper.error({
          message_md:
            'Encountered an error while processing your request. Please try again later.',
        });

        return [];
      } finally {
        _setLoading(false);
      }
    },

    getPitchStatsData: async (filters, silently) => {
      try {
        _setLoading(true);

        if (!silently) {
          NotifyHelper.info({
            message_md: t('common.please-wait-request-processing'),
          });
        }

        return await DataService.getPitchStats(filters);
      } catch (e) {
        console.error(e);
        NotifyHelper.error({
          message_md:
            'Encountered an error while processing your request. Please try again later.',
        });
        return [];
      } finally {
        _setLoading(false);
      }
    },
    refresh: () => _setLastFetched(new Date()),
    selectSession: _setSelectedSession,
  };

  /** fetch the data at load */
  useEffect(() => {
    if (!_lastFetched) {
      return;
    }

    (async (): Promise<void> => {
      _setLoading(true);

      const sessions = await SessionEventsService.getUserSessions(
        authCx.current.userID,
        MAX_USER_SESSIONS
      );

      if (sessions) {
        _setSessions(sessions);
      } else {
        console.warn('Session events failed to load');
        _setSessions([]);
      }

      _setLoading(false);
    })();
  }, [_lastFetched]); // dependency list => run whenever lastFetched is changed

  /** reload data to match session access */
  useEffect(() => {
    /** trigger refresh only once logged in/successfully resumed */
    if (authCx.current.auth && authCx.current.session) {
      _setLastFetched(new Date());
    }
  }, [authCx.current.auth, authCx.current.session]);

  useEffect(() => {
    _setSessionsOptions(getSessionsOptions(_sessions));
  }, [_sessions]); // dependency list => run whenever sessions is changed

  return (
    <SessionEventsContext.Provider value={state}>
      {props.children}

      {_dialog?.mode === SessionDialogMode.edit && (
        <EditSessionDialog
          key={_dialog.key}
          identifier="EditSessionDialog"
          session={_dialog.session}
          fires={
            authCx.current.session === _dialog.session
              ? _fired
              : _sessions.find((s) => s.session === _dialog.session)?.fires ?? 0
          }
          onChanged={() => _setLastFetched(new Date())}
          onClose={() => _setDialog(undefined)}
        />
      )}

      {_dialog?.mode === SessionDialogMode.visualize && (
        <VisualizeSessionDialog
          key={_dialog.key}
          identifier="VisualizeSessionDialog"
          session={_dialog.session}
          onClose={() => _setDialog(undefined)}
        />
      )}
    </SessionEventsContext.Provider>
  );
};
