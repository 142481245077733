import { CommonCallout } from 'components/common/callouts';
import { MachineContext } from 'contexts/machine.context';
import { addMinutes, isPast } from 'date-fns';
import { SfxName } from 'lib_ts/enums/machine-msg.enum';
import { useContext, useState } from 'react';

const TESTING = false;

export const DataCollectorEmptyHopperDetector = () => {
  const { lastBallCount, playSound } = useContext(MachineContext);

  const [lastPlayed, setLastPlayed] = useState<Date>();

  if (TESTING || lastBallCount === 0) {
    // play a tone on the projector (no more than once per minute)
    if (!lastPlayed || isPast(addMinutes(lastPlayed, 1))) {
      setLastPlayed(new Date());
      // todo: do we need a special sound for empty hopper?
      playSound(SfxName.ERROR);
    }

    return (
      <CommonCallout text="The ball hopper is empty. Please drop ball once the hopper has been refilled." />
    );
  }

  return <></>;
};
