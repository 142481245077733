import env from 'config';

const showBetaFeatures =
  !env.production || ['staging', 'beta'].includes(env.identifier);

// each feature could use custom logic or not

export const BETA_TRACKMAN_DEVICE = showBetaFeatures;
export const BETA_VISION_DEVICE = showBetaFeatures;
export const BETA_MACHINE_INSTALLER = showBetaFeatures;
