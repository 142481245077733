import { IOption } from 'lib_ts/interfaces/common/i-option';

/** impersonation sessions will have id starting with this */
export const IMP_PREFIX = 'IMP-';
/** super sessions will have id starting with this */
export const SUPER_PREFIX = 'SUP-';

export enum JwtType {
  auth = 'auth',
  passwordReset = 'passwordReset',
  emailVerify = 'emailVerify',
}

export enum LoginMethod {
  None = '',
  Auth0 = 'Auth0',
  Legacy = 'Legacy',
}

export enum UserStatus {
  deactivated = 'deactivated',
  verified = 'verified',
  unverified = 'unverified',
}

export enum UserRole {
  basic = 'basic',
  team_admin = 'team-admin',
  admin = 'admin',
}

export const USER_ROLES: IOption[] = [
  {
    label: 'constants.user-role-user',
    value: UserRole.basic,
  },
  {
    label: 'constants.user-role-team-admin',
    value: UserRole.team_admin,
  },
  {
    label: 'constants.user-role-super-admin',
    value: UserRole.admin,
  },
];

export enum EmailType {
  emailValidator = 'emailValidator',
  resetPassword = 'resetPassword',
}

/** needs to match entries in the server's .env configuration for LOGIN_DOMAINS */
export enum LoginDomain {
  Local = 'localhost',
  Dev = 'dev.trajektsports.app',
  Prod = 'trajektsports.app',
  Staging = 'staging.trajektsports.app',
}

export type LoginError =
  | 'credentials'
  | 'verification'
  | 'domain'
  | 'internal'
  | 'other';

export enum ApiType {
  // used by customers, e.g. for accessing session events
  Customer = 'customer',
}

export enum MenuMode {
  default = 'default',
  modal = 'modal',
}
