import { Box, Flex, Progress, Text } from '@radix-ui/themes';
import { WebSocketHelper } from 'classes/helpers/web-socket.helper';
import {
  LowerMachineIcon,
  R2FIcon,
} from 'components/common/custom-icon/shorthands';
import {
  DialogButton,
  DialogIconButton,
} from 'components/common/dialogs/button';
import { MachineAutoFireButton } from 'components/machine/buttons/auto-fire';
import { MachineFireButton } from 'components/machine/buttons/fire';
import { IAimingContext } from 'contexts/aiming.context';
import { IAuthContext } from 'contexts/auth.context';
import { ICookiesContext } from 'contexts/cookies.context';
import { IMachineContext, MachineDialogMode } from 'contexts/machine.context';
import { IMatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { ITrainingContext } from 'contexts/training.context';
import { isFuture } from 'date-fns';
import { FireInstruction } from 'enums/machine.enums';
import { ProgressDirection, PTStep } from 'enums/training.enums';
import { t } from 'i18next';
import { clamp } from 'lib_ts/classes/math.utilities';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { WsMsgType } from 'lib_ts/enums/machine-msg.enum';
import { FiringMode } from 'lib_ts/enums/machine.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { SpecialMsPosition } from 'lib_ts/interfaces/machine-msg/i-special-mstarget';
import React from 'react';

const COMPONENT_NAME = 'PTStatusBar';

const BTN_CLASS = 'width-100px';

interface IProps {
  cookiesCx: ICookiesContext;
  authCx: IAuthContext;
  machineCx: IMachineContext;
  matchingCx: IMatchingShotsContext;
  aimingCx: IAimingContext;
  trainingCx: ITrainingContext;

  step: PTStep;

  iteration: number;
  shot: number;
  totalShots: number;
  trained: boolean;
  isLastPitch: boolean;

  beforeFire?: (mode: FiringMode, isReady: boolean) => void;
  changePitch: (delta: ProgressDirection) => void;
  onFinish?: () => void;
}

interface IState {
  /** for suppressing auto-fire actions until a fireresponse arrives (e.g. after toggling it on) */
  ignoreAutoFire?: boolean;

  // updated as fire button changes state
  description?: string;
}

export class PTStatusBar extends React.Component<IProps, IState> {
  fireButton?: MachineFireButton;

  constructor(props: IProps) {
    super(props);

    this.state = {
      ignoreAutoFire: true,
    };

    this.getDescription = this.getDescription.bind(this);
    this.handleFireResponse = this.handleFireResponse.bind(this);

    this.renderFinishButton = this.renderFinishButton.bind(this);
    this.renderFireButton = this.renderFireButton.bind(this);
    this.renderNextButton = this.renderNextButton.bind(this);
    this.renderSendButton = this.renderSendButton.bind(this);

    this.sendPitch = this.sendPitch.bind(this);
  }

  componentDidMount(): void {
    WebSocketHelper.on(WsMsgType.M2U_FireResponse, this.handleFireResponse);
  }

  componentWillUnmount(): void {
    WebSocketHelper.remove(WsMsgType.M2U_FireResponse, this.handleFireResponse);
  }

  private async handleFireResponse() {
    /** we can stop ignoring auto-fire status once a fire has already happened */
    this.setState({
      ignoreAutoFire: false,
    });
  }

  /** sends the active pitch (without additional rotation) */
  sendPitch(trigger: string) {
    /** fire button will listen for r2f/fireresponse */
    this.fireButton?.resetR2FWait(`${COMPONENT_NAME} > before send pitch`);

    this.props.aimingCx.sendToMachine({
      training: true,
      skipPreview: true,
      trigger: `${COMPONENT_NAME} > ${trigger}`,
    });
  }

  private getDescription() {
    switch (this.props.step) {
      case PTStep.Complete: {
        return t('common.pitch-trained-successfully');
      }

      case PTStep.Firing:
      default: {
        if (isFuture(this.props.aimingCx.loadingUntil)) {
          return t('common.preparing-to-fire');
        }

        if (this.props.machineCx.lastBallCount !== 1) {
          return t('common.no-balls-msg');
        }

        return t(this.state.description ?? 'common.preparing-to-fire');
      }
    }
  }

  render() {
    const safeProgress = clamp(
      this.props.shot / (this.props.totalShots || 1),
      0,
      1
    );

    const description = this.getDescription();

    const isAdmin =
      this.props.authCx.current.role === UserRole.admin ||
      this.props.authCx.current.mode === 'impostor';

    return (
      <Flex gap={RADIX.FLEX.GAP.SM} align="center" style={{ height: '100%' }}>
        <Flex flexGrow="1" direction="column" gap={RADIX.FLEX.GAP.SM}>
          <Flex gap={RADIX.FLEX.GAP.SM} justify="between">
            <Box>
              <Text truncate>{description}</Text>
            </Box>
            <Box>
              {`${t('common.iteration')} ${this.props.iteration} · ${t(
                'common.shot'
              )} ${clamp(this.props.shot, 1, this.props.totalShots)}/${Math.max(
                this.props.totalShots,
                1
              )}`}
            </Box>
          </Flex>
          <Progress value={safeProgress * 100} />
        </Flex>

        {isAdmin && (
          <DialogIconButton
            icon={<R2FIcon />}
            color={RADIX.COLOR.SUPER_ADMIN}
            tooltip={t('common.ready-status').toString()}
            onClick={() =>
              this.props.machineCx.setDialog(MachineDialogMode.R2F)
            }
          />
        )}

        <DialogIconButton
          color={RADIX.COLOR.WARNING}
          icon={<LowerMachineIcon />}
          tooltip="common.lower-machine"
          onClick={() => {
            this.props.machineCx.setAutoFire(false);
            this.props.machineCx.specialMstarget(SpecialMsPosition.lowered);
          }}
        />

        <MachineAutoFireButton
          as="dialog-button"
          className={BTN_CLASS}
          beforeToggleFn={(newValue) =>
            this.setState({ ignoreAutoFire: newValue })
          }
        />

        {this.props.machineCx.lastMSHash
          ? this.props.trained
            ? this.props.isLastPitch
              ? this.renderFinishButton()
              : this.renderNextButton()
            : this.renderFireButton()
          : this.renderSendButton()}
      </Flex>
    );
  }

  private renderSendButton() {
    return (
      <DialogButton
        color={RADIX.COLOR.SEND_PITCH}
        className={BTN_CLASS}
        onClick={() => this.sendPitch('renderSendButton')}
        label="common.load-pitch"
      />
    );
  }

  private renderNextButton() {
    const willAutoFire =
      this.props.machineCx.autoFire && !this.state.ignoreAutoFire;

    const label =
      this.props.step === PTStep.Complete
        ? willAutoFire
          ? 'common.loading'
          : 'common.next'
        : 'common.skip';

    return (
      <DialogButton
        disabled={willAutoFire}
        className={BTN_CLASS}
        onClick={() => {
          this.props.changePitch(ProgressDirection.Next);
        }}
        label={label}
      />
    );
  }

  private renderFinishButton() {
    return (
      <DialogButton
        className={BTN_CLASS}
        color={
          this.props.aimingCx.pitch &&
          this.props.matchingCx.isPitchTrained(this.props.aimingCx.pitch)
            ? RADIX.COLOR.SUCCESS
            : undefined
        }
        onClick={() => {
          if (this.props.onFinish) {
            this.props.onFinish();
          }
        }}
        label="tr.finish"
      />
    );
  }

  private renderFireButton() {
    const firing = this.props.step === PTStep.Firing;

    return (
      <MachineFireButton
        ref={(elem) => (this.fireButton = elem as MachineFireButton)}
        as="dialog-button"
        size={RADIX.BUTTON.SIZE.SM}
        className={BTN_CLASS}
        cookiesCx={this.props.cookiesCx}
        ignoreAutoFire={this.state.ignoreAutoFire}
        machineCx={this.props.machineCx}
        aimingCx={this.props.aimingCx}
        trainingCx={this.props.trainingCx}
        firing={firing}
        tags="PRESET"
        beforeFire={this.props.beforeFire}
        onChange={(btn) => this.setState({ description: btn.description })}
        onReady={() => {
          if (
            firing &&
            this.props.machineCx.autoFire &&
            !this.state.ignoreAutoFire
          ) {
            return FireInstruction.AutoFire;
          }

          return FireInstruction.GoToReady;
        }}
      />
    );
  }
}
