import { NotifyHelper } from 'classes/helpers/notify.helper';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonDateInput } from 'components/common/form/date';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonSelectInput } from 'components/common/form/select';
import { endOfHour, endOfToday, startOfHour } from 'date-fns';
import { BallType } from 'lib_ts/enums/machine.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IMachine } from 'lib_ts/interfaces/i-machine';
import { IArchiveMachineShotsByDateRequest } from 'lib_ts/interfaces/training/i-machine-shot';
import React from 'react';
import { ShotsService } from 'services/shots.service';

const COMPONENT_NAME = 'ArchiveShotsDialog';

interface IProps {
  machine: IMachine;
  onClose: () => void;
}

interface IState {
  start?: Date;
  end?: Date;

  model: IArchiveMachineShotsByDateRequest;
}

export class ArchiveShotsDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const start = startOfHour(new Date());
    const end = endOfHour(new Date());

    this.state = {
      start: start,
      end: end,

      model: {
        machineID: this.props.machine.machineID,
        ball_type: this.props.machine.ball_type,
        start_date: start.toISOString(),
        end_date: end.toISOString(),
      },
    };

    this.renderForm = this.renderForm.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
  }

  private updateFilter(value: Partial<IArchiveMachineShotsByDateRequest>) {
    this.setState({
      model: {
        ...this.state.model,
        ...value,
      },
    });
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDialog
          identifier={COMPONENT_NAME}
          title="Archive Machine Shots"
          width={RADIX.DIALOG.WIDTH.MD}
          description="Use the form to specify which shots for this machine should be archived."
          content={this.renderForm()}
          buttons={[
            {
              label: 'common.archive',
              color: RADIX.COLOR.WARNING,
              onClick: async () => {
                try {
                  const success =
                    await ShotsService.getInstance().archiveShotsByDates(
                      this.state.model
                    );

                  if (!success) {
                    throw new Error('Server failed to process request.');
                  }

                  NotifyHelper.info({
                    message_md: `Shots archived for ${this.state.model.machineID}!`,
                  });

                  this.props.onClose();
                } catch (e) {
                  console.error(e);
                  NotifyHelper.info({
                    message_md: `Failed to archive shots for ${this.state.model.machineID}, please check console.`,
                  });
                }
              },
            },
          ]}
          onClose={this.props.onClose}
        />
      </ErrorBoundary>
    );
  }

  private renderForm() {
    return (
      <CommonFormGrid columns={2}>
        <CommonSelectInput
          id="archive-shots-machineID"
          name="machineID"
          label="MachineID"
          options={[
            {
              label: this.props.machine.machineID,
              value: this.props.machine.machineID,
            },
          ]}
          value={this.state.model.machineID}
          disabled
        />
        <CommonSelectInput
          id="archive-shots-ball"
          name="ball_type"
          label="common.ball-type"
          options={Object.values(BallType).map((b) => ({
            label: b,
            value: b,
          }))}
          value={this.state.model.ball_type}
          onChange={(v) => this.updateFilter({ ball_type: v as BallType })}
        />
        <CommonDateInput
          id="archive-shots-start"
          label="common.start-date"
          defaultValue={this.state.start}
          maxDate={endOfToday()}
          onChange={(date) => {
            this.setState({
              // null => undefined
              start: date ?? undefined,
            });

            this.updateFilter({
              start_date: date?.toISOString(),
            });
          }}
          showTime
          optional
        />
        <CommonDateInput
          id="archive-shots-end"
          label="common.end-date"
          defaultValue={this.state.end}
          maxDate={endOfToday()}
          onChange={(date) => {
            this.setState({
              // null => undefined
              end: date ?? undefined,
            });

            this.updateFilter({
              end_date: date?.toISOString(),
            });
          }}
          showTime
          optional
        />
      </CommonFormGrid>
    );
  }
}
