import { WebSocketHelper } from 'classes/helpers/web-socket.helper';
import { CommonCallout } from 'components/common/callouts';
import { MachineContext } from 'contexts/machine.context';
import { SfxName, WsMsgType } from 'lib_ts/enums/machine-msg.enum';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { TrackingDevice } from 'lib_ts/enums/training.enums';
import { ITrainingMsg } from 'lib_ts/interfaces/i-machine-msg';
import { useContext, useEffect, useMemo, useState } from 'react';

const TESTING_CRITICAL = false;
const TESTING_LOW_CONFIDENCE = false;

const HISTORY_LENGTH = 10;
// before triggering (e.g. if 4, then 4 is won't trigger anything but 5 would)
const MAX_CONSECUTIVE_ERRORS = 4;
const MAX_TOTAL_ERRORS = 5;

export const DataCollectorTrackingErrors = () => {
  const { machine, playSound, setAutoFire } = useContext(MachineContext);

  const [finalTrainingMsg, setFinalTrainingMsg] = useState<ITrainingMsg>();

  // newest values at the start instead of end
  const [errorHistory, setErrorHistory] = useState<boolean[]>([]);

  // if the user ignores the warning, it shouldn't show up anymore even if more timeouts occur
  const [ignoreCritical, setIgnoreCritical] = useState(false);
  const [ignoreLowConfidence, setIgnoreLowConfidence] = useState(false);

  useEffect(() => {
    const callback = (event: CustomEvent) => {
      const data = event.detail as ITrainingMsg;

      if (data.success === undefined) {
        // ignore interim training messages (e.g. when individual tracking devices report their own detections)
        return;
      }

      // only final training messages should get here
      setFinalTrainingMsg(data);
    };

    WebSocketHelper.on(WsMsgType.M2U_TrainingResponse, callback);
    return () => {
      WebSocketHelper.remove(WsMsgType.M2U_TrainingResponse, callback);
    };
  }, []);

  useEffect(() => {
    if (!finalTrainingMsg) {
      return;
    }

    const nextValue = [...errorHistory];

    if (finalTrainingMsg.shot) {
      const { shot } = finalTrainingMsg;

      // was a BI timeout encountered?
      const biTimeout = !!shot.bi_timeout;

      // was there no detection?
      const noDetection = (() => {
        switch (machine.tracking_device) {
          case TrackingDevice.RapsodoV3PRO: {
            return !shot.rapsodo_shot_id;
          }
          case TrackingDevice.TrackmanB1: {
            return !shot.trackman_pitch_id;
          }
          case TrackingDevice.TrajektVision: {
            return !shot.msbs_shot_id;
          }
          default: {
            return true;
          }
        }
      })();

      nextValue.splice(0, 0, biTimeout || noDetection);
    } else {
      // note: no shot found is treated like a BI timeout
      nextValue.splice(0, 0, true);
    }

    // only keep the most recent entries
    setErrorHistory(nextValue.filter((_, i) => i < HISTORY_LENGTH));
  }, [finalTrainingMsg]);

  // critical detection failure
  const isCriticalFailure = useMemo(() => {
    if (TESTING_CRITICAL) {
      return true;
    }

    return (
      errorHistory.findIndex((_, i) => {
        if (i + MAX_CONSECUTIVE_ERRORS > errorHistory.length) {
          // too close to end of list
          return false;
        }

        // many errors all in a row
        return errorHistory
          .slice(i, i + MAX_CONSECUTIVE_ERRORS)
          .every((v) => v === true);
      }) !== -1
    );
  }, [errorHistory]);

  useEffect(() => {
    if (!isCriticalFailure) {
      return;
    }

    // stop the test
    setAutoFire(false);

    // play sound on projector
    playSound(SfxName.ERROR);
  }, [isCriticalFailure]);

  // low confidence warning
  const isLowConfidence = useMemo(() => {
    if (TESTING_LOW_CONFIDENCE) {
      return true;
    }

    return errorHistory.filter((b) => b).length > MAX_TOTAL_ERRORS;
  }, [errorHistory]);

  if (isCriticalFailure && !ignoreCritical) {
    return (
      <CommonCallout
        text={`${machine.tracking_device} doesn't seem to be detecting pitches. Please check the setup to ensure it functions properly.`}
        actions={[
          {
            label: 'common.ignore',
            color: RADIX.COLOR.WARNING,
            onClick: () => setIgnoreCritical(true),
          },
          {
            invisible: true,
            label: 'common.resume',
            color: RADIX.COLOR.WARNING,
            // resets errors without suppressing for the rest of the session (unlike ignore)
            onClick: () => setErrorHistory([]),
          },
        ]}
      />
    );
  }

  if (isLowConfidence && !ignoreLowConfidence) {
    return (
      <CommonCallout
        text={`${machine.tracking_device} is experiencing detection issues. Please check the setup to ensure it functions properly.`}
        actions={[
          {
            label: 'common.ignore',
            color: RADIX.COLOR.WARNING,
            onClick: () => setIgnoreLowConfidence(true),
          },
        ]}
      />
    );
  }

  return <></>;
};
