import { PitchListHelper } from 'classes/helpers/pitch-list.helper';
import { SuperAdminIcon } from 'components/common/custom-icon/shorthands';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { TrainingControls } from 'components/machine/dialogs/training/controls';
import { AimingContext, AimingProvider } from 'contexts/aiming.context';
import { AuthContext } from 'contexts/auth.context';
import { CookiesContext } from 'contexts/cookies.context';
import { GlobalContext } from 'contexts/global.context';
import { MachineContext, MachineDialogMode } from 'contexts/machine.context';
import {
  MatchingShotsContext,
  MatchingShotsProvider,
} from 'contexts/pitch-lists/matching-shots.context';
import { TrainingContext, TrainingProvider } from 'contexts/training.context';
import { t } from 'i18next';
import { ITrainingDialog } from 'interfaces/i-training';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useContext, useEffect, useMemo, useState } from 'react';

const COMPONENT_NAME = 'TrainingDialog';

interface IProps extends ITrainingDialog {
  threshold: number;
}

export const TrainingDialogHoC = (props: IProps) => {
  const { machine } = useContext(MachineContext);

  const [start] = useState(new Date().toISOString());

  const [uniquePitches] = useState(
    PitchListHelper.getUniquePitches(props.pitches, machine)
  );

  return (
    <TrainingProvider mode={props.mode}>
      <MatchingShotsProvider>
        <AimingProvider newerThan={start}>
          <TrainingDialog {...props} pitches={uniquePitches} />
        </AimingProvider>
      </MatchingShotsProvider>
    </TrainingProvider>
  );
};

const TrainingDialog = (props: IProps) => {
  const { countDialog } = useContext(GlobalContext);
  const { current } = useContext(AuthContext);
  const { onEndTraining, setDialog: setMachineDialog } =
    useContext(MachineContext);
  const { pitch } = useContext(AimingContext);
  const { pitchIndex } = useContext(TrainingContext);

  // todo: deprecate this once we convert the underlying thing
  const aimingCx = useContext(AimingContext);
  const authCx = useContext(AuthContext);
  const cookiesCx = useContext(CookiesContext);
  const machineCx = useContext(MachineContext);
  const matchingCx = useContext(MatchingShotsContext);
  const trainingCx = useContext(TrainingContext);

  useEffect(() => {
    countDialog({
      id: props.identifier,
      open: true,
    });

    return () =>
      countDialog({
        id: props.identifier,
        open: false,
      });
  }, []);

  useEffect(() => {
    return () => onEndTraining();
  }, []);

  const title = useMemo(() => {
    let result = t('common.training').toString();

    if (props.pitches.length > 1) {
      result += ` ${pitchIndex + 1} of ${props.pitches.length} unique pitches`;
    }

    if (pitch?.name) {
      result += `: "${pitch.name}"`;
    }

    return result;
  }, [props.pitches, pitchIndex, pitch]);

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <CommonDialog
        identifier={COMPONENT_NAME}
        width={RADIX.DIALOG.WIDTH.XL}
        title={title}
        content={
          <TrainingControls
            machineCx={machineCx}
            trainingCx={trainingCx}
            cookiesCx={cookiesCx}
            authCx={authCx}
            matchingCx={matchingCx}
            aimingCx={aimingCx}
            pitches={props.pitches}
            threshold={props.threshold}
            onFinish={props.onClose}
            left_button={
              current.mode === 'impostor' || current.role === UserRole.admin
                ? {
                    label: 'common.ready-status',
                    color: RADIX.COLOR.INFO,
                    icon: <SuperAdminIcon />,
                    tooltip: t('common.super-admins-only-msg').toString(),
                    onClick: () => setMachineDialog(MachineDialogMode.R2F),
                  }
                : undefined
            }
          />
        }
        onClose={props.onClose}
      />
    </ErrorBoundary>
  );
};
