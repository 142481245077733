import { Box, Flex, Heading, Text } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { TrainingControls } from 'components/machine/dialogs/training/controls';
import {
  AimingContext,
  AimingProvider,
  IAimingContext,
} from 'contexts/aiming.context';
import { AuthContext, IAuthContext } from 'contexts/auth.context';
import { CookiesContext, ICookiesContext } from 'contexts/cookies.context';
import {
  IMachineCalibrationContext,
  MachineCalibrationContext,
  MAX_REF_LIST_SHOTS,
} from 'contexts/machine-calibration.context';
import { IMachineContext, MachineContext } from 'contexts/machine.context';
import {
  IMatchingShotsContext,
  MatchingShotsContext,
  MatchingShotsProvider,
} from 'contexts/pitch-lists/matching-shots.context';
import {
  ITrainingContext,
  TrainingContext,
  TrainingProvider,
} from 'contexts/training.context';
import { isAfter, parseISO } from 'date-fns';
import { CookieKey } from 'enums/cookies.enums';
import { CalibrationStep } from 'enums/machine.enums';
import { t } from 'i18next';
import { IButton } from 'interfaces/i-buttons';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { MetricInterval } from 'lib_ts/enums/machine-models.enums';
import { TrainingMode } from 'lib_ts/enums/machine.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IGatherShotDataQuery } from 'lib_ts/interfaces/modelling/i-gather-shot-data';
import { IPitch } from 'lib_ts/interfaces/pitches';
import { IMachineShot } from 'lib_ts/interfaces/training/i-machine-shot';
import { useContext, useEffect, useMemo, useState } from 'react';
import { AdminMachineModelsService } from 'services/admin/machine-models.service';

const COMPONENT_NAME = 'CollectData';

interface IProps {
  aimingCx: IAimingContext;
  authCx: IAuthContext;
  calibrationCx: IMachineCalibrationContext;
  cookiesCx: ICookiesContext;
  machineCx: IMachineContext;
  matchingCx: IMatchingShotsContext;
  trainingCx: ITrainingContext;
}

export const CollectDataHoC = () => {
  const { machineCalibration } = useContext(CookiesContext);

  return (
    <MatchingShotsProvider>
      <AimingProvider
        newerThan={machineCalibration.start_date ?? new Date().toISOString()}
      >
        <TrainingProvider mode={TrainingMode.Quick}>
          <CollectData />
        </TrainingProvider>
      </AimingProvider>
    </MatchingShotsProvider>
  );
};

const Controls = (props: { onFinish: () => void }) => {
  const aimingCx = useContext(AimingContext);
  const authCx = useContext(AuthContext);
  const calibrationCx = useContext(MachineCalibrationContext);
  const cookiesCx = useContext(CookiesContext);
  const machineCx = useContext(MachineContext);
  const matchingCx = useContext(MatchingShotsContext);
  const trainingCx = useContext(TrainingContext);

  if (!cookiesCx.machineCalibration.start_date) {
    return (
      <Text>
        Invalid value detected for calibration start date. Please refresh and
        try again.
      </Text>
    );
  }

  const threshold = cookiesCx.machineCalibration.shots ?? 0;

  if (!threshold) {
    return (
      <Text>
        Invalid value detected for shots per pitch. Please select a valid value
        and try again.
      </Text>
    );
  }

  if (!aimingCx.pitch) {
    return <></>;
  }

  const prevButton: IButton = {
    label: 'common.back',
    disabled: calibrationCx.loading,
    onClick: () => calibrationCx.setStep(CalibrationStep.Setup),
  };

  return (
    <TrainingControls
      aimingCx={aimingCx}
      authCx={authCx}
      cookiesCx={cookiesCx}
      machineCx={machineCx}
      matchingCx={matchingCx}
      trainingCx={trainingCx}
      pitches={calibrationCx.pitches}
      threshold={threshold}
      left_button={prevButton}
      beforeNext={() => {
        const pitch = aimingCx.pitch;
        if (!pitch) {
          // shouldn't trigger, but do nothing if the pitch can't be found
          return;
        }

        if (matchingCx.isPitchTrained(pitch)) {
          // do nothing if the pitch was successfully trained
          return;
        }

        // make a note of the pitch that was skipped to avoid returning to it later
        cookiesCx.setCookie(CookieKey.machineCalibration, {
          skippedPitchIDs: ArrayHelper.unique([
            ...cookiesCx.machineCalibration.skippedPitchIDs,
            pitch._id,
          ]),
        });
      }}
      onFinish={props.onFinish}
      showProgress
      calibrating
    />
  );
};

const CollectData = () => {
  const { setPitch } = useContext(AimingContext);
  const { pitches, setRealMachineMetric, setStep } = useContext(
    MachineCalibrationContext
  );
  const { machineCalibration, setCookie } = useContext(CookiesContext);
  const { safeGetShotsByPitch, updatePitches } =
    useContext(MatchingShotsContext);
  const { pitchIndex, setPitchIndex } = useContext(TrainingContext);

  // undefined until we load the data once
  const [complete, setComplete] = useState<boolean>(false);

  const startDate = useMemo(
    () =>
      machineCalibration.start_date
        ? parseISO(machineCalibration.start_date)
        : undefined,
    [machineCalibration.start_date]
  );

  const shotsSinceStart = (pitch: Partial<IPitch>): IMachineShot[] => {
    const startDate = machineCalibration.start_date
      ? new Date(machineCalibration.start_date)
      : undefined;

    return safeGetShotsByPitch(pitch).filter(
      (s) => !startDate || isAfter(new Date(s._created), startDate)
    );
  };

  const trainedSinceStart = (pitch: Partial<IPitch>): boolean => {
    return shotsSinceStart(pitch).filter((s) => s.training_complete).length > 0;
  };

  const handleMakeMetric = async () => {
    const cookie = machineCalibration;

    if (!cookie.machineID) {
      NotifyHelper.warning({
        message_md: 'Machine is not specified in model builder.',
        inbox: true,
      });
      return;
    }

    if (!cookie.ball_type) {
      NotifyHelper.warning({
        message_md: 'Ball type is not specified in model builder.',
        inbox: true,
      });
      return;
    }

    const payload: IGatherShotDataQuery = {
      machineID: cookie.machineID,
      ball_type: cookie.ball_type,
      start_date: cookie.start_date,
      end_date: cookie.end_date,
    };

    NotifyHelper.success({
      message_md: 'Evaluating your data, please wait...',
      inbox: true,
    });

    const metric =
      await AdminMachineModelsService.getInstance().createMachineMetric({
        query: payload,
        job_interval: MetricInterval.Calibration,
        list_length: pitches.length,
      });

    if (!metric) {
      /** shouldn't trigger */
      NotifyHelper.error({
        message_md: 'Received empty result from metric creation.',
        inbox: true,
      });

      setStep(CalibrationStep.TrainError);
      return;
    }

    setRealMachineMetric(metric);
    setStep(CalibrationStep.ReviewMetric);
  };

  /**
   * - populate the matching shots context for pitches in this list
   * - use start_date of this model builder session for matching query
   * - find first pitch where matching shots is less than the chosen threshold
   * - resume training from there
   */
  const init = async () => {
    if (pitches.length === 0) {
      NotifyHelper.warning({
        message_md: t('common.request-failed-msg'),
      });
      return;
    }

    if (!machineCalibration.start_date) {
      /** shouldn't trigger but fix it in case */
      await setCookie(CookieKey.machineCalibration, {
        start_date: new Date().toISOString(),
      });
    }

    await updatePitches({
      pitches: pitches,
      newerThan: machineCalibration.start_date,
      includeHitterPresent: false,
      includeLowConfidence: true,
      limit:
        // ensures that sufficient shots are fetched to proceed, e.g. calibration list count exceeds quick shot requirement
        (machineCalibration.shots ?? MAX_REF_LIST_SHOTS) + 1,
    });

    const skippedIDs = machineCalibration.skippedPitchIDs;
    const resumeIndex = pitches.findIndex(
      (p) => !skippedIDs.includes(p._id) && !trainedSinceStart(p)
    );

    switch (resumeIndex) {
      case -1: {
        // nowhere to resume, we should be done
        setComplete(true);
        return;
      }

      default: {
        setPitchIndex(resumeIndex);
        return;
      }
    }
  };

  // once, upon mount
  useEffect(() => {
    init();
  }, []);

  // upon complete, make the metric
  useEffect(() => {
    if (!complete) {
      return;
    }

    handleMakeMetric();
  }, [complete]);

  useEffect(() => {
    const nextPitch = pitches[pitchIndex];

    if (!nextPitch) {
      return;
    }

    if (trainedSinceStart(nextPitch)) {
      setPitchIndex(pitchIndex + 1);
      return;
    }

    setPitch(nextPitch, { loadShots: 'force' });
  }, [pitchIndex, pitches]);

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
        <Heading size={RADIX.HEADING.SIZE.MD}>
          {complete
            ? 'Data Collection Complete'
            : 'Data Collection In Progress'}
        </Heading>

        <Box>
          <Controls
            onFinish={async () => {
              // move to completed screen
              setComplete(true);

              // record moment when all data was collected (for use in model training payload)
              await setCookie(CookieKey.machineCalibration, {
                end_date: new Date().toISOString(),
              });

              handleMakeMetric();
            }}
          />
        </Box>
      </Flex>
    </ErrorBoundary>
  );
};
