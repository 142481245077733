import { ExclamationTriangleIcon } from '@radix-ui/react-icons';
import { Box, Button, Callout, Flex, Heading, Text } from '@radix-ui/themes';
import { t } from 'i18next';
import { IMenuAction } from 'interfaces/i-menus';
import {
  RADIX,
  RadixCalloutSize,
  RadixCalloutVariant,
  RadixColor,
  RadixFlexAlign,
  RadixFlexJustify,
} from 'lib_ts/enums/radix-ui';
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';

interface IProps {
  size?: RadixCalloutSize;

  flexAlign?: RadixFlexAlign;
  flexJustify?: RadixFlexJustify;

  header?: string;

  /** will be translated and rendered via Callout.Text */
  text?: string;
  /** will be translated and rendered via react markdown in a box */
  text_md?: string;
  content?: React.ReactNode;
  icon?: JSX.Element;
  color?: RadixColor;
  variant?: RadixCalloutVariant;
  hideIcon?: boolean;

  actions?: IMenuAction[];

  justifyIcon?: RadixFlexJustify;

  autoDismissMS?: number;
}

export const CommonCallout = (props: IProps) => {
  const [autoDismissed, setAutoDismissed] = useState(false);

  useEffect(() => {
    if (props.autoDismissMS === undefined) {
      return;
    }

    setTimeout(() => {
      setAutoDismissed(true);
    }, props.autoDismissMS);
  }, [props.autoDismissMS]);

  if (autoDismissed) {
    return <></>;
  }

  return (
    <Callout.Root
      size={props.size}
      variant={props.variant}
      color={props.color ?? RADIX.COLOR.WARNING}
    >
      <Flex
        align={props.flexAlign}
        justify={props.flexJustify}
        style={{ width: '100%' }}
        gap={RADIX.FLEX.GAP.MD}
      >
        {!props.hideIcon && (
          <Box style={{ paddingTop: '2px' }}>
            {props.icon ?? <ExclamationTriangleIcon />}
          </Box>
        )}

        <Flex
          flexGrow="1"
          direction="column"
          gap={RADIX.FLEX.GAP.SM}
          justify="start"
        >
          {props.header && (
            <Heading size={RADIX.HEADING.SIZE.SM}>{t(props.header)}</Heading>
          )}

          {props.text && <Text>{t(props.text)}</Text>}

          {props.text_md && <ReactMarkdown>{t(props.text_md)}</ReactMarkdown>}

          {props.content}
        </Flex>

        {props.actions && (
          <Flex direction="column" gap={RADIX.FLEX.GAP.SM} justify="center">
            {props.actions
              .filter((a) => !a.invisible)
              .map((a, i) => (
                <Box key={i}>
                  <Button
                    size="1"
                    title={a.tooltip}
                    disabled={a.disabled}
                    color={a.color ?? props.color ?? RADIX.COLOR.NEUTRAL}
                    variant={a.variant ?? 'soft'}
                    className="btn-block"
                    onClick={a.onClick}
                  >
                    {t(a.label)}
                  </Button>
                </Box>
              ))}
          </Flex>
        )}
      </Flex>
    </Callout.Root>
  );
};
