import {
  CheckCircledIcon,
  InfoCircledIcon,
  PauseIcon,
  PlayIcon,
} from '@radix-ui/react-icons';
import { Flex, Spinner } from '@radix-ui/themes';
import { MachineContextHelper } from 'classes/helpers/machine-context.helper';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { WebSocketHelper } from 'classes/helpers/web-socket.helper';
import { CommonCallout } from 'components/common/callouts';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { HINT_DELIMITER } from 'components/common/form/text-hint';
import { DotDotDot } from 'components/common/layout/dot-dot-dot';
import { MachineCameraStreamViewer } from 'components/machine/camera-stream-viewer';
import {
  IStreamingContext,
  StreamingContext,
  StreamingProvider,
} from 'components/machine/camera-stream-viewer/context';
import { IMachineContext } from 'contexts/machine.context';
import { IBaseDialog, IFullDialog } from 'interfaces/i-dialogs';
import { CameraSource, WsMsgType } from 'lib_ts/enums/machine-msg.enum';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { ICalibrateStereoResponseMsg } from 'lib_ts/interfaces/machine-msg/i-calibrate-stereo';
import React, { useMemo } from 'react';

const COMPONENT_NAME = 'StereoCheckDialog';

const CalibrationCallout = (props: { msg: ICalibrateStereoResponseMsg }) => {
  const { msg } = props;

  if (msg.success) {
    const text = useMemo(
      () =>
        [
          'Calibration successful',
          msg.py_ft !== undefined
            ? `Measured distance to plate: ${msg.py_ft.toFixed(2)} ft`
            : undefined,
        ]
          .filter((s) => !!s)
          .join(HINT_DELIMITER),
      [msg.py_ft]
    );

    return (
      <CommonCallout
        color={RADIX.COLOR.SUCCESS}
        icon={<CheckCircledIcon />}
        text={text}
      />
    );
  }

  return <CommonCallout color={RADIX.COLOR.DANGER} text="Failure msg here" />;
};

interface IBaseProps extends IBaseDialog {
  // provide for admins inspecting others' machines
  machineID?: string;
  // provide for users inspecting their active machine
  machineCx?: IMachineContext;
}

interface IProps extends IBaseProps {
  streamingCx: IStreamingContext;
}

interface IState {
  loading: boolean;
  machineID: string;
  lastMessage?: ICalibrateStereoResponseMsg;
}

export const StereoCheckDialogHoC = (props: IBaseProps) => {
  return (
    <StreamingProvider>
      <StreamingContext.Consumer>
        {(streamingCx) => (
          <StereoCheckDialog {...props} streamingCx={streamingCx} />
        )}
      </StreamingContext.Consumer>
    </StreamingProvider>
  );
};

class StereoCheckDialog extends React.Component<IProps, IState> {
  private closeTimeout?: NodeJS.Timeout;

  constructor(props: IProps) {
    super(props);

    if (props.machineID && props.machineCx) {
      NotifyHelper.warning({
        message_md: `Both \`machineID\` and \`machineCx\` should not be provided; using \`machineCx\` for inspection dialog.`,
      });
    }

    this.state = {
      loading: false,
      machineID: props.machineCx?.machine.machineID ?? props.machineID ?? '',
    };

    this.handleCalibrateResponse = this.handleCalibrateResponse.bind(this);
  }

  componentDidMount() {
    this.props.machineCx?.setSpecialMode('stereo-check');
    WebSocketHelper.on(
      WsMsgType.M2U_CalibrateStereo,
      this.handleCalibrateResponse
    );
  }

  componentWillUnmount() {
    this.props.machineCx?.setSpecialMode(undefined);
    WebSocketHelper.remove(
      WsMsgType.M2U_CalibrateStereo,
      this.handleCalibrateResponse
    );
    clearTimeout(this.closeTimeout);
  }

  private handleCalibrateResponse(event: CustomEvent) {
    const data: ICalibrateStereoResponseMsg = event.detail;
    this.setState({
      lastMessage: data,
      loading: false,
    });
  }

  private renderContent() {
    return (
      <Flex direction="column" gap={RADIX.FLEX.GAP.LG}>
        <MachineCameraStreamViewer
          streamingCx={this.props.streamingCx}
          defaultSource={CameraSource.STEREO_CHECK}
          machineID={this.state.machineID}
          hideSource
        />

        {this.state.lastMessage ? (
          <CalibrationCallout msg={this.state.lastMessage} />
        ) : this.state.loading ? (
          <CommonCallout
            color={RADIX.COLOR.INFO}
            icon={<Spinner />}
            content={<DotDotDot label="Calibrating" delay_ms={500} />}
          />
        ) : (
          <CommonCallout
            color={RADIX.COLOR.INFO}
            icon={<InfoCircledIcon />}
            text="Please ensure the lane is clear and the checker board is present before calibrating."
          />
        )}
      </Flex>
    );
  }

  render() {
    const { paused, lastFrame, setPaused } = this.props.streamingCx;

    const DEFAULT_PROPS: IFullDialog = {
      identifier: COMPONENT_NAME,
      width: RADIX.DIALOG.WIDTH.LG,
      title: 'settings.calibrate',
      content: this.renderContent(),
      buttons: [
        {
          icon: paused ? <PlayIcon /> : <PauseIcon />,
          label: paused ? 'common.resume' : 'common.pause',
          color: RADIX.COLOR.SECONDARY,
          invisible: !lastFrame,
          onClick: () => setPaused(!paused),
        },
        {
          label: 'settings.calibrate',
          disabled: !this.props.machineCx || !lastFrame?.cal_board_detected,
          onClick: () => {
            this.setState({
              // reset the last msg in case the user does this multiple times
              lastMessage: undefined,
              // start the spinner
              loading: true,
            });

            MachineContextHelper.sendCalibrateVision({
              machineID: this.state.machineID,
              source: COMPONENT_NAME,
            });
          },
        },
      ],
      onClose: () => this.props.onClose(),
    };

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDialog {...DEFAULT_PROPS} />
      </ErrorBoundary>
    );
  }
}
