import { Box, Flex, Text } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonStepHeader } from 'components/machine/dialogs/installation/header';
import { RADIX } from 'lib_ts/enums/radix-ui';

export const StepEnd = () => {
  return (
    <ErrorBoundary componentName="StepEnd">
      <Flex direction="column" gap="4">
        <CommonStepHeader label="Machine Install Complete" />

        <Box>
          <Text color={RADIX.COLOR.SECONDARY}>
            Your Machine is now fully installed and ready for use! All settings
            have been calibrated, and the system is optimized for accurate
            performance.
          </Text>
        </Box>
        <Box>
          <Text color={RADIX.COLOR.SECONDARY}>
            You can now start using your Machine and start firing pitches.
          </Text>
        </Box>

        <Flex justify="center">
          <Box>
            <img
              style={{
                width: '351px',
              }}
              src="/img/renders/installation-complete.svg"
            />
          </Box>
        </Flex>
      </Flex>
    </ErrorBoundary>
  );
};
