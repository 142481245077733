import {
  CaretDownIcon,
  CaretUpIcon,
  DownloadIcon,
} from '@radix-ui/react-icons';
import { Box, Button, Grid, Popover, Text } from '@radix-ui/themes';
import { StringHelper } from 'classes/helpers/string.helper';
import { CommonSearchInput } from 'components/common/form/search';
import { CommonTableButton } from 'components/common/table/button';
import { AdvancedFilters } from 'components/sections/analytics/hitter-session/advanced-filters';
import useAnalyticsStore from 'components/sections/analytics/store/use-analytics-store';
import { lightFormat } from 'date-fns';
import parseISO from 'date-fns/parseISO';
import { t } from 'i18next';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { HitOutcomeCode } from 'lib_ts/enums/hitters.enums';
import { PitchType } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { ISessionHit } from 'lib_ts/interfaces/i-session-hit';
import { useMemo, useState } from 'react';

const getUniquePitchTypes = (hits: ISessionHit[]) =>
  ArrayHelper.unique(
    hits.map((m) => m.pitch.type).filter(Boolean)
  ).sort() as PitchType[];

const getUniqueOutcomes = (hits: ISessionHit[]) =>
  ArrayHelper.unique(
    hits.map((m) => m.hit.outcome).filter(Boolean)
  ).sort() as HitOutcomeCode[];

export const HitterSessionToolbar = () => {
  const {
    hits,
    active,
    pitchTypeFilter,
    outcomeFilter,
    setPitchTypeFilter,
    setOutcomeFilter,
    loading,
  } = useAnalyticsStore();

  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

  const pitchTypes = useMemo(() => getUniquePitchTypes(hits), [hits]);

  const outcomes = useMemo(() => getUniqueOutcomes(hits), [hits]);

  // Should this be exporting the filtered rows instead of the whole table?
  const exportReport = () => {
    let fileName = 'Hitting Report';

    if (active) {
      const { hitterName, startDate } = active;
      fileName = `${fileName} - ${hitterName} (${lightFormat(
        parseISO(startDate),
        'yyyy-MM-dd'
      )})`;
    }

    StringHelper.saveCsv(hits, {
      prefix: fileName,
    });
  };

  return (
    <Grid columns="6" gap="1">
      <Box gridColumn="span 2">
        <CommonSearchInput
          id="pitch-type-filter"
          placeholder={t('common.pitch-type') as string}
          options={pitchTypes.map((pitchType) => ({
            label: pitchType,
            value: pitchType,
          }))}
          values={pitchTypeFilter}
          onChange={(pitchTypes) =>
            setPitchTypeFilter(pitchTypes as PitchType[])
          }
          disabled={loading}
          multiple
          strict
        />
      </Box>

      <Box gridColumn="span 2">
        <CommonSearchInput
          id="outcome-filter"
          placeholder={t('common.results') as string}
          options={outcomes.map((outcome) => ({
            label: outcome,
            value: outcome,
          }))}
          values={outcomeFilter}
          onChange={(outcomes) =>
            setOutcomeFilter(outcomes as HitOutcomeCode[])
          }
          disabled={loading}
          multiple
        />
      </Box>

      <Box>
        <Popover.Root
          open={showAdvancedFilters}
          onOpenChange={setShowAdvancedFilters}
        >
          <Popover.Trigger>
            <Button
              className="btn-block"
              size={RADIX.BUTTON.SIZE.TABLE}
              color={RADIX.COLOR.NEUTRAL}
              variant={RADIX.BUTTON.VARIANT.SECONDARY}
              disabled={loading}
            >
              <Text truncate>
                {t('common.advanced-filters')}&nbsp;
                {showAdvancedFilters ? <CaretUpIcon /> : <CaretDownIcon />}
              </Text>
            </Button>
          </Popover.Trigger>
          <Popover.Content
            align="end"
            style={{
              padding: 'var(--space-4)',
            }}
          >
            <AdvancedFilters
              onApply={() => {
                setShowAdvancedFilters(false);
              }}
              onReset={() => {
                setShowAdvancedFilters(false);
              }}
            />
          </Popover.Content>
        </Popover.Root>
      </Box>

      <Box>
        <CommonTableButton
          icon={<DownloadIcon />}
          label="common.export-report"
          disabled={loading}
          className="btn-block"
          onClick={exportReport}
        />
      </Box>
    </Grid>
  );
};
