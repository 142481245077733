import { Box, Button, Flex } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonTextInput } from 'components/common/form/text';
import { CommonProgress } from 'components/common/progress';
import { LandingFormWrapper } from 'components/main/landing/forms/wrapper';
import { IAuthContext } from 'contexts/auth.context';
import { IKeyUpEvent } from 'interfaces/forms/event';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { ILoginPayload } from 'lib_ts/interfaces/common/i-login';
import React from 'react';

interface IProps {
  authCx: IAuthContext;
}

interface IState {
  login: ILoginPayload;
}

export class LoginFormLegacy extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      login: {
        email: '',
        password: '',
      },
    };

    this.onKeyUp = this.onKeyUp.bind(this);
    this.renderContent = this.renderContent.bind(this);
    this.submit = this.submit.bind(this);
  }

  private onKeyUp(event: IKeyUpEvent) {
    if (event.code === 'Enter') {
      this.submit();
    }
  }

  private async submit() {
    if (!this.state.login.email) {
      NotifyHelper.warning({
        message_md: 'Please enter your username and try again.',
      });
      return;
    }

    if (!this.state.login.password) {
      NotifyHelper.warning({
        message_md: 'Please enter your password and try again.',
      });
      return;
    }

    const result = await this.props.authCx.loginLegacy(this.state.login);

    if (result.error) {
      NotifyHelper.warning({ message_md: result.error.message });
      return;
    }
  }

  private renderContent() {
    if (this.props.authCx.loading) {
      return <CommonProgress value={80} color={RADIX.COLOR.INFO} />;
    }

    return (
      <CommonFormGrid columns={1}>
        <CommonTextInput
          id="legacy-login-email"
          value={this.state.login.email}
          type="email"
          placeholder="user@trajektsports.com"
          onKeyUp={this.onKeyUp}
          autoComplete="username"
          disabled={this.props.authCx.loading}
          onChange={(v) => {
            this.setState({
              login: {
                ...this.state.login,
                email: v ?? '',
              },
            });
          }}
        />

        <CommonTextInput
          id="legacy-login-password"
          value={this.state.login.password}
          type="password"
          controlId="password"
          placeholder="*********"
          onKeyUp={this.onKeyUp}
          autoComplete="current-password"
          disabled={this.props.authCx.loading}
          onChange={(v) => {
            this.setState({
              login: {
                ...this.state.login,
                password: v ?? '',
              },
            });
          }}
        />

        <Box>
          <Button
            className="btn-block"
            color={RADIX.COLOR.SUCCESS}
            disabled={this.props.authCx.loading}
            onClick={this.submit}
          >
            Login
          </Button>
        </Box>
      </CommonFormGrid>
    );
  }

  render() {
    return (
      <ErrorBoundary componentName="LoginFormLegacy" hideIntercom>
        <LandingFormWrapper
          header={
            this.props.authCx.loading
              ? 'Please wait...'
              : 'Please log in to access your machine.'
          }
        >
          <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
            {this.renderContent()}
          </Flex>
        </LandingFormWrapper>
      </ErrorBoundary>
    );
  }
}
